import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useActivationTagAssociation(activationId: number) {
    const authenticatedApi = useAuthenticatedApi();
    const getTagAssociations = async () => {
        const response = await authenticatedApi.get(
            `/activations/${activationId}/tags`,
        );
        return response.data;
    };

    const setTagAssociations = async (values: any) => {
        const response = await authenticatedApi.put(
            `/activations/${activationId}/tags`,
            JSON.stringify(values),
        );

        return response.data;
    };

    return {
        getTagAssociations,
        setTagAssociations,
    };
}
