import { useEffect, useState } from 'react';
import { useSoftwareReleases } from './hooks/useSoftwareReleases';

export default function SoftwareReleases() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [adapterRelease, setAdapterRelease] = useState<any>(null);
    const [macRelease, setMacRelease] = useState<any>(null);
    const [macSharingRelease, setMacSharingRelease] = useState<any>(null);
    const [macUploadingRelease, setMacUploadingRelease] = useState<any>(null);
    const [macProcessingRelease, setMacProcessingRelease] = useState<any>(null);
    const [romrAndroid, setRomrAndroid] = useState<any>(null);
    const { getLatestRelease } = useSoftwareReleases();
    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const release = await getLatestRelease('smileport-adapter');
                const macRelease = await getLatestRelease('mac-client');
                const macProcessingUtility = await getLatestRelease(
                    'mac-processing-utility',
                );
                const macUploadingUtility = await getLatestRelease(
                    'mac-upload-utility',
                );
                const macSharingUtility = await getLatestRelease(
                    'mac-sharing-utility',
                );
                const romrAndroid = await getLatestRelease('romr-android');

                setRomrAndroid(romrAndroid);
                setAdapterRelease(release);
                setMacRelease(macRelease);
                setMacProcessingRelease(macProcessingUtility);
                setMacUploadingRelease(macUploadingUtility);
                setMacSharingRelease(macSharingUtility);
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ul className="divide-y divide-gray-100 px-6">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        <tbody>
                            {isLoading ? (
                                '...'
                            ) : (
                                <>
                                    <h2 className="text-lg font-semibold leading-6 text-gray-900">
                                        Smileport Utilities
                                    </h2>
                                    <li className="flex items-center justify-between gap-x-6 py-5">
                                        <div className="min-w-0">
                                            <div className="flex items-start gap-x-3">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                    Smileport Adapter
                                                </p>
                                            </div>

                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                Utility application to bridge
                                                smileport devices to Photostat
                                                for sharing.
                                            </div>
                                        </div>
                                        <div className="flex flex-none items-center gap-x-4">
                                            <a
                                                href={adapterRelease.bundleUri}
                                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                            >
                                                Download
                                            </a>
                                        </div>
                                    </li>
                                    <br />
                                    <br />
                                    <h2 className="text-lg font-semibold leading-6 text-gray-900">
                                        Mac Utilities
                                    </h2>
                                    <li className="flex items-center justify-between gap-x-6 py-5">
                                        <div className="min-w-0">
                                            <div className="flex items-start gap-x-3">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                    Mac Client
                                                </p>
                                            </div>

                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                Photostat client for Mac.
                                                Requires all of the following
                                                Mac utilities.
                                            </div>
                                        </div>
                                        <div className="flex flex-none items-center gap-x-4">
                                            <a
                                                href={macRelease.bundleUri}
                                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                            >
                                                Download
                                            </a>
                                        </div>
                                    </li>
                                    <li className="flex items-center justify-between gap-x-6 py-5">
                                        <div className="min-w-0">
                                            <div className="flex items-start gap-x-3">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                    Mac Upload Utility
                                                </p>
                                            </div>

                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                Utility application to upload
                                                photos to Photostat.
                                            </div>
                                        </div>
                                        <div className="flex flex-none items-center gap-x-4">
                                            <a
                                                href={
                                                    macUploadingRelease.bundleUri
                                                }
                                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                            >
                                                Download
                                            </a>
                                        </div>
                                    </li>
                                    <li className="flex items-center justify-between gap-x-6 py-5">
                                        <div className="min-w-0">
                                            <div className="flex items-start gap-x-3">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                    Mac Processing Utility
                                                </p>
                                            </div>

                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                Utility application to process
                                                photos for sharing. Requires
                                                Imagemagick to be installed.
                                            </div>
                                        </div>
                                        <div className="flex flex-none items-center gap-x-4">
                                            <a
                                                href={
                                                    macProcessingRelease.bundleUri
                                                }
                                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                            >
                                                Download
                                            </a>
                                        </div>
                                    </li>
                                    <li className="flex items-center justify-between gap-x-6 py-5">
                                        <div className="min-w-0">
                                            <div className="flex items-start gap-x-3">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                    Mac Sharing Utility
                                                </p>
                                            </div>

                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                Utility to allow the Photostat
                                                sharing station to connect to a
                                                Mac.
                                            </div>
                                        </div>
                                        <div className="flex flex-none items-center gap-x-4">
                                            <a
                                                href={
                                                    macSharingRelease.bundleUri
                                                }
                                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                            >
                                                Download
                                            </a>
                                        </div>
                                    </li>
                                    <br />
                                    <br />
                                    <h2 className="text-lg font-semibold leading-6 text-gray-900">
                                        Android Utilities
                                    </h2>
                                    <li className="flex items-center justify-between gap-x-6 py-5">
                                        <div className="min-w-0">
                                            <div className="flex items-start gap-x-3">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                    ROMR Android
                                                </p>
                                            </div>

                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                ROMR Android application.
                                            </div>
                                        </div>
                                        <div className="flex flex-none items-center gap-x-4">
                                            <a
                                                href={romrAndroid.bundleUri}
                                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                            >
                                                Download
                                            </a>
                                        </div>
                                    </li>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </ul>
    );
}
