import { useNavigate, useParams } from 'react-router-dom';
import useProcessing, { ProcessingFlow } from './hooks/useProcessing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FlowEditor from './FlowEditor';
import Loading from '../../Common/components/Loading';
import useGallery from '@/src/GalleryConfiguration/components/hooks/useGallery';

export default function EditProcessingFlow() {
    const {
        environments,
        processors,
        updateProcessingFlow,
        getProcessingFlow,
    } = useProcessing();
    const [flowDefinition, setFlowDefinition] = useState<ProcessingFlow[]>([]);
    const { galleryId, flowId } = useParams();
    const [feedback, setFeedback] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [environmentId, setEnvironmentId] = useState<number>(0);
    const [isEnabled, setIsEnabled] = useState<boolean>(true);
    const [gallery, setGallery] = useState<GalleryObject | null>(null);
    const { getGallery } = useGallery();

    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        const loadFlow = async () => {
            const response = await getProcessingFlow(
                parseInt(flowId as string),
            );

            const gallery = await getGallery(parseInt(galleryId as string));

            if (isMounted) {
                setFlowDefinition(JSON.parse(response.flowDefinition));
                setEnvironmentId(response.environmentId);
                setIsEnabled(response.enabled);
                setIsLoading(false);
                setGallery(gallery);
            }
        };

        loadFlow();
        return () => {
            isMounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let flowObject = useMemo(
        () => ({
            id: parseInt(flowId as string),
            environmentId,
            flowDefinition: JSON.stringify(flowDefinition),
            galleryId: parseInt(galleryId as string),
            enabled: isEnabled,
        }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [environmentId, flowDefinition, galleryId, isEnabled],
    );

    let onSave = useCallback(
        async (environmentId: number, enabled: boolean, definition: any) => {
            const result = await updateProcessingFlow(
                flowId ? parseInt(flowId) : 0,
                JSON.stringify(definition),
                environmentId,
                enabled,
            );

            if (result) {
                navigate(-1);
                return;
            }

            setFeedback('Failed to save flow');
        },
        [flowId, navigate, updateProcessingFlow],
    );

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div>
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        Edit | {gallery?.name}
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    Processing Flow
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>
            {feedback && <div>{feedback}</div>}
            <FlowEditor
                environments={environments}
                processors={processors}
                flow={flowObject}
                onSave={onSave}
            />
        </div>
    );
}
