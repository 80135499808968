import React from 'react';
import { Link } from 'react-router-dom';

interface ActivationActionsProps {
    row: any;
}

export default function GalleryActions({ row }: ActivationActionsProps) {
    const galleryId = row.original.id;

    return (
        <span className="Table-options">
            <Link to={`gallery/${galleryId}/edit`}>
                <i className="bi bi-pencil-fill"></i>
            </Link>
            <Link to={`gallery/${galleryId}/edit/crowdGallery`}>
                <i className="bi bi-chat-left-text"></i>
            </Link>
            <Link to={`/gallery/${galleryId}`} target="_new">
                <i className="bi bi-box-arrow-up-right"></i>
            </Link>
        </span>
    );
}
