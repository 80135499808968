interface V3ActionsProps {
    row: any;
    onRepair: () => void;
}

export default function V3Actions({ row, onRepair }: V3ActionsProps) {
    return (
        <span className="Table-options">
            <i onClick={onRepair} className="bi bi-bandaid-fill"></i>
        </span>
    );
}
