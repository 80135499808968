import '../../Common/components/Form.css';
import { Formik, Form, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import DatePickerField from '../../Common/components/DatePickerField';
import LocationField from '../../Common/components/LocationField';
import useEditActivation from './hooks/useEditActivation';
import { useEffect, useState } from 'react';
import useGetActivation from './hooks/useActivation';
import useV3Folders from './hooks/useV3Folders';
import Loading from '../../Common/components/Loading';
import { Button } from '@/src/shadComponents/ui/button';

interface ActivationObject {
    id: number;
    name: string;
    date: Date;
    location: string;
    owner: string;
    isArchived: boolean;
    v3Folder: string;
}

export default function EditActivation() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [activation, setActivation] = useState<ActivationObject | null>(null);
    const { id } = useParams();
    const { getActivation } = useGetActivation();
    const [isEditingV3Link, setIsEditingV3Link] = useState(false);
    const {
        portals,
        folders,
        getFolders,
        selectedPortal,
        isLoading: isFolderListLoading,
    } = useV3Folders();
    const { saveActivation } = useEditActivation(id ? +id : 0);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const activation = await getActivation(id ? +id : 0);
                setActivation(activation);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    if (!activation) {
        return <div></div>;
    }

    return (
        <div>
            <div className="Row-container">
                <h1>edit activation</h1>
            </div>
            <Formik
                initialValues={activation}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await saveActivation(values);
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate(-1);
                    }
                }}
            >
                {(props) => (
                    <Form
                        className="Form"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div>
                            <label htmlFor="name">name</label>
                            <Field
                                id="name"
                                name="name"
                                placeholder="Activation Name"
                            />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="date">date</label>
                            <DatePickerField id="date" name="date" />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="location">location</label>
                            <LocationField name="location" id="location" />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="v3Folder">v3 folder</label>
                            {isEditingV3Link ? (
                                <div className="Form-row">
                                    <select
                                        id="portal"
                                        name="portal"
                                        onChange={(e) => {
                                            const portal =
                                                e.target.selectedOptions[0]
                                                    .value;
                                            getFolders(portal);
                                        }}
                                    >
                                        <option value="">Select Portal</option>
                                        {portals.map(
                                            (portal, index: number) => {
                                                return (
                                                    <option
                                                        value={portal.name}
                                                        key={portal.name}
                                                    >
                                                        {portal.displayName}
                                                    </option>
                                                );
                                            },
                                        )}
                                    </select>
                                    <div className="spacer" />
                                    {isFolderListLoading ? (
                                        <i className="bi bi-hourglass-split" />
                                    ) : null}
                                    {selectedPortal ? (
                                        <Field
                                            id="v3Folder"
                                            name="v3Folder"
                                            as="select"
                                            className="Folder-select"
                                        >
                                            <option value=""></option>
                                            {folders.map((folder) => {
                                                return (
                                                    <option
                                                        value={folder.name}
                                                        key={folder.name}
                                                    >
                                                        {folder.displayName}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                    ) : null}
                                    <div className="spacer" />
                                    <div className="spacer" />
                                    <div className="spacer" />
                                    <div className="spacer" />
                                </div>
                            ) : (
                                <div className="Form-row">
                                    <span
                                        className="Read-only-value"
                                        onClick={() => setIsEditingV3Link(true)}
                                    >
                                        {activation.v3Folder
                                            ? activation.v3Folder
                                            : 'not linked '}
                                    </span>
                                </div>
                            )}
                        </div>
                        <br />
                        <br />
                        <div className="Layout-row">
                            <Button type="submit" className="Primary-button">
                                save activation
                            </Button>
                            <Button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="Secondary-button"
                            >
                                <span>cancel</span>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
