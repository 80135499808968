import { useState } from 'react';
import Loading from '../../Common/components/Loading';
import './Share.css';
import ShareTypeSelection from './ShareTypeSelect';
import ShareConfirmationAutoClose from './ShareConfirmationAutoClose';
import SharingStationForm from './SharingStationForm';
import logo from '../../Gallery/components/white_logo.png';

export default function SharingStationCreateShare({
    isSharingComplete,
    galleryInfo,
    onShare,
    emailEnabled,
    textEnabled,
    shareIdentifiers,
    shareType,
    setShareType,
}: any) {
    const [isSharing, setIsSharing] = useState(false);
    const [shareDestination] = useState('');

    return (
        <>
            <Loading isLoading={isSharing} />
            <div className="flex flex-col justify-center items-center">
                <div className="Share-container">
                    {!isSharingComplete && shareType === null ? (
                        <ShareTypeSelection
                            type={shareType}
                            onSelect={setShareType}
                            emailEnabled={emailEnabled}
                            textEnabled={textEnabled}
                            logo={galleryInfo?.logo || logo}
                        />
                    ) : null}
                    {!isSharingComplete && shareType !== null ? (
                        <SharingStationForm
                            galleryInfo={galleryInfo}
                            defaultDestination={''}
                            onShare={onShare}
                            setIsSharing={setIsSharing}
                            type={shareType}
                            onReset={() => {}}
                            shareIdentifiers={shareIdentifiers}
                        />
                    ) : null}
                    {isSharingComplete ? (
                        <ShareConfirmationAutoClose
                            reset={() => {}}
                            destination={shareDestination}
                            autoShare={false}
                        />
                    ) : null}
                </div>
            </div>
        </>
    );
}
