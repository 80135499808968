import React from 'react';

interface GalleryShareContextProps {
    setShowShareModal: (
        shareIdentifier: string,
        type?: ShareTypeEnum | undefined,
    ) => void;
    setHideShareModal?: () => void;
}

export default React.createContext<GalleryShareContextProps | null>(null);
