import React from 'react';
import '../../Common/components/Form.css';
import { Formik, Form, Field } from 'formik';
import useCreateAccount from './hooks/useCreateAccount';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Button } from '@/src/shadComponents/ui/button';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';
import { toast } from 'sonner';

interface CreateAccountFormValues {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    orgId: number;
    role: number;
}

export default function CreateAccount() {
    const { orgId } = useUserOrg();
    const initialValues: CreateAccountFormValues = {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        orgId,
        role: 1,
    };
    const navigate = useNavigate();
    const { createAccount } = useCreateAccount();

    return (
        <div>
            <div className="Row-container">
                <h1>create account</h1>
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, actions) => {
                    const response = await createAccount(values);
                    actions.setSubmitting(false);

                    if (response?.data.id) {
                        toast.success('Account created successfully');
                        navigate('/accounts');
                    } else {
                        toast.error(
                            `Account creation failed: ${response.message}`,
                        );
                    }
                }}
            >
                <Form
                    className="Form"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                >
                    <div>
                        <label htmlFor="username">username</label>
                        <Field id="username" name="username" placeholder="" />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="email">email</label>
                        <Field
                            type="email"
                            id="email"
                            name="email"
                            placeholder="yo@email.com"
                        />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="firstName">first name</label>
                        <Field id="firstName" name="firstName" placeholder="" />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="firstName">last name</label>
                        <Field id="lastName" name="lastName" placeholder="" />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="password">password</label>
                        <Field
                            id="password"
                            type="password"
                            name="password"
                            placeholder=""
                        />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="role">role</label>
                        <Field id="role" as="select" name="role" placeholder="">
                            <option value={2}>Client</option>
                            <option value={1}>User</option>
                            <option value={0}>Admin</option>
                        </Field>
                    </div>
                    <br />
                    <br />
                    <div className="Layout-row">
                        <Button type="submit" className="Primary-button">
                            create account
                        </Button>
                        <NavLink to="/accounts">
                            <Button className="Secondary-button">cancel</Button>
                        </NavLink>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}
