import { cn } from '@/src/lib/utils';
import { Popover, PopoverTrigger, PopoverContent } from './popover';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from './calendar';
import React from 'react';
import { Button } from './button';

export function DatePicker({ className, date, setDate }: any) {
    return (
        <div className={cn('grid gap-2', className)}>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        // @ts-ignore
                        variant={'outline'}
                        className={cn(
                            'w-[300px] justify-start text-left font-normal',
                            !date && 'text-muted-foreground',
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {format(date, 'LLL dd, y')}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        mode="single"
                        // @ts-ignore
                        initialFocus
                        defaultMonth={date}
                        selected={date}
                        // @ts-ignore
                        onSelect={setDate}
                        numberOfMonths={1}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}
