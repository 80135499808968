import { useState, useEffect } from 'react';
import useAuthenticatedApi, {
    HTTP_STATUS_CODES,
} from '../../../Authentication/components/hooks/useAuthenticatedApi';

type V3Folder = {
    name: string;
    displayName: string;
};

type V3Portal = {
    name: string;
    displayName: string;
};

export default function useV3Folders() {
    const authenticatedApi = useAuthenticatedApi();
    const [selectedPortal, setSelectedPortal] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [folders, setFolders] = useState<V3Folder[]>([]);
    const [portals, setPortals] = useState<V3Portal[]>([]);

    const getFolders = async (portal: string) => {
        setIsLoading(true);
        const response = await authenticatedApi.get(`/v3/events/${portal}`);
        setSelectedPortal(portal);
        if (response.status === HTTP_STATUS_CODES.OK) {
            setFolders(response.data);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const getPortals = async () => {
            const response = await authenticatedApi.get('/v3/portals');

            if (response.status === 200) {
                setPortals(response.data);
            }
        };
        getPortals();
        /* eslint-disable-next-line */
    }, []);

    return {
        getFolders,
        folders,
        portals,
        isLoading,
        selectedPortal,
    };
}
