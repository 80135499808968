import { useNavigate, useParams } from 'react-router-dom';
import useProcessing, { ProcessingFlow } from './hooks/useProcessing';
import { useEffect, useState } from 'react';
import FlowEditor from './FlowEditor';
import useGallery from '@/src/GalleryConfiguration/components/hooks/useGallery';

export default function CreateProcessingFlow() {
    const { environments, processors, createProcessingFlow } = useProcessing();
    const [flowDefinition] = useState<ProcessingFlow[]>([]);
    const { galleryId } = useParams();
    const [gallery, setGallery] = useState<GalleryObject | null>(null);
    const { getGallery } = useGallery();

    const [feedback, setFeedback] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const gallery = await getGallery(
                    galleryId ? parseInt(galleryId) : 0,
                );
                isMounted ?? setGallery(gallery);
            } catch (e) {
                console.error(e);
            }
        };
        load();

        return () => {
            isMounted = false;
        };
    }, [galleryId, getGallery]);

    return (
        <div>
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        Create | {gallery?.name}
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    Processing Flow
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>
            {feedback && <div>{feedback}</div>}
            <FlowEditor
                environments={environments}
                processors={processors}
                flow={{
                    id: 0,
                    environmentId: 0,
                    flowDefinition: JSON.stringify(flowDefinition),
                    galleryId: parseInt(galleryId as string),
                    enabled: true,
                }}
                onSave={async (environmentId, enabled, definition) => {
                    const result = await createProcessingFlow(
                        galleryId ? parseInt(galleryId) : 0,
                        JSON.stringify(definition),
                        environmentId,
                        enabled,
                    );

                    if (result) {
                        navigate(-1);
                        return;
                    }

                    setFeedback('Failed to create flow');
                }}
            />
        </div>
    );
}
