import React from 'react';
import { Link } from 'react-router-dom';
import useFeatureFlagManagment from './hooks/useFeatureFlagManagement';

interface FeatureFlagActionsProps {
    row: any;
    onDelete: () => void;
}

export default function FeatureFlagActions({
    row,
    onDelete,
}: FeatureFlagActionsProps) {
    const key = row.original.key;
    const id = row.original.id;
    const { deleteFeatureFlag } = useFeatureFlagManagment();

    const performDelete = () => {
        const deleteRequest = async () => {
            const response = await deleteFeatureFlag(key);

            if (response.status === 200) {
                onDelete();
            }
        };

        const shouldDelete = window.confirm(
            `Are you sure you would like to delete ${row.original.key}`,
        );

        if (shouldDelete) {
            deleteRequest();
        }
    };
    return (
        <span className="Table-options">
            <Link to={`${id}`}>
                <i className="bi bi-pencil-fill"></i>
            </Link>
            <i onClick={performDelete} className="bi bi-trash3-fill"></i>
        </span>
    );
}
