import { useState } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useFeatureFlagManagment() {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchFeatureFlags = async () => {
        const response = await authenticatedApi.get('/features');

        return response.data;
    };

    const deleteFeatureFlag = async (key: string) => {
        const response = await authenticatedApi.delete(`/features/${key}`);

        return response.data;
    };

    const createFeatureFlag = async (values: any) => {
        const response = await authenticatedApi.post(
            '/features',
            JSON.stringify(values),
        );

        return response.data;
    };

    const updateFeatureFlag = async (values: any) => {
        const response = await authenticatedApi.put(
            `/features/${values.key}`,
            JSON.stringify(values),
        );

        return response.data;
    };

    const getFeatureFlag = async (id: number) => {
        const results = await fetchFeatureFlags();

        const flags = results.filter((result: any) => result.id === id);
        return flags[0];
    };

    const getFeatureFlagsForRoles = async () => {
        const response = await authenticatedApi.get(`/features/role`);
        const roleFeatureFlagLookup: {
            [key: string]: { keys: string[]; roleId: number };
        } = {};

        for (const association of response.data) {
            const { name, key, accountRoleId } = association;
            if (!roleFeatureFlagLookup[name]) {
                roleFeatureFlagLookup[name] = {
                    keys: [],
                    roleId: accountRoleId,
                };
            }
            roleFeatureFlagLookup[name].keys.push(key);
        }

        const roleFeatureFlagLookupTable = Object.keys(
            roleFeatureFlagLookup,
        ).map((role) => ({
            name: role,
            keys: roleFeatureFlagLookup[role].keys,
            roleId: roleFeatureFlagLookup[role].roleId,
        }));

        return roleFeatureFlagLookupTable;
    };

    const getFeatureFlagsForRole = async (roleId: number): Promise<any[]> => {
        const response = await authenticatedApi.get(`/features/role/${roleId}`);

        return response.data;
    };

    const addFeatureFlagToRole = async (
        roleId: number,
        featureFlagId: number,
    ): Promise<any> => {
        const response = await authenticatedApi.post(
            `/features/${featureFlagId}/role`,
            JSON.stringify({ roleId }),
        );

        return response.data;
    };

    const removeFeatureFlagFromRole = async (
        roleId: number,
        featureFlagId: number,
    ): Promise<any> => {
        const response = await authenticatedApi.delete(
            `/features/${featureFlagId}/role/${roleId}`,
        );

        return response.data;
    };

    return {
        isLoading,
        fetchFeatureFlags,
        deleteFeatureFlag,
        createFeatureFlag,
        updateFeatureFlag,
        getFeatureFlag,
        setIsLoading,
        getFeatureFlagsForRole,
        getFeatureFlagsForRoles,
        addFeatureFlagToRole,
        removeFeatureFlagFromRole,
    };
}
