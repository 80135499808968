import { useState } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';
import { toast } from 'sonner';

export default function useActivation() {
    const [isZipping, setIsZipping] = useState(false);
    const authenticatedApi = useAuthenticatedApi();
    const getActivation = async (activationId: number) => {
        const response: any = await authenticatedApi.get(
            `/activations/${activationId}`,
        );

        const { data } = response;

        const activation: ActivationObject = {
            // @ts-ignore
            id: activationId,
            name: data.name,
            date: data.date.split('T')[0],
            location: data.location,
            isArchived: data.isArchived,
            owner: data.createdBy,
            v3Folder: data.v3Folder,
            jobId: data.jobId,
        };

        return activation;
    };

    const getActivationPhotos = async (
        activationId: number,
        typeFilter: string[],
    ) => {
        const response = await authenticatedApi.get(
            `/activations/${activationId}/photos?filter=${encodeURIComponent(
                typeFilter.join(','),
            )}`,
        );

        const { data } = response;

        return data;
    };

    const getSessionPhotos = async (
        activationId: number,
        photoSessionId: string,
    ) => {
        const response = await authenticatedApi.get(
            `/activations/${activationId}/session/${photoSessionId}/photos`,
        );

        const { data } = response;

        return data;
    };

    const downloadActivationPhotos = async (
        activationId: number,
        typeFilter: string[],
    ) => {
        setIsZipping(true);
        const response = await authenticatedApi
            .get(
                `/media/download/${activationId}?filter=${encodeURIComponent(
                    typeFilter.join(','),
                )}`,
                { responseType: 'blob' },
            )
            .catch((err) => {
                toast('Error downloading photos');
                setIsZipping(false);
                return null;
            });

        if (!response) {
            toast('Error downloading photos');
            setIsZipping(false);
            return;
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'gallery.zip');
        document.body.appendChild(link);
        link.click();
        setIsZipping(false);
    };

    const getActivationMediaStats = async (activationId: number) => {
        const response = await authenticatedApi.get(
            `/activations/${activationId}/mediaStats`,
        );

        const { data } = response;

        return data;
    };

    const getActivationSessions = async (activationId: number) => {
        const response = await authenticatedApi.get(
            `/activations/${activationId}/sessions`,
        );

        const { data } = response;

        return data;
    };

    return {
        getSessionPhotos,
        isZipping,
        getActivation,
        getActivationPhotos,
        downloadActivationPhotos,
        getActivationMediaStats,
        getActivationSessions,
    };
}
