import GalleryView from '../../Common/components/GalleryView';

interface PhotoManagerListProps {
    photos: MediaInstance[];
}

export default function PhotoManagerList({ photos }: PhotoManagerListProps) {
    return (
        <div>
            <GalleryView media={photos} />
        </div>
    );
}
