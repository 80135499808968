type DefinitionFeedbackObject = {
    warnings: string[];
    errors: string[];
};

export default class DefinitionParser {
    static instance = new DefinitionParser();
    static getInstance() {
        if (!DefinitionParser.instance) {
            DefinitionParser.instance = new DefinitionParser();
        }
        return DefinitionParser.instance;
    }

    private constructor() {
        // Private constructor to prevent instantiation
    }

    parseDefinition(definition: string) {
        const parsedDefinition = JSON.parse(definition);
        return parsedDefinition;
    }

    stringifyDefinition(definition: any) {
        return JSON.stringify(definition);
    }

    private validateDefinition(definition: Definition): {
        isValid: boolean;
        warnings: string[];
        errors: string[];
    } {
        if (!definition || typeof definition !== 'object') {
            return {
                isValid: false,
                errors: ['Definition is not an object'],
                warnings: [],
            };
        }

        const { screens } = definition;

        if (!Array.isArray(screens)) {
            return {
                isValid: false,
                errors: ['Screens is not an array'],
                warnings: [],
            };
        }

        const {
            warnings: processingFlowWarnings,
            errors: processingFlowErrors,
        } = this.detectProcessingFlows(definition);

        // Generate Variable Map
        const {
            variableMap,
            errors: variableMapErrors,
            warnings: variableMapWarnings,
        } = this.generateVariableMap(definition);

        const {
            dynamicTextMap,
            errors: dynamicTextMapErrors,
            warnings: dynamicTextMapWarnings,
        } = this.generateDynamicTextMap(definition);

        const { errors: navigationErrors, warnings: navigationWarnings } =
            this.validateNavigation(definition);

        // Detect unresolved variables
        // Detect variables used before they are defined
        // Detect unused variables
        // Detect all variables created in the definition
        // Detect all navigation paths
        // Detect if a reset action is present
        // Detect if a capture step is present
        // Detects if a processing flow is present
        // Detect if a processing flow is not used

        console.log('Variable Map:', variableMap);
        console.log('Dynamic Text Map:', dynamicTextMap);

        return {
            isValid: true,
            warnings: [
                ...processingFlowWarnings,
                ...variableMapWarnings,
                ...dynamicTextMapWarnings,
                ...navigationWarnings,
            ],
            errors: [
                ...processingFlowErrors,
                ...variableMapErrors,
                ...dynamicTextMapErrors,
                ...navigationErrors,
            ],
        };
    }

    private validateNavigation(definition: Definition) {
        const { screens } = definition;
        let errors: string[] = [];
        let warnings: string[] = [];

        let screenIds = screens.map((screen) => {
            return screen.id;
        });

        screens.forEach((screen) => {
            const { stage } = screen;

            if (stage.metadata) {
                let metadata = stage.metadata as CaptureMetadata;
                if (metadata?.postCaptureDestination) {
                    if (!screenIds.includes(metadata.postCaptureDestination)) {
                        errors.push(
                            `Invalid screen reference: ${metadata.postCaptureDestination}, check postCaptureDestination for screen ${screen.id}.`,
                        );
                    }
                }
            }
        });

        return { errors, warnings };
    }

    private generateVariableMap(definition: Definition) {
        const { screens } = definition;
        const variableMap: Record<string, string> = {};
        let errors: string[] = [];
        let warnings: string[] = [];

        screens.forEach((screen) => {
            screen.elements.forEach((element) => {
                if (element.type === 'input') {
                    const { metadata, id } = element;

                    if (!metadata.key) {
                        warnings.push(
                            `Input element with id ${id} does not have a key`,
                        );
                        return;
                    }

                    variableMap[id] = metadata.key ?? '';
                }
            });

            if (screen.stage.type === 'capture') {
                const metadata = screen.stage.metadata as CaptureMetadata;

                if (!metadata) {
                    errors.push(
                        `Capture stage with id ${screen.id} does not have a flowId`,
                    );
                }

                if (metadata.mode === 'clicker') {
                    if (!metadata.shouldNavigateAfterCapture) {
                        variableMap[
                            `clicker-${screen.id}`
                        ] = `clicker-${screen.id}`;
                    }
                }

                if (metadata.mode === 'gif') {
                    variableMap[`gif-${screen.id}-0`] = `gif-${screen.id}-0`;
                    variableMap[`gif-${screen.id}-1`] = `gif-${screen.id}-1`;
                    variableMap[`gif-${screen.id}-2`] = `gif-${screen.id}-2`;
                    variableMap[`gif-${screen.id}-3`] = `gif-${screen.id}-3`;
                    variableMap[`gif-${screen.id}-4`] = `gif-${screen.id}-4`;
                    variableMap[`gif-${screen.id}-5`] = `gif-${screen.id}-5`;
                    variableMap[`gif-${screen.id}-6`] = `gif-${screen.id}-6`;
                    variableMap[`gif-${screen.id}-7`] = `gif-${screen.id}-7`;
                }

                if (metadata.mode === 'sequence' && metadata.photoCount) {
                    for (let i = 0; i < metadata.photoCount; i++) {
                        variableMap[
                            `sequence-${screen.id}-${i}`
                        ] = `sequence-${screen.id}-${i}`;
                    }
                }
            }
        });

        return { variableMap, errors, warnings };
    }

    private generateDynamicTextMap(definition: Definition) {
        const { screens } = definition;
        const dynamicTextMap: Record<string, string> = {};
        let errors: string[] = [];
        let warnings: string[] = [];

        screens.forEach((screen) => {
            screen.elements.forEach((element) => {
                if (element.type === 'dynamicText') {
                    const { metadata, id } = element;

                    if (!metadata.identifier) {
                        errors.push(
                            `DynamicText element with id ${id} does not have an identifier`,
                        );
                        return;
                    }

                    dynamicTextMap[`${screen.id}-${id}`] =
                        metadata.identifier ?? '';
                }
            });
        });

        return { dynamicTextMap, errors, warnings };
    }

    private detectProcessingFlows(
        definition: Definition,
    ): DefinitionFeedbackObject {
        const { processingFlows } = definition;
        const processingFlowIds = Object.keys(processingFlows);

        const warnings: string[] = [];
        const errors: string[] = [];

        if (processingFlowIds.length === 0) {
            warnings.push('No processing flows present');
        }

        const captureStages = this.getCaptureStages(definition);
        const processingStages = this.getProcessingStages(definition);

        let captureStageProcessingFlowIds = captureStages.map((stage) => {
            return stage.flowId;
        });

        let processingStageProcessingFlowIds = processingStages.map((stage) => {
            return stage.flowId;
        });

        captureStageProcessingFlowIds.forEach((flowId) => {
            if (!processingFlowIds.includes(flowId.toString())) {
                errors.push(
                    `Capture stage with flowId ${flowId} does not have a processing flow`,
                );
            }
        });

        processingStageProcessingFlowIds.forEach((flowId) => {
            if (!processingFlowIds.includes(flowId.toString())) {
                errors.push(
                    `Processing stage with flowId ${flowId} does not have a processing flow`,
                );
            }
        });

        return { warnings, errors };
    }

    private getCaptureStages(definition: Definition): CaptureMetadata[] {
        const { screens } = definition;
        const captureStages = screens.filter((screen) => {
            return screen.stage.type === 'capture';
        });

        const captureMetadata = captureStages.map((screen) => {
            return screen.stage.metadata as CaptureMetadata;
        });

        return captureMetadata;
    }

    private getProcessingStages(definition: Definition) {
        const { screens } = definition;
        const processingStages = screens.filter((screen) => {
            return screen.stage.type === 'processing';
        });

        const processingMetadata = processingStages.map((screen) => {
            return screen.stage.metadata as ProcessingMetadata;
        });

        return processingMetadata;
    }

    analyzeDefinition(definition: Definition) {
        const { screens, processingFlows, definitionVersion } = definition;

        const { warnings, errors, isValid } =
            this.validateDefinition(definition);

        return {
            screenCount: screens.length,
            processingFlowCount: Object.keys(processingFlows).length,
            definitionVersion,
            warnings,
            isValid,
            errors,
        };
    }
}
