import { SurveyAnswers } from '../SharingSurvey';
import api from '../../../services/api';

export default function useShareSurvey() {
    const saveSurveyAnswers = async (
        answers: SurveyAnswers,
        questions: SurveyQuestion[],
        activationId: number,
        shareId: number,
    ) => {
        const questionsWithAnswers = questions.map((question) => {
            const answer = answers[question.id];
            return {
                questionId: question.id,
                answer,
                answerTypeId: question.answerTypeId,
            };
        });

        const response = api.post(
            `/share/activation/${activationId}/share/survey-answers`,
            {
                answers: questionsWithAnswers,
                shareId,
            },
        );

        return response;
    };

    return {
        saveSurveyAnswers,
    };
}
