import Loading from '../../Common/components/Loading';
import CreateShareForm from './CreateShareForm';
import './Share.css';
import ShareConfirmation from './ShareConfirmation';
import ShareTypeSelection from './ShareTypeSelect';
import logo from '../../Gallery/components/white_logo.png';

export default function CreateShare({
    isSharing,
    isSharingComplete,
    shareType,
    setShareType,
    galleryInfo,
    shareDestination,
    onShare,
    autoShare,
    setIsSharing,
    emailEnabled,
    textEnabled,
    reset,
    shareIdentifiers,
    showFooter = true,
}: any) {
    return (
        <>
            <Loading isLoading={isSharing} />
            <div className="flex flex-1 justify-center items-center centered column">
                <div className="Share-container">
                    {!isSharingComplete && shareType === null ? (
                        <ShareTypeSelection
                            type={shareType}
                            onSelect={setShareType}
                            emailEnabled={emailEnabled}
                            textEnabled={textEnabled}
                            logo={galleryInfo?.logo || logo}
                        />
                    ) : null}
                    {!isSharingComplete && shareType !== null ? (
                        <CreateShareForm
                            galleryInfo={galleryInfo}
                            defaultDestination={shareDestination}
                            onShare={onShare}
                            setIsSharing={setIsSharing}
                            type={shareType}
                            onReset={reset}
                            shareIdentifiers={shareIdentifiers}
                        />
                    ) : null}
                    {isSharingComplete ? (
                        <ShareConfirmation
                            reset={reset}
                            destination={shareDestination}
                            autoShare={autoShare}
                        />
                    ) : null}
                </div>
            </div>
        </>
    );
}
