import api from '../../../services/api';
import useAuth from './useAuth';

export default function useLogout() {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});

        try {
            await api('/logout', {
                withCredentials: true,
            });
        } catch (error) {
            console.error(error);
        }
    };

    return logout;
}
