import { useContext } from 'react';
import FeatureFlagContext from './FeatureFlagContext';

interface FeatureFlagProps {
    flag: string;
    fallback?: () => React.ReactElement;
    children: React.ReactElement;
}

export default function FeatureFlag({
    flag,
    fallback,
    children,
}: FeatureFlagProps) {
    const featureFlags = useContext(FeatureFlagContext);

    if (!featureFlags[flag]) {
        return fallback ? fallback() : null;
    }

    return children;
}
