import { useEffect, useState } from 'react';
import useAssets from './hooks/useAssets';
import LoadingIndicator from '../../Common/components/LoadingIndicator';
import AssetUpload from './AssetUpload';
import { Button } from '@/src/shadComponents/ui/button';

interface Asset {
    id: number;
    type: string;
    url: string;
    name: string;
    width: number;
    height: number;
    folder: string;
    bytes: number;
    createdAt: string;
}

interface AssetPickerProps {
    onAssetSelected: (asset: Asset) => void;
    onClose: () => void;
}

export default function AssetPicker({
    onAssetSelected,
    onClose,
}: AssetPickerProps) {
    const [assets, setAssets] = useState<Asset[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [displayUploader, setDisplayUploader] = useState(false);
    const { getAssets, uploadAsset } = useAssets();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const assets = await getAssets();
                isMounted && setAssets(assets);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="Asset-modal-container">
            <div className="modal">
                {displayUploader ? (
                    <AssetUpload
                        onSubmit={async (...args) => {
                            // @ts-ignore
                            const result = await uploadAsset(...args);
                            setAssets([...assets, result]);
                            setSelectedAsset(result);
                            setDisplayUploader(false);
                        }}
                    />
                ) : (
                    <div className="row">
                        <div className="list">
                            {[...assets].reverse().map((asset) => (
                                <div
                                    key={asset.url}
                                    onClick={() => setSelectedAsset(asset)}
                                >
                                    {asset.name}
                                </div>
                            ))}
                        </div>
                        <div className="preview">
                            <h5>Preview: {selectedAsset?.name}</h5>
                            {selectedAsset && (
                                <div className="column">
                                    <img
                                        src={selectedAsset.url}
                                        alt="preview"
                                        className="max-h-[200 px]"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className="row">
                    <Button
                        className="Secondary-button"
                        onClick={() => setDisplayUploader(true)}
                    >
                        upload
                    </Button>
                    <div className="spacer" />
                    <Button
                        className="Primary-button"
                        onClick={() => {
                            selectedAsset && onAssetSelected(selectedAsset);
                            onClose();
                        }}
                    >
                        select
                    </Button>
                    <Button className="Secondary-button" onClick={onClose}>
                        cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}
