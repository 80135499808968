import TextAreaFieldType from './fieldTypes/TextAreaFieldType';
import TextFieldType from './fieldTypes/TextFieldType';

interface FieldFactoryProps {
    field: DocumentField;
}

export default function FieldFactory({ field }: FieldFactoryProps) {
    switch (field.type) {
        case 'text':
            return <TextFieldType field={field} />;
        case 'textarea':
            return <TextAreaFieldType field={field} />;
        default:
            return null;
    }
}
