import Loading from '../../../Common/components/Loading';
import logo from '../../../smile_icon.png';
import useDbOfSmiles from './hooks/useDbOfSmiles';
import '../Gallery.css';

import './DBofSmiles.css';
import { useEffect, useState } from 'react';

const year = new Date().getFullYear();

export function DBOfSmiles() {
    const { loading, smiles, matches, findSmile } = useDbOfSmiles();
    const [showSearch, setShowSearch] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
        findSmile(query);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    if (loading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div className="db-of-smiles-container">
            <div className="dos-header">
                <h1>database</h1>
                <h2>of</h2>
                <h2>smiles.</h2>
                <h4 onClick={() => setShowSearch(true)}>find yourself</h4>
                <div className="dos-header-welcome">
                    <h3>Welcome to our</h3>
                </div>
            </div>
            {showSearch ? (
                <div className="centered search">
                    <div className="searchBox">
                        <span>enter your number</span>
                        <input
                            type="text"
                            placeholder="your digits"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                        <div className="row">
                            <span
                                className="Tertiary-button"
                                onClick={() => {
                                    setShowSearch(false);
                                    setTimeout(() => {
                                        const photos =
                                            document.getElementsByClassName(
                                                'photo-hit',
                                            );
                                        photos[0]?.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                    }, 500);
                                }}
                            >
                                search!
                            </span>
                            &nbsp; &nbsp; &nbsp;
                            <span
                                className="Tertiary-button"
                                onClick={() => setQuery('')}
                            >
                                clear
                            </span>
                            &nbsp; &nbsp; &nbsp; &nbsp;
                            <span
                                className="Tertiary-button"
                                onClick={() => setShowSearch(false)}
                            >
                                close
                            </span>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="centered">
                <section id="photos">
                    {smiles.map((smile, index) => (
                        <img
                            src={smile.originalUri}
                            alt="smile"
                            id={smile.shareIdentifier}
                            className={
                                matches
                                    ? matches.includes(smile.shareIdentifier)
                                        ? 'photo-hit'
                                        : 'photo-miss'
                                    : ''
                            }
                            key={`smile.shareIdentifier${index}`}
                        />
                    ))}
                </section>
            </div>

            <div className="Site-footer">
                <a
                    href="https://www.smilebooth.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img className="logo" src={logo} alt="Smilebooth Logo" />
                </a>
                <div className="spacer" />
                <span className="Small-text">
                    copyright &copy; {year} smilebooth
                </span>
            </div>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
            ></meta>
        </div>
    );
}
