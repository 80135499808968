import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';

const firebaseConfig = {
    apiKey: 'AIzaSyCVdiy40zdbvUwvr7fVHLXER6Y-BzDVHhg',
    authDomain: 'photostat-366604.firebaseapp.com',
    projectId: 'photostat-366604',
    storageBucket: 'photostat-366604.appspot.com',
    messagingSenderId: '475304023009',
    appId: '1:475304023009:web:fb51daf0fe05e07c8e78a0',
    measurementId: 'G-Y1M6TEE6J5',
};

const app = initializeApp(firebaseConfig);

getAnalytics(app);
getPerformance(app);

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);

reportWebVitals(console.log);
