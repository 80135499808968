import React from 'react';
import { Link } from 'react-router-dom';
import useProcessing from './hooks/useProcessing';

interface ProcessingActionsProps {
    row: any;
    galleryId: number;
    onDelete: () => void;
}

export default function ProcessingActions({
    row,
    galleryId,
    onDelete,
}: ProcessingActionsProps) {
    const { deleteProcessingFlow } = useProcessing();

    return (
        <span className="Table-options">
            <Link
                to={`gallery/${galleryId}/processing/edit/${row.original.id}`}
            >
                <i className="bi bi-pencil-fill"></i>
            </Link>
            <i
                onClick={async () => {
                    const deleteRecord = async () => {
                        await deleteProcessingFlow(row.original.id);
                        onDelete();
                    };

                    const shouldDelete = window.confirm(
                        `Are you sure you would like to delete this flow?`,
                    );

                    if (shouldDelete) {
                        deleteRecord();
                    }
                }}
                className="bi bi-trash3-fill"
            ></i>
        </span>
    );
}
