import useAuthenticatedApi, {
    HTTP_STATUS_CODES,
} from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useEditAccount() {
    const authenticatedApi = useAuthenticatedApi();

    const editAccount = async (userObject: any) => {
        const response = await authenticatedApi.put(
            `/accounts/${userObject.id}`,
            JSON.stringify(userObject)
        );

        return response.status === HTTP_STATUS_CODES.OK;
    };

    const setPassword = async (accountId: number, password: string) => {
        const response = await authenticatedApi.put(
            `/accounts/${accountId}/password`,
            JSON.stringify({
                password,
            })
        );
        return response.status === HTTP_STATUS_CODES.OK;
    };

    return {
        editAccount,
        setPassword,
    };
}
