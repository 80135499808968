import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useActivation from './hooks/useActivation';
import PhotoManagerList from './PhotoManagerList';
import BulkUploadMedia from '@/src/Common/components/BulkUploadMedia';
import { DownloadIcon } from '@radix-ui/react-icons';
import { UploadIcon } from 'lucide-react';
import Loading from '@/src/Common/components/Loading';

const filterOptions = [
    { name: 'All', value: 'all' },
    { name: 'Photo', value: 'processed' },
    { name: 'Video', value: 'processed-video' },
    { name: 'Edited', value: 'user-edit' },
    { name: 'Prints', value: 'composite' },
    { name: 'Originals', value: 'original' },
];

export default function PhotoManager() {
    const [showHidden, setShowHidden] = useState(false);
    const { activationId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [, setActivation] = useState<ActivationObject | null>(null);
    const [photos, setPhotos] = useState<MediaInstance[]>([]);
    const [typeFilter, setTypeFilter] = useState<string[]>([]);
    const [isShowingBulkUpload, setIsShowingBulkUpload] = useState(false);

    const {
        getActivation,
        getActivationPhotos,
        downloadActivationPhotos,
        isZipping,
    } = useActivation();

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const activation = await getActivation(
                    activationId ? +activationId : 0,
                );

                setActivation(activation);

                const photos = await getActivationPhotos(
                    activation.id,
                    typeFilter,
                );

                if (!showHidden) {
                    setPhotos(
                        photos.filter(
                            (photo: MediaInstance) => !photo.isHidden,
                        ),
                    );
                    return;
                }

                setPhotos(photos);
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showHidden, typeFilter]);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div>
            <div className="flex flex-row px-2">
                <span className="inline-title">
                    photo count: {photos.length}
                </span>
                <span className="inline-title">
                    &nbsp; &nbsp; Filter:&nbsp;
                    <select
                        onChange={(e) => {
                            if (
                                e.target.value === 'undefined' ||
                                e.target.value === 'all'
                            ) {
                                setTypeFilter([]);
                                return;
                            }

                            setTypeFilter([e.target.value]);
                        }}
                    >
                        {filterOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </span>
                <span className="inline-title">
                    &nbsp;&nbsp; Include Hidden?
                    <input
                        type="checkbox"
                        onChange={() => setShowHidden((hidden) => !hidden)}
                        checked={showHidden}
                    />
                </span>
                <div className="grow" />
                <span className="inline-title">
                    {!isZipping ? (
                        <DownloadIcon
                            className="h-6 w-6"
                            onClick={() => {
                                if (!activationId) {
                                    return;
                                }
                                downloadActivationPhotos(
                                    +activationId,
                                    typeFilter,
                                );
                            }}
                        />
                    ) : (
                        <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    )}
                </span>
                <span className="inline-title">
                    <UploadIcon onClick={() => setIsShowingBulkUpload(true)} />
                </span>
            </div>
            <PhotoManagerList photos={photos} />
            {isShowingBulkUpload ? (
                <BulkUploadMedia
                    onCancel={() => setIsShowingBulkUpload(false)}
                    onComplete={() => {
                        setIsShowingBulkUpload(false);
                        alert('upload complete');
                    }}
                    activationId={activationId ? +activationId : 0}
                />
            ) : null}
        </div>
    );
}
