type NavigationItem = {
    name: string;
    href: string;
    current: boolean;
    icon: any;
};

interface PageWithHeaderProps {
    children: React.ReactNode;
    title: string;
    subtitle: string;
    actions?: React.ReactNode;
    navigation?: NavigationItem[];
    filters?: React.ReactNode;
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export default function PageWithSidebar({
    children,
    title,
    subtitle,
    actions,
    navigation = [],
    filters = null,
}: PageWithHeaderProps) {
    return (
        <div>
            <div className="absolute top-[43px] bottom-0 z-5 flex w-16 lg:w-72 flex-col border-r-2 b-black/100">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white/10 px-6 ring-1 ring-white/5">
                    <div className="flex h-2 shrink-0 items-center"></div>
                    <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul className="-mx-2 space-y-1">
                                    {navigation.map((item) => (
                                        <li key={item.name}>
                                            <a
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-gray-100 text-black'
                                                        : 'text-gray-900 hover:text-black hover:bg-gray-100',
                                                    'group flex gap-x-3 rounded-md p-1 text-[14px] leading-6 font-semibold',
                                                )}
                                            >
                                                <item.icon
                                                    className="h-5 w-5 shrink-0 mt-0.5"
                                                    aria-hidden="true"
                                                />
                                                <p className="text-sm mt-0.5 hidden lg:block">
                                                    {item.name}
                                                </p>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <main className="pl-16 lg:pl-72 height-minus-header">
                <div className="relative isolate">
                    <header className="pb-4 pt-6 sm:pb-6">
                        <div className="mx-auto flex max-w-10xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                            <h1 className="text-base font-semibold leading-7 text-gray-900">
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        {subtitle}
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    {title}
                                </div>
                            </h1>
                            <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                                {filters ? filters : null}
                            </div>
                            <div className="spacer"></div>
                            {actions ? actions : null}
                        </div>
                    </header>
                    {children}
                </div>
            </main>
        </div>
    );
}
