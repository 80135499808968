import api from '@/src/services/api';

export default function useGalleryUserManagement() {
    const createGalleryUser = async (
        galleryId: number,
        destination: string,
    ) => {
        const results = await api
            .post(`/gallery-users/create`, {
                galleryId,
                destination,
            })
            .catch((e) => {
                console.error(e);
            });

        return results;
    };

    return {
        createGalleryUser,
    };
}
