import '../Gallery.css';

import useAnimatedCollageWall from './hooks/useAnimatedCollageWall';
import { useParams } from 'react-router-dom';

export function AnimatedCollageWall() {
    const { activationId } = useParams();
    const { displayedPhotos } = useAnimatedCollageWall(
        activationId ? +activationId : 0,
    );

    return (
        <div className="absolute h-screen w-screen">
            {displayedPhotos.map((smile, index) => {
                let photo = smile.photo;
                return (
                    <img
                        src={
                            photo.thumbnail_800pxUri
                                ? photo.thumbnail_800pxUri
                                : photo.originalUri
                        }
                        alt="smile"
                        id={photo.shareIdentifier}
                        key={photo.shareIdentifier}
                        style={{
                            position: 'absolute',
                            top: smile.y,
                            left: smile.x,
                            maxWidth: '200px',
                        }}
                    />
                );
            })}

            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
            ></meta>
        </div>
    );
}
