import { NavLink, useParams } from 'react-router-dom';
import Table, { createColumnHelper } from '../../Common/components/Table';
import useUserOrg from '../../Authentication/components/hooks/useUserOrg';
import { useDeviceInviteList } from './hooks/useDeviceInviteList';
import DeviceInviteActions from './DeviceInviteActions';
import { Button } from '@/src/shadComponents/ui/button';
import Loading from '@/src/Common/components/Loading';

const columnHelper = createColumnHelper<DeviceInvite>();

export default function DeviceInvites() {
    const { orgId } = useUserOrg();
    const { groupId } = useParams();
    const { deviceInvites, isLoading } = useDeviceInviteList(
        orgId,
        +(groupId || 0),
    );

    const columnDefinitions = [
        columnHelper.accessor('name', {
            id: 'name',
            header: () => <span>name</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <DeviceInviteActions
                    row={props.row}
                    orgId={orgId}
                    onDelete={() => null}
                />
            ),
        }),
    ];

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div>
            <div className="row flex mx-8 my-4">
                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                    Device Invites
                </div>
                <div className="spacer"></div>
                <NavLink to="/device-management/device-invites/create">
                    <Button className="Primary-button"> + Invite Device</Button>
                </NavLink>
                <br />
            </div>
            <br />
            {deviceInvites.length === 0 ? (
                <div className="mx-8">
                    There are no open invites for this group
                </div>
            ) : (
                <Table data={deviceInvites} columns={columnDefinitions} />
            )}
        </div>
    );
}
