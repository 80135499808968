import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '../../../Authentication/components/hooks/useUserOrg';

export default function useDeviceGroupAssignment() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const getDeviceAssignments = async () => {
        const response = await authenticatedApi.get(
            `${orgId}/device-management/assignments`,
        );
        return response.data;
    };

    const addDeviceAssignmentsForGallery = async (
        galleryId: number,
        deviceGroups: number[],
    ) => {
        const response = await authenticatedApi.post(
            `${orgId}/device-groups/assignments/gallery`,
            JSON.stringify({
                deviceGroups,
                galleryId,
            }),
        );

        return response.data;
    };

    const listDeviceAssignmentsForGallery = async (galleryId: number) => {
        const response = await authenticatedApi.get(
            `${orgId}/device-groups/assignments/gallery/${galleryId}`,
        );

        console.log(response.data);
        return response.data;
    };

    return {
        getDeviceAssignments,
        addDeviceAssignmentsForGallery,
        listDeviceAssignmentsForGallery,
    };
}
