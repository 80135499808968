import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';

export default function useOrgAccounts() {
    const authenticatedApi = useAuthenticatedApi();

    const getAccounts = async (orgId: number) => {
        const response = await authenticatedApi.get(`${orgId}/accounts`);
        return response.data;
    };

    return {
        getAccounts,
    };
}
