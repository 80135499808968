import { useCallback, useState } from 'react';
import ListPickerAdd from './ListPickerAdd';
import { ScrollArea } from '@/src/shadComponents/ui/scroll-area';
import { Checkbox } from '@/src/shadComponents/ui/checkbox';
import { Label } from '@/src/shadComponents/ui/label';
import { Button } from '@/src/shadComponents/ui/button';

interface ListItem {
    id: number;
    name: string;
}

interface ListPickerModalProps {
    list: ListItem[];
    selectedIds: number[];
    onSave: (selectedIds: number[]) => void;
    onClose: () => void;
    allowAdd?: boolean;
    onAdd?: (label: string) => void;
}

export default function ListPickerModal({
    list,
    selectedIds,
    onClose,
    onSave,
    allowAdd = false,
    onAdd,
}: ListPickerModalProps) {
    const [localSelectedIds, setLocalSelectedIds] = useState(selectedIds);

    const onChange = useCallback(
        (listItemId: number) => {
            if (!localSelectedIds.includes(listItemId)) {
                setLocalSelectedIds([...localSelectedIds, listItemId]);
            } else {
                const modifiedIds = [...localSelectedIds];
                modifiedIds.splice(modifiedIds.indexOf(listItemId), 1);
                setLocalSelectedIds(modifiedIds);
            }
        },
        [localSelectedIds],
    );

    return (
        <div className="List-picker-modal-container">
            <div className="modal">
                {allowAdd && <ListPickerAdd onSubmit={onAdd} />}
                <ScrollArea className="h-[500px] rounded-md border p-4">
                    <div className="list-2">
                        {list.map((listItem) => (
                            <div key={listItem.id} className="list-item">
                                <Checkbox
                                    onClick={() => onChange(listItem.id)}
                                    id={`${listItem.id}_item`}
                                    checked={localSelectedIds.includes(
                                        listItem.id,
                                    )}
                                />
                                <Label
                                    className=" pl-4"
                                    htmlFor={`${listItem.id}_item`}
                                >
                                    {listItem.name}
                                </Label>
                            </div>
                        ))}
                    </div>
                </ScrollArea>
                <br />
                <div className="flex flex-row justify-end">
                    <Button
                        className="Tertiary-button"
                        onClick={() => onSave(localSelectedIds)}
                    >
                        save
                    </Button>
                    <Button className="Secondary-button" onClick={onClose}>
                        cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}
