import { useDispatch, useSelector } from 'react-redux';
import { updateScreenMetadata } from './utils/state/screensSlice';
import DialogFormRow from './DialogFormRow';
import { Switch } from '../shadComponents/ui/switch';

interface CaptureMetadataFormProps {
    screen: ScreenDefinition;
}

export default function CaptureMetadataForm({
    screen,
}: CaptureMetadataFormProps) {
    const metadata = screen.stage.metadata as CaptureMetadata;
    const dispatch = useDispatch();

    const screenNames: [string] = useSelector((state: any) => {
        return state.present.screens.ids.map(
            (id: string) => state.present.screens.entities[id].id,
        );
    });

    const flows: [{ id: string; flow: ProcessingFlow; description: string }] =
        useSelector((state: any) => {
            return state.present.flows.ids.map((id: string) => {
                return {
                    id,
                    flow: state.present.flows.entities[id],
                    description: state.present.flows.entities[id].reduce(
                        (result: string, flow: any) => {
                            if (result === '') {
                                return flow.name;
                            }

                            return `${result}->${flow.name}`;
                        },
                        '',
                    ),
                };
            });
        });

    console.log(flows);
    if (!metadata) {
        return null;
    }

    /*const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let updatedMetadata = { ...metadata, [name]: value };

        dispatch(
            updateScreenMetadata({ id: screen.id, metadata: updatedMetadata }),
        );
    };*/

    const handleSwitchChange = (name: string, value: boolean) => {
        let updatedMetadata = { ...metadata, [name]: value };

        dispatch(
            updateScreenMetadata({ id: screen.id, metadata: updatedMetadata }),
        );
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        let updatedMetadata = { ...metadata, [name]: value };

        dispatch(
            updateScreenMetadata({ id: screen.id, metadata: updatedMetadata }),
        );
    };

    return (
        <>
            <DialogFormRow title="Mode" field="mode">
                <select
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    name="mode"
                    value={metadata.mode}
                    onChange={handleSelectChange}
                >
                    <option value="clicker">Clicker</option>
                    <option value="gif">GIF</option>
                    <option value="sequence">Sequence</option>
                </select>
            </DialogFormRow>
            <DialogFormRow title="Flow" field="flowId">
                <div className="flex flex-row">
                    <select
                        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        name="flowId"
                        value={metadata.flowId}
                        onChange={handleSelectChange}
                    >
                        {flows.map(({ id, description }) => (
                            <option key={id} value={id}>
                                ({id}) {description}
                            </option>
                        ))}
                    </select>
                    <button
                        className="ml-2 px-4 py-1.5 bg-indigo-600 text-white rounded-md"
                        onClick={() => console.log('clicked')}
                    >
                        Add&nbsp;Flow
                    </button>
                </div>
            </DialogFormRow>
            <DialogFormRow
                title="Should Navigate After Capture"
                field="shouldNavigateAfterCapture"
            >
                <Switch
                    name="shouldNavigateAfterCapture"
                    checked={metadata.shouldNavigateAfterCapture}
                    onCheckedChange={(checked: boolean) =>
                        handleSwitchChange(
                            'shouldNavigateAfterCapture',
                            checked,
                        )
                    }
                />
            </DialogFormRow>

            {metadata.shouldNavigateAfterCapture && (
                <DialogFormRow
                    title="Post Capture Destination"
                    field="postCaptureDestination"
                >
                    <select
                        name="postCaptureDestination"
                        value={metadata.postCaptureDestination}
                        onChange={handleSelectChange}
                        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                        {screenNames.map((name) => (
                            <option key={name} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                </DialogFormRow>
            )}
        </>
    );
}
