import { useState, useEffect } from 'react';
import useAuthenticatedApi, {
    HTTP_STATUS_CODES,
} from '../../../Authentication/components/hooks/useAuthenticatedApi';

export interface ProcessingEnvironment {
    id: number;
    environment: string;
}

export interface Processor {
    name: string;
    arguments: { [key: string]: string | number };
    argumentTypes?: { [key: string]: string };
    fileCount: number;
    saveOutput: boolean;
    description?: string;
    title?: string;
    inputType?: string[] | string;
    environments?: string[];
}

export interface ProcessingFlow {
    id: number;
    galleryId: number;
    enabled: boolean;
    environmentId: number;
    flowDefinition: string;
}

export default function useProcessing() {
    const { get, post, put, delete: del } = useAuthenticatedApi();
    const [environments, setEnvironments] = useState<ProcessingEnvironment[]>(
        [],
    );
    const [processors, setProcessors] = useState<Processor[]>([]);

    const getProcessingEnvironments = async () => {
        const response = await get('/processing/processingEnvironments');
        if (response.status === HTTP_STATUS_CODES.OK) {
            return response.data;
        }
        return [];
    };

    const getProcessors = async () => {
        const response = await get('/processing/processors');
        if (response.status === HTTP_STATUS_CODES.OK) {
            return response.data;
        }
        return [];
    };

    const getProcessingFlows = async (galleryId: number) => {
        const response = await get(`/processing/gallery/${galleryId}`);
        if (response.status === HTTP_STATUS_CODES.OK) {
            return response.data;
        }
        return [];
    };

    const createProcessingFlow = async (
        galleryId: number,
        flowDefinition: string,
        environmentId: number,
        enabled: boolean,
    ) => {
        const response = await post(`/processing/gallery/${galleryId}`, {
            galleryId,
            flowDefinition,
            environmentId,
            enabled,
        });
        if (response.status === HTTP_STATUS_CODES.OK) {
            return response.data;
        }

        return false;
    };

    const updateProcessingFlow = async (
        id: number,
        flowDefinition: string,
        environmentId: number,
        enabled: boolean,
    ) => {
        const response = await put(`/processing/${id}`, {
            flowDefinition,
            environmentId,
            enabled,
        });
        if (response.status === HTTP_STATUS_CODES.OK) {
            return response.data;
        }
    };

    const getProcessingFlow = async (flowId: number) => {
        const response = await get(`/processing/${flowId}`);
        if (response.status === HTTP_STATUS_CODES.OK) {
            return response.data;
        }
    };

    const deleteProcessingFlow = async (flowId: number) => {
        const response = await del(`/processing/${flowId}`);
        if (response.status === HTTP_STATUS_CODES.OK) {
            return response.data;
        }
    };

    const testFlow = async (values: { [key: string]: string }) => {
        const formData = new FormData();
        for (let value in values) {
            formData.append(value, values[value]);
        }

        const response = await post('/processing/test', formData);

        const { data } = response;

        return data;
    };

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const environments = await getProcessingEnvironments();
                const processors = await getProcessors();

                if (isMounted) {
                    setProcessors(processors);
                    setEnvironments(environments);
                }
            } catch (error) {
                console.error(error);
            }
        };
        getData();
        return () => {
            isMounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        getProcessingEnvironments,
        environments,
        processors,
        getProcessingFlows,
        createProcessingFlow,
        updateProcessingFlow,
        getProcessingFlow,
        deleteProcessingFlow,
        testFlow,
    };
}
