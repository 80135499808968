import { useEffect, useState } from 'react';

interface FlowStepInputProps {
    name: string;
    stepNumber: number;
    argumentType: string | undefined;
    value: string | number;
    onChange: (value: string | number) => void;
    onLaunchAssetPicker?: (callback: (value: string) => void) => void;
}
export default function FlowStepInput({
    name,
    stepNumber,
    argumentType = 'string',
    value,
    onChange,
    onLaunchAssetPicker,
}: FlowStepInputProps) {
    const [inputValue, setInputValue] = useState<string | number>(value);
    const isFileurl = argumentType === 'fileurl';
    const [imageWidth, setImageWidth] = useState<number | null>(null);
    const [imageHeight, setImageHeight] = useState<number | null>(null);

    useEffect(() => {
        if (isFileurl && inputValue !== '') {
            const img = new Image();
            img.src = inputValue as string;
            img.onload = () => {
                setImageWidth(img.width);
                setImageHeight(img.height);
            };
        }
    }, [isFileurl, inputValue]);

    const key = `${name}_${stepNumber}`;

    useEffect(() => {
        if (value !== inputValue) {
            onChange(inputValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    return (
        <div className="flex flex-row" key={key}>
            <div className="flex-col flex align-center">
                <div className="capitalize">{name}:</div>
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                    className="h-8 w-96 px-2 border border-gray-300 rounded-md"
                />
            </div>
            {isFileurl && (
                <div>
                    <button
                        className="px-2 py-[4.5px] bg-gray-200 rounded-md mt-[21px] border-solid border-[1px] border-[lightgrey] cursor-pointer"
                        onClick={() =>
                            onLaunchAssetPicker &&
                            onLaunchAssetPicker(setInputValue)
                        }
                    >
                        <i className="bi bi-folder2-open" />
                    </button>
                </div>
            )}
            {isFileurl && inputValue !== '' && (
                <div className="flex-column justify-center">
                    <div className="rounded-md aspect-fit ml-4 flex-column">
                        <img
                            width={100}
                            height="auto"
                            src={inputValue as string}
                            className="rounded-md aspect-fit border-[1px] border-black bg-slate-200"
                            alt="preview"
                        />
                        <div className="text-[10px] text-center">
                            {imageWidth}px&nbsp;x&nbsp;{imageHeight}px
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
