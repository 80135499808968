import { DatePicker } from '@/src/shadComponents/ui/DatePicker';
import { useField, useFormikContext } from 'formik';

export default function DatePickerField(props: any) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
        <DatePicker
            {...field}
            {...props}
            date={field.value}
            setDate={(value: Date) => {
                setFieldValue(field.name, value);
            }}
        />
    );
}
