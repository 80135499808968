import React, { Fragment, useEffect } from 'react';
import {
    useReactTable,
    createColumnHelper,
    getCoreRowModel,
    SortingState,
    getSortedRowModel,
    ColumnFiltersState,
    FilterFn,
    getFilteredRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
import '../../Common/components/Table.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { format } from 'date-fns';
import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';

interface TableProps {
    columns: any[];
    data: any[];
    enableSorting?: boolean;
    enablePagination?: boolean;
    onDelete: () => void;
    onClone: (id: number, activation: any) => void;
}

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>;
    }
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);

    addMeta({
        itemRank,
    });

    return itemRank.passed;
};

export default function ActivationsTable({
    columns,
    data,
    onDelete,
    enableSorting = false,
    enablePagination = false,
    onClone,
}: TableProps) {
    const [sorting, setSorting] = React.useState<SortingState>([]);

    const authenticatedApi = useAuthenticatedApi();
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    const options = {
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            sorting,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getCoreRowModel: getCoreRowModel(),
    };

    if (enablePagination) {
        // @ts-ignore
        options.getPaginationRowModel = getPaginationRowModel();
    }

    // @ts-ignore
    const table = useReactTable(options);

    useEffect(() => {
        if (enablePagination && table) {
            // @ts-ignore
            table.setPageSize(25);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const performDelete = (id: number, name: string) => {
        const deleteRequest = async () => {
            const response = await authenticatedApi.put(
                `activations/${id}/archive`,
            );

            if (response.status === 200) {
                onDelete();
            }
        };

        const shouldDelete = window.confirm(
            `Are you sure you would like to archive ${name}`,
        );

        if (shouldDelete) {
            deleteRequest();
        }
    };

    return (
        <ul className="divide-y divide-gray-100 px-6">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <li
                                    key={row.getValue('id')}
                                    className="flex items-center justify-between gap-x-6 py-5"
                                >
                                    <div className="min-w-0">
                                        <div className="flex items-start gap-x-3">
                                            <p className="text-sm font-semibold leading-6 text-gray-900">
                                                <a
                                                    href={`/activations/${row.getValue(
                                                        'id',
                                                    )}`}
                                                >
                                                    {row.getValue('name')}
                                                </a>
                                            </p>
                                            {/*<p
                                                className={classNames(
                                                    statuses[
                                                        activationGroup.date
                                                    ],
                                                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                                                )}
                                            >
                                                {activationGroup.date}
                                                </p>*/}
                                        </div>
                                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                            <p className="whitespace-nowrap">
                                                <time
                                                    dateTime={row.getValue(
                                                        'date',
                                                    )}
                                                >
                                                    {format(
                                                        new Date(
                                                            row.getValue(
                                                                'date',
                                                            ),
                                                        ),
                                                        'MMM d',
                                                    )}
                                                </time>{' '}
                                                in {row.getValue('location')}
                                            </p>
                                            <svg
                                                viewBox="0 0 2 2"
                                                className="h-0.5 w-0.5 fill-current"
                                            >
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            <p className="truncate">
                                                Created by{' '}
                                                {row.getValue('owner')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-none items-center gap-x-4">
                                        <a
                                            href={`/activations/${row.getValue(
                                                'id',
                                            )}`}
                                            className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                        >
                                            View activation
                                            <span className="sr-only">
                                                , {row.getValue('name')}
                                            </span>
                                        </a>
                                        <Menu
                                            as="div"
                                            className="relative flex-none"
                                        >
                                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                                <span className="sr-only">
                                                    Open options
                                                </span>
                                                <EllipsisVerticalIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href={`/activations/${row.getValue(
                                                                    'id',
                                                                )}`}
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-gray-50'
                                                                        : '',
                                                                    'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                                )}
                                                            >
                                                                Manage
                                                                <span className="sr-only">
                                                                    ,{' '}
                                                                    {row.getValue(
                                                                        'name',
                                                                    )}
                                                                </span>
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.preventDefault();
                                                                    performDelete(
                                                                        row.getValue(
                                                                            'id',
                                                                        ),
                                                                        row.getValue(
                                                                            'name',
                                                                        ),
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-gray-50'
                                                                        : '',
                                                                    'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                                )}
                                                            >
                                                                Delete
                                                                <span className="sr-only">
                                                                    ,{' '}
                                                                    {row.getValue(
                                                                        'name',
                                                                    )}
                                                                </span>
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.preventDefault();
                                                                    onClone(
                                                                        row.getValue(
                                                                            'id',
                                                                        ),
                                                                        row.original,
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-gray-50'
                                                                        : '',
                                                                    'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                                )}
                                                            >
                                                                Clone
                                                                <span className="sr-only">
                                                                    ,{' '}
                                                                    {row.getValue(
                                                                        'name',
                                                                    )}
                                                                </span>
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </li>
                            ))}
                        </tbody>
                    </table>
                    {enablePagination ? (
                        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                            <div className="flex flex-1 justify-between sm:hidden">
                                <button
                                    onClick={() => table.previousPage()}
                                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => table.nextPage()}
                                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                >
                                    Next
                                </button>
                            </div>
                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                <nav
                                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                                    aria-label="Pagination"
                                >
                                    <button
                                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                        onClick={() => table.previousPage()}
                                    >
                                        <span className="sr-only">
                                            Previous
                                        </span>
                                        <ChevronLeftIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    {table.getPageOptions().map((page) => {
                                        if (
                                            page >
                                                table.getState().pagination
                                                    .pageIndex +
                                                    2 ||
                                            page <
                                                table.getState().pagination
                                                    .pageIndex -
                                                    2
                                        ) {
                                            return null;
                                        }
                                        const pageIndex = page;
                                        return (
                                            <button
                                                key={pageIndex}
                                                className={`${
                                                    pageIndex ===
                                                    table.getState().pagination
                                                        .pageIndex
                                                        ? 'bg-gray-200'
                                                        : 'bg-white'
                                                } relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                                                onClick={() =>
                                                    table.setPageIndex(
                                                        pageIndex,
                                                    )
                                                }
                                            >
                                                {pageIndex + 1}
                                            </button>
                                        );
                                    })}
                                    <button
                                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                        onClick={() => table.nextPage()}
                                    >
                                        <span className="sr-only">Next</span>
                                        <ChevronRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>

                                    <div className="spacer" />
                                    <div className="px-4 flex justify-center align-middle items-center">
                                        <div className="items-center row text-nowrap ">
                                            page&nbsp;
                                            {table.getState().pagination
                                                .pageIndex + 1}{' '}
                                            of {table.getPageCount()}
                                        </div>
                                    </div>
                                    <span className="flex items-center pr-4">
                                        | &nbsp;&nbsp;&nbsp;go to page: &nbsp;
                                        <input
                                            type="number"
                                            defaultValue={
                                                table.getState().pagination
                                                    .pageIndex + 1
                                            }
                                            onChange={(e) => {
                                                const page = e.target.value
                                                    ? Number(e.target.value) - 1
                                                    : 0;
                                                table.setPageIndex(page);
                                            }}
                                            className="border p-1 rounded w-16"
                                        />
                                    </span>
                                    <select
                                        value={
                                            table.getState().pagination.pageSize
                                        }
                                        onChange={(e) => {
                                            table.setPageSize(
                                                Number(e.target.value),
                                            );
                                        }}
                                    >
                                        {[25, 50, 100].map((pageSize) => (
                                            <option
                                                key={pageSize}
                                                value={pageSize}
                                            >
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </nav>
                            </div>
                        </div>
                    ) : null}
                    {data.length === 0 && <div>zero records... </div>}
                </div>
            </div>
        </ul>
    );
}

export { createColumnHelper };
