import { useCallback, useEffect, useState } from 'react';
import ListPickerModal from '../../Common/components/ListPickerModal';
import useUserOrg from '../../Authentication/components/hooks/useUserOrg';
import { useDeviceGroup } from './hooks/useDeviceGroup';
import useDeviceGroupAssignment from './hooks/useDeviceGroupAssignment';

interface GalleryDeviceAssignmentsProps {
    galleryId: number;
}

export default function GalleryDeviceGroupAssignments({
    galleryId,
}: GalleryDeviceAssignmentsProps) {
    const { orgId } = useUserOrg();
    const [isLoading, setIsLoading] = useState(true);
    const { getDeviceGroups } = useDeviceGroup(orgId);
    const { listDeviceAssignmentsForGallery, addDeviceAssignmentsForGallery } =
        useDeviceGroupAssignment();
    const [devices, setDevices] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [showPicker, setShowPicker] = useState(false);

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const assignments = await listDeviceAssignmentsForGallery(
                    galleryId,
                );

                const devices = await getDeviceGroups();

                setAssignments(assignments);
                setDevices(devices);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveAssignments = useCallback(
        async (newAssignments: number[]) => {
            try {
                await addDeviceAssignmentsForGallery(galleryId, newAssignments);
                const response = await listDeviceAssignmentsForGallery(
                    galleryId,
                );

                setAssignments(response);
            } catch (error) {
                console.error(error);
            }
            setShowPicker(false);
        },
        [
            addDeviceAssignmentsForGallery,
            galleryId,
            listDeviceAssignmentsForGallery,
        ],
    );

    if (isLoading) {
        return <div></div>;
    }
    return (
        <>
            <span className="center" onClick={() => setShowPicker(true)}>
                {assignments.length === 0
                    ? 'none'
                    : assignments.map(({ name }) => `${name} `)}
                {' |'}
                <i className="bi bi-card-checklist"></i>
            </span>
            {showPicker && (
                <ListPickerModal
                    list={devices}
                    selectedIds={assignments.map(({ groupId: id }) => id)}
                    onClose={() => setShowPicker(false)}
                    onSave={saveAssignments}
                />
            )}
        </>
    );
}
