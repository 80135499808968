import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Table, { createColumnHelper } from '../../Common/components/Table';
import useTemplates from './hooks/useTemplates';
import { Button } from '@/src/shadComponents/ui/button';

interface TemplateObject {
    id: number;
    name: string;
    body: string;
    assetUri: string;
    createdBy: string;
}

const columnHelper = createColumnHelper<GalleryObject>();

export default function Templates() {
    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState<TemplateObject[]>([]);
    const { getTemplates } = useTemplates();

    const columnDefinitions = [
        columnHelper.accessor('name', {
            id: 'name',
            header: () => <span>name</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
    ];

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const templates = await getTemplates();
                setTemplates(templates);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <div>loading</div>;
    }

    return (
        <div>
            <h1>templates</h1>
            <NavLink to="import">
                <Button className="Primary-button"> + import template</Button>
            </NavLink>
            <br />
            <br />
            <Table data={templates} columns={columnDefinitions} />
        </div>
    );
}
