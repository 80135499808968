import { useEffect, useState } from 'react';
import useCrowdGallery, { CrowdGallerySettings } from './hooks/useCrowdGallery';
import LoadingIndicator from '../../Common/components/LoadingIndicator';
import { Link } from 'react-router-dom';

interface CrowdGalleryProps {
    galleryId: number;
}

export default function CrowdGallery({ galleryId }: CrowdGalleryProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [settings, setSettings] = useState<CrowdGallerySettings | null>(null);

    const { getCrowdGallery } = useCrowdGallery();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const settings = await getCrowdGallery(galleryId);
                if (isMounted) {
                    setSettings(settings);
                }
            } catch (error) {
                console.error(error);
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (!settings) {
        return null;
    }

    return (
        <span>
            <Link to="crowdGallery">
                [ edit crowd gallery (
                {`${settings.enabled ? 'enabled' : 'disabled'}`}) ]
            </Link>
        </span>
    );
}
