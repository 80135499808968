import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../Common/components/Loading';
import FindMyPhotosModal from './FindMyPhotosModal';
import GalleryThumbnail from './GalleryThumbnail';
import useGalleryDirectory from './hooks/useGalleryDirectory';

import logo from './white_logo.png';
import YearSelector from './YearSelector';

export default function GalleryDirectory() {
    const {
        isLoading,
        galleries,
        setFilter,
        filter,
        getNewPage,
        loadedGalleries,
    } = useGalleryDirectory();
    const [showFindMyPhotosModal, setShowFindMyPhotosModal] =
        useState<boolean>(false);

    return (
        <>
            <Loading isLoading={isLoading} />
            <div>
                <div className="Gallery-header">
                    <div className="Gallery-header-title">
                        <img src={logo} alt="Smilebooth Logo" />
                    </div>
                    <div className="Gallery-filter">
                        <YearSelector
                            currentYear={filter}
                            onChange={setFilter}
                        />
                    </div>
                </div>
                <div
                    className="Find-your-own-photos-banner clickable"
                    onClick={() => setShowFindMyPhotosModal(true)}
                >
                    <h3 className="looking-for-prompt">
                        Looking for your own photos?
                    </h3>
                </div>
                <div className="Gallery-previews">
                    <div className="Gallery-content pb-[20px]">
                        <div className="Gallery-preview-grid-container">
                            <div className="Gallery-grid">
                                <InfiniteScroll
                                    dataLength={loadedGalleries.length}
                                    next={() => getNewPage(10)}
                                    style={{ display: 'grid' }}
                                    hasMore={
                                        galleries.length >
                                        loadedGalleries.length
                                    }
                                    scrollThreshold="200px"
                                    loader={<Loading isLoading={true} />}
                                >
                                    {galleries.map((gallery) =>
                                        gallery.previewImageUrls.length > 0 ? (
                                            <GalleryThumbnail
                                                key={gallery.name}
                                                title={gallery.name}
                                                urls={gallery.previewImageUrls}
                                                slug={gallery.slug}
                                                date={gallery.date}
                                            />
                                        ) : null,
                                    )}
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showFindMyPhotosModal ? (
                <FindMyPhotosModal
                    setShowModal={(show) => setShowFindMyPhotosModal(show)}
                />
            ) : null}
        </>
    );
}
