import { NavLink, useParams } from 'react-router-dom';
import useGallery from './hooks/useGallery';
import useQueryString from '../../Common/components/hooks/useQueryString';
import { useEffect, useState } from 'react';
import Loading from '../../Common/components/Loading';
import GalleryImage from './GalleryImage';
import './Gallery.css';
import logo from '../../smile_icon.png';
import DownloadButton from '../../Share/components/DownloadButton';
import GlobalStyle from '@/src/Share/components/GalleryTheme';

export default function GalleryPhoto() {
    const [isLoading, setIsLoading] = useState(true);
    const [galleryInfo, setGalleryInfo] = useState<GalleryObject | null>(null);
    const query = useQueryString();
    const { galleryId } = useParams();
    const [media, setMedia] = useState<MediaInstance | null>(null);

    const shareIdentifier = query.get('si');

    const { getPhotoByShareIdentifier, getGalleryById } = useGallery({
        galleryId: galleryId ? +galleryId : 0,
        slug: '',
    });

    useEffect(() => {
        let isMounted = true;

        const getPhoto = async () => {
            if (!shareIdentifier) {
                return;
            }

            try {
                const mediaResponse = await getPhotoByShareIdentifier(
                    shareIdentifier,
                );

                console.log('Media Response: ', mediaResponse);

                const galleryResponse = await getGalleryById();

                isMounted && setMedia(mediaResponse);
                isMounted && setGalleryInfo(galleryResponse);
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getPhoto();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasCTA = galleryInfo?.ctaImage || galleryInfo?.ctaText;
    const showGalleryLink = galleryInfo?.showGalleryLink;

    return (
        <div>
            <GlobalStyle
                foregroundColor={galleryInfo?.foregroundColor}
                backgroundColor={galleryInfo?.backgroundColor}
                headerBackgroundColor={galleryInfo?.headerBackgroundColor}
                headerForegroundColor={galleryInfo?.headerForegroundColor}
            />
            <Loading isLoading={isLoading} />
            {!isLoading && (
                <div className={`Gallery-header`}>
                    <div className="Gallery-header-title">
                        <img
                            src={galleryInfo?.logo || logo}
                            alt="Smilebooth Logo"
                        />
                    </div>
                    <div className="Gallery-name">{galleryInfo?.name}</div>
                </div>
            )}
            <div className="Gallery-photo-container">
                {media ? (
                    <>
                        <div className="spacer" />
                        <div className="spacer" />
                        <div className="media">
                            <GalleryImage
                                media={media}
                                galleryId={galleryId ? +galleryId : 0}
                            />
                        </div>
                        <div className="spacer" />
                        <div className="spacer" />
                    </>
                ) : null}
            </div>
            <br />
            <br />
            <div className="Gallery-navigation">
                {media ? (
                    <div className="Photo-buttons">
                        <DownloadButton
                            uri={media?.originalUri}
                            mimeType={media?.mimeType}
                            mediaId={media?.id}
                            source="gallery"
                        />
                    </div>
                ) : null}
                <div className="spacer" />
                {showGalleryLink && (
                    <div className=" w-[100vw] mt-8 flex row center">
                        <NavLink
                            to={`/gallery/${galleryId}`}
                            className="cta-button"
                        >
                            Back To Gallery
                        </NavLink>
                    </div>
                )}
                {hasCTA ? (
                    <div
                        className="Gallery-cta"
                        onClick={() => {
                            if (galleryInfo?.ctaLink) {
                                window.open(galleryInfo?.ctaLink, '_blank');
                            }
                        }}
                    >
                        {galleryInfo?.ctaImage && (
                            <img
                                src={galleryInfo?.ctaImage}
                                alt="Smilebooth CTA"
                            />
                        )}
                        {galleryInfo?.ctaText && <p>{galleryInfo?.ctaText}</p>}
                    </div>
                ) : null}

                <div className="spacer" />
            </div>
        </div>
    );
}
