import { Form, Formik } from 'formik';
import FieldFactory from './FieldFactory';

const document = {
    name: 'Logistics Form',
    job: {
        id: 1,
        name: 'Some Job',
    },
    templateId: 1,
    createdAt: new Date(),
    submitted: false,
    submittedAt: null,
    submittedBy: null,
    jobId: 1,
    sections: [
        {
            name: 'Basic Details',
            description: 'Basic details about the job',
            fields: [
                {
                    id: '1',
                    name: 'Event Name',
                    type: 'text',
                    placeholder: 'Event Name',
                    helperText:
                        'The name of the event, this will be used in all public facing areas such as gallery, emails, and text messages.',
                },
                {
                    id: '2',
                    name: 'Description',
                    type: 'textarea',
                    placeholder: '',
                    helperText: '',
                    /*conditional: {
                        type: 'fieldComparison',
                        field: 'Event Name',
                        value: 'Some Event',
                        operator: 'eq',
                    },*/
                },
            ],
        },
    ],
};

export default function DocumentForm() {
    return (
        <Formik initialValues={{}} onSubmit={() => {}}>
            {(props) => (
                <div className="">
                    <header className="relative isolate pt-0">
                        <div
                            className="absolute inset-0 -z-10 overflow-hidden"
                            aria-hidden="true"
                        >
                            <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                            <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                        </div>

                        <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                            <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                                <div className="flex items-center gap-x-6">
                                    <h1>
                                        <div className="text-sm leading-6 text-gray-500">
                                            <span className="text-gray-700">
                                                {document.job.name}
                                            </span>
                                        </div>
                                        <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                            {document.name}
                                        </div>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </header>
                    <Form
                        className="mt-6"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        {document.sections.map((section) => {
                            return (
                                <div
                                    className="space-y-12"
                                    key={`section-${section.name}`}
                                >
                                    <div className="grid grid-cols-1 gap-x-8 gap-y-10  pb-12 md:grid-cols-3">
                                        <div className="px-8">
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                                {section.name}
                                            </h2>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                {section.description}
                                            </p>
                                        </div>

                                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 p-4 sm:p-0">
                                            {section.fields.map((field) => {
                                                return (
                                                    <div
                                                        className="col-span-full sm:col-span-4"
                                                        key={`field-${field.id}`}
                                                    >
                                                        <label
                                                            htmlFor={field.name}
                                                            className="block text-sm font-medium leading-6 text-gray-900"
                                                        >
                                                            {field.name}
                                                        </label>
                                                        <FieldFactory
                                                            field={field}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                            <div className="flex-cols flex-1">
                                <p className="text-sm font-regular text-gray-900 flex-col">
                                    Saved 30 seconds ago
                                </p>
                                <p className="text-[0.8rem] font-thin text-gray-900 inline-block">
                                    Changes are auto-saved
                                </p>
                            </div>
                            <div className="flex-1 hidden sm:visible"></div>
                            <button
                                type="button"
                                className="text-sm font-semibold leading-6 text-gray-900"
                                onClick={() => {}}
                            >
                                Save Draft
                            </button>
                            <button
                                type="submit"
                                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                            >
                                Complete
                            </button>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}
