import { createSlice } from '@reduxjs/toolkit';

export interface ProcessingFlowState {
    entities: Record<string, ProcessingFlow>;
    ids: string[];
}

const initialState: ProcessingFlowState = {
    entities: {},
    ids: [],
};

const processingFlowSlice = createSlice({
    name: 'flows',
    initialState,
    reducers: {
        addProcessingFlow(state, action) {
            const { id, flow } = action.payload;

            state.entities[id] = flow;

            if (!state.ids.includes(id)) {
                state.ids.push(id);
            }
        },
        removeProcessingFlow(state, action) {
            const { id } = action.payload;

            delete state.entities[id];
            state.ids = state.ids.filter(
                (processingFlowId) => processingFlowId !== id,
            );
        },
        resetProcessingFlows(state) {
            state.entities = {};
            state.ids = [];
        },
        updateProcessingFlow(state, action) {
            const { id, flow } = action.payload;

            state.entities[id] = flow;
        },
    },
});

export const {
    addProcessingFlow,
    removeProcessingFlow,
    resetProcessingFlows,
    updateProcessingFlow,
} = processingFlowSlice.actions;

export default processingFlowSlice.reducer;
