import React from 'react';
import '../../Common/components/Form.css';
import { Formik, Form, Field } from 'formik';
import useFeatureFlagManagement from './hooks/useFeatureFlagManagement';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Button } from '@/src/shadComponents/ui/button';

interface CreateFeatureFlagFormValues {
    key: string;
    enabled: boolean;
    enabledForDevelopment: boolean;
}

export default function CreateFeatureFlag() {
    const initialValues: CreateFeatureFlagFormValues = {
        key: '',
        enabled: false,
        enabledForDevelopment: true,
    };

    const navigate = useNavigate();
    const { createFeatureFlag } = useFeatureFlagManagement();

    return (
        <div>
            <div className="Row-container">
                <h1>create feature flag</h1>
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await createFeatureFlag(values);
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate('/admin/features');
                    }
                }}
            >
                <Form
                    className="Form"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                >
                    <div>
                        <label htmlFor="key">key</label>
                        <Field id="key" name="key" placeholder="" />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="enabled">enabled</label>
                        <div className="left-justify">
                            <Field
                                id="enabled"
                                name="enabled"
                                type="checkbox"
                            />
                        </div>
                    </div>
                    <br />
                    <div>
                        <label htmlFor="enabledForDevelopment">
                            development enabled
                        </label>
                        <div className="left-justify">
                            <Field
                                id="enabledForDevelopment"
                                name="enabledForDevelopment"
                                type="checkbox"
                            />
                        </div>
                    </div>
                    <br />
                    <div className="Layout-row">
                        <Button type="submit" className="Primary-button">
                            create feature flag
                        </Button>
                        <NavLink to="/admin/features">
                            <Button className="Secondary-button">cancel</Button>
                        </NavLink>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}
