import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function ExperienceDrawer({
    isOpen,
    onClose,
    title,
    children,
}: {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}) {
    return (
        <Dialog
            transition
            open={isOpen}
            onClose={onClose}
            className="relative"
            unmount={false}
        >
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 mt-[64px]">
                        <DialogPanel
                            transition
                            className="pointer-events-auto w-screen max-w-md transform ease-in-out data-[closed]:translate-x-full sm:duration-50"
                        >
                            <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                <div className="flex-1">
                                    {/* Header */}
                                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                                        <div className="flex items-start justify-between space-x-3">
                                            <div className="space-y-1">
                                                <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                                                    {title}
                                                </DialogTitle>
                                                <p className="text-sm text-gray-500"></p>
                                            </div>
                                            <div className="flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    onClick={() => onClose()}
                                                    className="relative text-gray-400 hover:text-gray-500"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">
                                                        Close panel
                                                    </span>
                                                    <XMarkIcon
                                                        aria-hidden="true"
                                                        className="h-6 w-6"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Divider container */}
                                    <div className="space-y-6 py-6 sm:space-y-0  sm:py-0">
                                        {children}
                                    </div>
                                </div>
                                <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <button
                                        type="submit"
                                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                    >
                                        Done
                                    </button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
