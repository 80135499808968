import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useDeviceKeys(orgId: number) {
    const authenticatedApi = useAuthenticatedApi();

    const getAssignableDevices = async () => {
        const response = await authenticatedApi.get(`${orgId}/device-keys`);
        return response.data;
    };

    const getDeviceKeys = async () => {
        const response = await authenticatedApi.get(
            //`${orgId}/device-keys/${groupId}`,
            `${orgId}/device-keys`,
        );
        return response.data;
    };

    const getDeviceKeysForGroup = async (groupId?: number) => {
        const response = await authenticatedApi.get(
            `${orgId}/device-keys/${groupId}`,
        );
        return response.data;
    };

    const addDeviceKey = async (values: any) => {
        const response = await authenticatedApi.post(
            `${orgId}/device-keys/create`,
            JSON.stringify(values),
        );

        return response.data;
    };

    const destroyDeviceKey = async (id: number) => {
        const response = await authenticatedApi.delete(
            `${orgId}/device-keys/${id}`,
        );
        return response.data;
    };

    const generateDeviceKey = async () => {
        const response = await authenticatedApi.get(
            `${orgId}/device-keys/generate`,
        );
        return response.data;
    };

    return {
        getDeviceKeys,
        addDeviceKey,
        destroyDeviceKey,
        generateDeviceKey,
        getAssignableDevices,
        getDeviceKeysForGroup,
    };
}
