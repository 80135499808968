import React from 'react';
import { Link } from 'react-router-dom';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';

interface AccountActionsProps {
    row: any;
    onDelete: () => void;
}

export default function AccountActions({ row, onDelete }: AccountActionsProps) {
    const accountId = row.original.id;
    const authenticatedApi = useAuthenticatedApi();

    const performDelete = () => {
        const deleteRequest = async () => {
            const response = await authenticatedApi.delete(
                `accounts/${accountId}`
            );

            if (response.status === 200) {
                onDelete();
            }
        };

        const shouldDelete = window.confirm(
            `Are you sure you would like to delete ${row.original.username}`
        );

        if (shouldDelete) {
            deleteRequest();
        }
    };
    return (
        <span className="Table-options">
            <Link to={`${accountId}`}>
                <i className="bi bi-pencil-fill"></i>
            </Link>
            <i onClick={performDelete} className="bi bi-trash3-fill"></i>
        </span>
    );
}
