import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';
import { Button } from '@/src/shadComponents/ui/button';

export default function UploadToActivation() {
    const { id } = useParams();
    const authenticatedApi = useAuthenticatedApi();
    const formik = useFormik({
        initialValues: {
            photo: '',
            shareIdentifier: `${id}_${uuidv4()}`,
        },
        onSubmit: async (values: { [key: string]: string }) => {
            const formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            authenticatedApi.post(`/media/upload/${id}`, formData);
        },
    });
    return (
        <div>
            <h1>uploader</h1>
            <form
                className="Form"
                onSubmit={formik.handleSubmit}
                encType="multpart/form-data"
            >
                <input
                    type="file"
                    name="photo"
                    accept="image/*"
                    onChange={(e) => {
                        formik.setFieldValue(
                            'photo',
                            e.currentTarget.files?.[0],
                        );
                    }}
                />
                <input type="hidden" name="shareIdentifier" />
                <br />
                <br />
                <Button type="submit" className="Primary-button">
                    upload
                </Button>
            </form>
        </div>
    );
}
