interface ShareConfirmationProps {
    destination: string;
    autoShare: boolean;
    reset: () => void;
}

export default function ShareConfirmation({
    destination,
    reset,
    autoShare = false,
}: ShareConfirmationProps) {
    return (
        <div className="Share-body">
            <h2 className="Share-title">yehaw!</h2>
            <br />
            <div className="Share-prompt">
                <p>
                    {!autoShare
                        ? `Your photo will be delivered to ${destination}`
                        : `Your photo was automatically shared to ${destination}`}
                    , to share elsewhere{' '}
                    <span className="text-button" onClick={reset}>
                        click here.
                    </span>
                </p>
            </div>
            <br />
        </div>
    );
}
