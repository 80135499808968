import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import useRerender from '../../Common/components/hooks/useRerender';
import Table, { createColumnHelper } from '../../Common/components/Table';
import FeatureFlagActions from './FeatureFlagActions';
import useFeatureFlagManagment from './hooks/useFeatureFlagManagement';
import RoleFeatureFlagAssociationActions from './RoleFeatureFlagAssociationActions';
import { Button } from '@/src/shadComponents/ui/button';

type FeatureFlag = {
    id: number;
    key: string;
    enabled: boolean;
    enabledForDevelopment: boolean;
};

/*interface FeatureFlagRoleAssociation {
    accountRoleId: number;
    featureFlagId: number;
    name: string;
    key: string;
}*/

interface FeatureFlagRoleAssociationMap {
    name: string;
    keys: string[];
}

const featureFlagColumnHelper = createColumnHelper<FeatureFlag>();
const associationColumnHelper =
    createColumnHelper<FeatureFlagRoleAssociationMap>();

export default function FeatureFlags() {
    const { render, renderCount } = useRerender();

    const onDelete = () => {
        render();
    };

    const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);
    const [featureFlagRoleAssociations, setFeatureFlagRoleAssociations] =
        useState<FeatureFlagRoleAssociationMap[]>([]);

    const {
        isLoading,
        fetchFeatureFlags,
        setIsLoading,
        getFeatureFlagsForRoles,
    } = useFeatureFlagManagment();

    const featureFlagColumnDefinitions = [
        featureFlagColumnHelper.accessor('key', {
            id: 'key',
            header: () => <span>key</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        featureFlagColumnHelper.accessor('enabled', {
            id: 'enabled',
            header: () => <span>enabled</span>,
            cell: (info) => <span>{info.getValue() ? 'yes' : 'no'}</span>,
        }),
        featureFlagColumnHelper.accessor('enabledForDevelopment', {
            id: 'enabledForDevelopment',
            header: () => <span>dev enabled</span>,
            cell: (info) => <span>{info.getValue() ? 'yes' : 'no'}</span>,
        }),
        featureFlagColumnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <FeatureFlagActions onDelete={onDelete} row={props.row} />
            ),
        }),
    ];

    const roleAssociationColumnDefinitions = [
        associationColumnHelper.accessor('name', {
            id: 'name',
            header: () => <span>role</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        associationColumnHelper.accessor('keys', {
            id: 'keys',
            header: () => <span>features</span>,
            cell: (info) => <span>{info.getValue().length} feature flags</span>,
        }),
        associationColumnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <RoleFeatureFlagAssociationActions row={props.row} />
            ),
        }),
    ];

    useEffect(() => {
        let isMounted = true;
        const getAccounts = async () => {
            try {
                const flags = await fetchFeatureFlags();
                const associations = await getFeatureFlagsForRoles();

                setFeatureFlagRoleAssociations(associations);
                setFeatureFlags(flags);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getAccounts();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCount]);

    if (isLoading) {
        return <div>Loading</div>;
    }

    return (
        <div>
            <h1>feature flags</h1>
            <p>
                Feature flags are used to manage which features are seen by
                users. Tread carefully!
            </p>
            <NavLink to="create">
                <Button className="Primary-button"> + create flag</Button>
            </NavLink>
            <br />
            <br />
            <Table data={featureFlags} columns={featureFlagColumnDefinitions} />
            <br />
            <h2>role associations</h2>
            <br />
            <Table
                data={featureFlagRoleAssociations}
                columns={roleAssociationColumnDefinitions}
            />
        </div>
    );
}
