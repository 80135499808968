import { Formik, Form, Field } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingIndicator from '../../Common/components/LoadingIndicator';
import { Button } from '@/src/shadComponents/ui/button';
import useFTPSettings, { FTPGallerySettings } from './hooks/useFTPSettings';

export default function EditFTPSettings() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [settings, setSettings] = useState<FTPGallerySettings | null>(null);
    const { getFTPSettings, saveFTPSettingsWithPassword, listFTPDirectories } =
        useFTPSettings();
    const { galleryId: id } = useParams();
    const galleryId = id ? +id : 0;

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const settings: FTPGallerySettings = await getFTPSettings(
                    galleryId,
                );

                isMounted && setSettings(settings);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading || !settings) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            <div className="Row-container">
                <h1>FTP Sync Settings</h1>
            </div>
            <Formik
                initialValues={settings}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await saveFTPSettingsWithPassword(
                        id ? +id : 0,
                        values,
                    );
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate(-1);
                    }
                }}
            >
                {(props) => (
                    <Form
                        className="Form"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div>
                            <label htmlFor="ftpHost">Host</label>
                            <Field
                                id="ftpHost"
                                name="ftpHost"
                                placeholder="Host Address"
                            />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="ftpPort">Port</label>
                            <Field
                                id="ftpPort"
                                name="ftpPort"
                                placeholder="Port Number (21, 22, 990)"
                            />
                        </div>
                        <div>
                            <label htmlFor="ftpUser">Username</label>
                            <Field id="ftpUser" name="ftpUser" />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="ftpPassword">Password</label>
                            <Field id="ftpPassword" name="ftpPassword" />
                        </div>

                        <div>
                            <label htmlFor="ftpDirectory">Directory</label>
                            <Field id="ftpDirectory" name="ftpDirectory" />
                        </div>
                        <br />
                        <div className="Layout-row">
                            <Button type="submit" className="Primary-button">
                                save settings
                            </Button>
                            <Button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="Secondary-button"
                            >
                                <span>cancel</span>
                            </Button>
                            <Button
                                type="button"
                                onClick={() => listFTPDirectories(galleryId)}
                                className="Secondary-button"
                            >
                                <span>test</span>
                            </Button>
                        </div>

                        <br />
                    </Form>
                )}
            </Formik>
        </div>
    );
}
