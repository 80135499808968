import api from '../../../services/api';
import useAuth from './useAuth';

export default function useRefreshToken() {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await api.get('/refresh');

        setAuth((prev: any) => {
            return {
                ...prev,
                id: response.data.id,
                username: response.data.username,
                roles: response.data.roles,
                accessToken: response.data.accessToken,
                orgs: response.data.orgs,
            };
        });
        return response.data.accessToken;
    };
    return refresh;
}
