import { useState, useEffect } from 'react';
import api from '../../../../services/api';

export default function useDbOfSmiles() {
    const [smiles, setSmiles] = useState<any[]>([]);
    const [shares, setShares] = useState<any[]>([]);
    const [matches, setMatches] = useState<string[] | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const getSmiles = async () => {
            setLoading(true);

            try {
                const smileData = await api.get(
                    'https://photostat-api.smilebooth.com/public/activations/6898/photos',
                );

                const smilesArray = smileData.data;

                smilesArray.push(...smileData.data);
                smilesArray.push(...smileData.data);
                smilesArray.push(...smileData.data);
                setSmiles(smilesArray);
            } catch (error) {}

            try {
                const smileShares = await api.get(
                    'https://photostat-api.smilebooth.com/share/dbofsmiles',
                );

                setShares(smileShares.data);
            } catch (error) {}

            setLoading(false);
        };

        getSmiles();
    }, []);

    const findSmile = (input: string) => {
        if (!input) {
            setMatches(undefined);
            return;
        }
        const shareIdentifiers = shares
            .filter((share) => share.destination === input)
            .map((share) => share.shareIdentifier);

        setMatches(shareIdentifiers);
    };

    return {
        smiles,
        loading,
        findSmile,
        matches,
    };
}
