import GalleryShareContext from '@/src/Gallery/components/GalleryShareContext';
import { useContext, useEffect } from 'react';

const HIDE_TIMEOUT = 5000;

export default function ShareConfirmationAutoClose({ type }: any) {
    // @ts-ignore
    const { setHideShareModal } = useContext(GalleryShareContext);

    useEffect(() => {
        setTimeout(() => {
            if (setHideShareModal) {
                console.log('hiding share modal');
                setHideShareModal();
            }
        }, HIDE_TIMEOUT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Share-body">
            <h2 className="Share-title">yehaw!</h2>
            <br />
            <div className="Share-prompt">
                <p>
                    Keep your eyes peeled for{' '}
                    {type === 0 ? 'an email' : 'a text message'} with a link to
                    your share!
                </p>
            </div>
            <br />
        </div>
    );
}
