export default function useLocationQuery() {
    const getLocations = async (query: string) => {
        try {
            const response = await fetch(getURL(query));
            if (!response.ok) {
                throw new Error(response.statusText);
            }

            return await response.json();
        } catch (error) {
            return { error: 'Unable to retreive places' };
        }
    };

    return {
        getLocations,
    };
}
const publicMapToken = `pk.eyJ1Ijoic21pbGVkeWxhbiIsImEiOiJjbDZwcXBkbm4wNm80M2pqdnh1MDAzd285In0.UpkKWBwDVK_8_L7Eu7tKwg`;
const getURL = (text: string) =>
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${publicMapToken}&autocomplete=true&types=place`;
