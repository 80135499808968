import { PlusSmallIcon } from '@heroicons/react/20/solid';
import JobsStats from './JobsStats';
import JobsList, { JobsFilter } from './JobsList';
import PageWithTable from '@/src/Common/components/PageWithTable';
import { useState } from 'react';

const secondaryNavigation = [
    { name: 'All', key: 'all', href: '#', current: true },
    { name: 'Mine', key: 'mine', href: '#', current: false },
    { name: 'Next 30 Days', key: 'next30', href: '#', current: false },
    { name: 'Past', key: 'previous30', href: '#', current: false },
];

export default function JobsDashboard() {
    const [filter, setFilter] = useState<JobsFilter>('all');
    const actions = (
        <a
            href="jobs/create"
            className="ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
        >
            <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
            Create Job
        </a>
    );
    return (
        <PageWithTable
            title="Jobs"
            filters={secondaryNavigation.map((item) => (
                <a
                    key={item.name}
                    href={item.href}
                    className={
                        item.key === filter
                            ? 'text-black font-semibold border-b-2 border-black'
                            : 'text-gray-700'
                    }
                    onClick={(e) => {
                        e.preventDefault();
                        setFilter(item.key as JobsFilter);
                    }}
                >
                    {item.name}
                </a>
            ))}
            actions={actions}
        >
            <JobsStats />
            <JobsList filter={filter} />
        </PageWithTable>
    );
}
