import useOrgAccounts from './hooks/useOrgAccounts';
import { useParams } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '@/src/Common/components/Loading';

type Account = {
    username: string;
    email: string;
    id: number;
    firstName: string;
    lastName: string;
};

export default function OrgAccounts() {
    const navigate = useNavigate();
    const { orgId } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const { getAccounts } = useOrgAccounts();

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const response = await getAccounts(orgId ? +orgId : 0);
                setAccounts(response);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div className="">
            <tr className="text-sm leading-6 text-gray-900">
                <th
                    scope="colgroup"
                    colSpan={3}
                    className="relative isolate py-2 font-semibold"
                >
                    <div className="px-4 sm:px-8 w-max relative ">Account</div>
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('account/create');
                            }}
                            className="mr-2 my-[6px] px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-100 rounded-sm absolute right-0"
                        >
                            <Plus className="h-5 w-5 text-black" />
                        </button>
                    </div>
                </th>
            </tr>
            <div>
                <div className="overflow-hidden border-t border-gray-100">
                    <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <table className="w-full text-left">
                                <thead className="sr-only">
                                    <tr>
                                        <th>User Name</th>
                                        <th className="hidden sm:table-cell">
                                            Email
                                        </th>
                                        <th>More details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accounts.map((account) => (
                                        <tr key={account.id}>
                                            <td className="relative py-5 pr-6">
                                                <div className="flex gap-x-6">
                                                    <div className="flex-auto">
                                                        <div className="flex items-start gap-x-3">
                                                            <div className="text-sm font-medium leading-6 text-gray-900">
                                                                {
                                                                    account.firstName
                                                                }{' '}
                                                                {
                                                                    account.lastName
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="mt-1 text-xs leading-5 text-gray-500">
                                                            {account.email}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                            </td>
                                            <td className="py-5 text-right">
                                                <div className="flex justify-end">
                                                    {/*<a
                                                        href={`orgs/${account.id}`}
                                                        className="text-sm font-medium leading-6 text-black hover:text-indigo-500"
                                                    >
                                                        Edit
                                                        <span className="hidden sm:inline">
                                                            {' '}
                                                            Account &rarr;
                                                        </span>
                                                            </a>*/}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {accounts.length === 0 && (
                                        <>
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    className="text-center smaller-text text-gray-500 py-4"
                                                >
                                                    No accounts found
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    className="align-center flex justify-center row"
                                                >
                                                    <span>
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate(
                                                                    'account/create',
                                                                );
                                                            }}
                                                            className="flex row rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                                                        >
                                                            Create Account
                                                            <Plus className="h-5 w-5 text-white" />
                                                        </button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
