import { createSlice } from '@reduxjs/toolkit';

export interface ScreenState {
    entities: Record<string, ScreenDefinition>;
    ids: string[];
}

const initialState: ScreenState = {
    entities: {},
    ids: [],
};

const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {
        addScreen(state, action) {
            const screen = action.payload;
            const id = screen.id;

            state.entities[id] = screen;

            if (!state.ids.includes(id)) {
                state.ids.push(id);
            }
        },
        removeScreen(state, action) {
            const { id } = action.payload;

            delete state.entities[id];
            state.ids = state.ids.filter((screenId) => screenId !== id);
        },
        resetScreens(state) {
            state.entities = {};
            state.ids = [];
        },
        updateScreenId(state, action) {
            const { id, newId } = action.payload;

            state.entities[newId] = { ...state.entities[id], id: newId };
            delete state.entities[id];
            state.ids = state.ids.map((screenId) =>
                screenId === id ? newId : screenId,
            );
        },
        updateScreen(state, action) {
            const { id, screen } = action.payload;

            state.entities[id] = screen;
        },
        updateScreenStage(state, action) {
            const { id, stage } = action.payload;

            state.entities[id].stage = stage;
        },
        updateScreenMetadata(state, action) {
            const { id, metadata } = action.payload;

            state.entities[id].stage.metadata = metadata;
        },
    },
});

export const {
    addScreen,
    removeScreen,
    resetScreens,
    updateScreenMetadata,
    updateScreenStage,
    updateScreen,
    updateScreenId,
} = screenSlice.actions;

export default screenSlice.reducer;
