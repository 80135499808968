import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useActivation from './hooks/useActivation';
import PhotoManagerList from './PhotoManagerList';
import useShare from '../../Share/components/hooks/useShare';
import BulkUploadMedia from '../../Common/components/BulkUploadMedia';
import AddSessionShareModal from './AddSessionShareModal';
import useGetGalleries from '@/src/GalleryConfiguration/components/hooks/useGetGalleries';
import { UploadIcon } from 'lucide-react';
import { toast } from 'sonner';

export default function SessionManager() {
    const [showHidden, setShowHidden] = useState(false);
    const { activationId, photoSessionId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isSending, setIsSending] = useState<boolean | null>(null);
    const [isShowingBulkUpload, setIsShowingBulkUpload] = useState(false);
    const [isShowingAddShare, setIsShowingAddShare] = useState(false);
    const [photos, setPhotos] = useState<MediaInstance[]>([]);

    const { getActivation, getSessionPhotos } = useActivation();
    const { getGalleriesForActivation } = useGetGalleries();
    const { sendUnsentSharesForSession } = useShare();

    const [galleryId, setGalleryId] = useState<number | undefined>();

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const activation = await getActivation(
                    activationId ? +activationId : 0,
                );

                const photos = await getSessionPhotos(
                    activation.id,
                    photoSessionId ? photoSessionId : '',
                );

                const galleries = await getGalleriesForActivation(
                    activationId ? +activationId : 0,
                );

                setGalleryId(galleries[0]?.id);

                if (!showHidden) {
                    setPhotos(
                        photos.filter(
                            (photo: MediaInstance) => !photo.isHidden,
                        ),
                    );
                    return;
                }

                setPhotos(photos);
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showHidden]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    return (
        <div>
            <main className=" height-minus-header">
                <div className="relative isolate">
                    <header className="pb-4 pt-6 sm:pb-6">
                        <div className="mx-auto flex max-w-10xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                            <h1 className="text-base font-semibold leading-7 text-gray-900">
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        Manage
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    Session Photos
                                </div>
                            </h1>
                            <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7"></div>
                            <div className="spacer"></div>
                            <div className="Row-container-actions flex row gap-2">
                                <span
                                    className="cursor-pointer ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                                    onClick={() => {
                                        setIsSending(true);
                                        sendUnsentSharesForSession(
                                            photoSessionId ?? '',
                                        );
                                        toast.success(
                                            'Shares sent successfully',
                                        );
                                        setIsSending(false);
                                    }}
                                >
                                    {isSending
                                        ? 'Sending...'
                                        : 'Send to Clients'}
                                </span>

                                <span
                                    className="cursor-pointer ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                                    onClick={() => {
                                        setIsShowingAddShare(true);
                                    }}
                                >
                                    Add New Client
                                </span>
                            </div>
                            <span className="inline-title">
                                <UploadIcon
                                    onClick={() => setIsShowingBulkUpload(true)}
                                ></UploadIcon>
                            </span>
                        </div>
                    </header>

                    <div className="title-row pl-8">
                        <span className="inline-title">
                            photo count: {photos.length}
                        </span>

                        <span className="inline-title">
                            &nbsp;&nbsp; Include Hidden?
                            <input
                                type="checkbox"
                                onChange={() =>
                                    setShowHidden((hidden) => !hidden)
                                }
                                checked={showHidden}
                            />
                        </span>
                    </div>
                    <PhotoManagerList photos={photos} />

                    {isShowingBulkUpload ? (
                        <BulkUploadMedia
                            onCancel={() => setIsShowingBulkUpload(false)}
                            onComplete={() => {
                                setIsShowingBulkUpload(false);
                                alert('upload complete');
                            }}
                            activationId={activationId ? +activationId : 0}
                            photoSessionId={
                                photoSessionId ? photoSessionId : ''
                            }
                        />
                    ) : null}

                    {isShowingAddShare ? (
                        <AddSessionShareModal
                            photoSessionId={photoSessionId ?? ''}
                            activationId={activationId ? +activationId : 0}
                            galleryId={galleryId ? +galleryId : 0}
                            onComplete={() => setIsShowingAddShare(false)}
                        />
                    ) : null}
                </div>
            </main>
        </div>
    );
}
