import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';
import useGalleryViewLogger from '../../../Stats/components/hooks/useGalleryViewLogger';

export default function useGetGalleries() {
    const authenticatedApi = useAuthenticatedApi();
    const { getViews } = useGalleryViewLogger();
    const getGalleriesForActivation = async (activationId: number) => {
        const response = await authenticatedApi.get(
            `/galleries/activation/${activationId}`,
        );

        const { data } = response;

        for (const gallery of data) {
            const views = await getViews(gallery.id);

            gallery.totalViews = views.totalCount;
            gallery.uniqueViews = views.uniqueCount;
        }

        return data;
    };

    return {
        getGalleriesForActivation,
    };
}
