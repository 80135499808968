import useCreateAccount from '@/src/Accounts/components/hooks/useCreateAccount';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import useOrgs from './hooks/useOrgs';
import { useEffect, useState } from 'react';
import Loading from '@/src/Common/components/Loading';
import { toast } from 'sonner';

interface CreateAccountFormValues {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    orgId: number;
    role: number;
}

export default function OrgAccountCreate() {
    const { orgId } = useParams();
    const initialValues: CreateAccountFormValues = {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        orgId: orgId ? +orgId : 0,
        role: 1,
    };
    const navigate = useNavigate();
    const { createAccount } = useCreateAccount();

    const { getOrg } = useOrgs();
    const [org, setOrg] = useState<any>({});

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const response = await getOrg(orgId ? +orgId : 0);
                isMounted ?? setOrg(response);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!org) {
        return <Loading isLoading={true} />;
    }

    return (
        <div className="">
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        Create
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    Add an Account
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, actions) => {
                    const response = await createAccount(values);
                    actions.setSubmitting(false);

                    if (response?.data.id) {
                        toast.success('Account created successfully');
                        navigate(`/admin/orgs/${orgId}`);
                    } else {
                        toast.error(`${response.message}`);
                    }
                }}
            >
                {(props) => (
                    <Form
                        className="mt-6"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        autoComplete="off"
                    >
                        <input type="hidden" autoComplete="off" />
                        <div className="space-y-12">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10  pb-12 md:grid-cols-3">
                                <div className="px-8">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                        Add a user to {org.name}.
                                    </h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        They will be sent an invite to join
                                        after creation.
                                    </p>
                                </div>
                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 p-4 sm:p-0">
                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="username"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Username
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    id="username"
                                                    name="username"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    autoFill="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-full">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Email
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="yo@email.com"
                                                    autoComplete="off"
                                                    autoFill="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-full">
                                        <label
                                            htmlFor="firstName"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            First Name
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    id="firstName"
                                                    name="firstName"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    autoFill="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-full">
                                        <label
                                            htmlFor="firstName"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Last Name
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    id="lastName"
                                                    name="lastName"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    autoFill="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-full">
                                        <label
                                            htmlFor="password"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Password
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    id="password"
                                                    type="password"
                                                    name="password"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    autoFill="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-full">
                                        <label
                                            htmlFor="role"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Role
                                        </label>
                                        <Field
                                            id="role"
                                            as="select"
                                            name="role"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder=""
                                        >
                                            <option value={2}>Client</option>
                                            <option value={1}>User</option>
                                            <option value={0}>Admin</option>
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                            <button
                                type="button"
                                className="text-sm font-semibold leading-6 text-gray-900"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </button>

                            <button
                                type="submit"
                                disabled={props.isSubmitting}
                                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                            >
                                Create
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
