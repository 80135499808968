import React, { useEffect } from 'react';

export default function MetaPixel({
    pixelId = '806181844880151',
}: {
    pixelId: string;
}) {
    useEffect(() => {
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('init', pixelId);
            // @ts-ignore
            window.fbq('track', 'PageView');
        } else {
            console.error('window.fbq is not defined');
        }
    }, [pixelId]);

    return <></>;
}
