import { useCallback } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';
import api from '../../../services/api';

export default function usePhotoViewLogger() {
    const authenticatedApi = useAuthenticatedApi();
    const logPhotoView = useCallback(
        async (activationId: number, mediaId: number) => {
            if (!activationId || !mediaId) {
                return;
            }

            const { data } = await api.post('/stat/log-stat', {
                metadata: {
                    type: 'media-view',
                    mediaId,
                    activationId,
                },
            });

            return data;
        },
        [],
    );

    const getTotalViews = useCallback(
        async (activationId: number | null) => {
            const { data } = await authenticatedApi.get(
                `/reports/stats/media-view?${
                    activationId ? `activationId=${activationId}` : ''
                }`,
            );

            return data;
        },
        [authenticatedApi],
    );

    return {
        getTotalViews,
        logPhotoView,
    };
}
