import { createGlobalStyle } from 'styled-components';

export interface DefaultThemeProps {
    foregroundColor?: string;
    backgroundColor?: string;
    headerBackgroundColor?: string;
    headerForegroundColor?: string;
}

export const DEFAULT_THEME = {
    foregroundColor: '#000000',
    backgroundColor: '#FFFFFF',
    headerBackgroundColor: '#000000',
    headerForegroundColor: '#FFFFFF',
};

export default createGlobalStyle`
body {
    color: ${({ foregroundColor }: DefaultThemeProps) =>
        foregroundColor || DEFAULT_THEME.foregroundColor};
    background-color: ${({ backgroundColor }: DefaultThemeProps) =>
        backgroundColor || DEFAULT_THEME.backgroundColor};
}

.Fullscreen-modal,
.Share-container {
    color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
    background-color: ${({ headerBackgroundColor }: DefaultThemeProps) =>
        headerBackgroundColor || DEFAULT_THEME.headerBackgroundColor};

}

.Share-form input {
    color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
    background-color: ${({ headerBackgroundColor }: DefaultThemeProps) =>
        headerBackgroundColor || DEFAULT_THEME.headerBackgroundColor};
    border-color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
    border-style:solid;
    font-family: 'Roboto', sans-serif;
}

.Share-form .Link-button {
    background-color: ${({ headerBackgroundColor }: DefaultThemeProps) =>
        headerBackgroundColor || DEFAULT_THEME.headerBackgroundColor};
    color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
}

.SB-Modal {
    color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
    background-color: ${({ headerBackgroundColor }: DefaultThemeProps) =>
        headerBackgroundColor || DEFAULT_THEME.headerBackgroundColor};
}

.Gallery-cell.expanded {
color: ${({ foregroundColor }: DefaultThemeProps) =>
    foregroundColor || DEFAULT_THEME.foregroundColor};
    background-color: ${({ backgroundColor }: DefaultThemeProps) =>
        backgroundColor || DEFAULT_THEME.backgroundColor};
}

.Gallery-cell.expanded > .options {
color: ${({ foregroundColor }: DefaultThemeProps) =>
    foregroundColor || DEFAULT_THEME.foregroundColor};
    background-color: ${({ backgroundColor }: DefaultThemeProps) =>
        backgroundColor || DEFAULT_THEME.backgroundColor};;
}

.cta-button {
    color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
    background-color: ${({ headerBackgroundColor }: DefaultThemeProps) =>
        headerBackgroundColor || DEFAULT_THEME.headerBackgroundColor};
    border-color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
    border-style:solid;
    }

.Gallery-header {
    background-color: ${({ headerBackgroundColor }: DefaultThemeProps) =>
        headerBackgroundColor || DEFAULT_THEME.headerBackgroundColor};
    color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
}

.Gallery-name {
    color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
}

.Loading-wrapper:first-child {
    background-color: ${({ backgroundColor }: DefaultThemeProps) =>
        backgroundColor || DEFAULT_THEME.backgroundColor};
    color: ${({ foregroundColor }: DefaultThemeProps) =>
        foregroundColor || DEFAULT_THEME.foregroundColor};  
}


.cta-container {
    background-color: ${({ backgroundColor }: DefaultThemeProps) =>
        backgroundColor || DEFAULT_THEME.foregroundColor};
    }

.Find-your-own-photos-banner {
    background-color: ${({ headerBackgroundColor }: DefaultThemeProps) =>
        headerBackgroundColor || DEFAULT_THEME.headerBackgroundColor};
    color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
}

.Media-container {
    background-color: ${({ backgroundColor }: DefaultThemeProps) =>
        backgroundColor || DEFAULT_THEME.backgroundColor}; 
}

.video-js {
    background-color: ${({ backgroundColor }: DefaultThemeProps) =>
        backgroundColor || DEFAULT_THEME.backgroundColor} !important; 
}

.qr-code > path:first-child {
    stroke: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
}

.qr-code {
    border-color: ${({ headerForegroundColor }: DefaultThemeProps) =>
        headerForegroundColor || DEFAULT_THEME.headerForegroundColor};
    border-style:solid;
    border-width: 6px;

}


`;
