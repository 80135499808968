import { useState, useEffect } from 'react';

function getStorageValue(key: string, defaultValue: any) {
    // getting stored value
    const saved = localStorage.getItem(key);

    try {
        // @ts-ignore
        const initial = JSON.parse(saved);
        return initial ?? defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export const useLocalStorage = <T>(key: string, defaultValue: T) => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};
