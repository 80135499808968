import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export default function usePicTime() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const getPicTimeToken = async (accessToken: string) => {
        const response = await authenticatedApi.post(
            `${orgId}/integrations/pic-time/authToken`,
            JSON.stringify({
                accessToken,
            }),
        );
        return response.data;
    };

    const isLinkedToPictime = async () => {
        const response = await authenticatedApi.get(
            `${orgId}/integrations/pic-time/link-status`,
        );
        return response.data?.isLinked;
    };

    const createPictimeFolder = async (name: string) => {
        const response = await authenticatedApi.post(
            `${orgId}/integrations/pic-time/gallery`,
            JSON.stringify({
                galleryId: 2,
            }),
        );
        return response.data;
    };

    const addPhotoToGallery = async () => {
        const response = await authenticatedApi.post(
            `${orgId}/integrations/pic-time/addPhotoToGallery`,
            JSON.stringify({
                galleryId: 2,
                photoUrl:
                    //'https://photostat-archive-prod.s3.amazonaws.com/c9513b0454-2.jpg',
                    'https://photostat-archive-prod.s3.amazonaws.com/btaoft0025.jpg',
            }),
        );
        return response.data;
    };

    return {
        isLinked: false,
        isLinkedToPictime: isLinkedToPictime,
        linkAccount: getPicTimeToken,
        unlinkAccount: () => {},
        createPictimeFolder,
        addPhotoToGallery,
        accountName: 'dylan@smilebooth.com',
    };
}
