import { useContext } from 'react';
import OrgContext from '../OrgContext';

export default function useUserOrg() {
    const { org, setOrg } = useContext(OrgContext);

    return {
        org,
        orgId: org?.id,
        setOrg,
    };
}
