import { Formik, Form, Field } from 'formik';
import useCreateShare from './hooks/useCreateShare';
import { useContext } from 'react';
import GalleryShareContext from '@/src/Gallery/components/GalleryShareContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import logo from '../../Gallery/components/white_logo.png';

interface CreateShareFormProps {
    galleryInfo: GalleryObject | null;
    onShare: (destination: string) => void;
    setIsSharing: (isSharing: boolean) => void;
    defaultDestination: string;
    type: ShareType;
    onReset: () => void;
    shareIdentifiers: string[];
}

function isValidEmail(email: string) {
    // eslint-disable-next-line no-useless-escape
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

function isValidPhoneNumber(phoneNumber: string) {
    var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return regex.test(phoneNumber);
}

export default function SharingStationForm({
    galleryInfo,
    onShare,
    setIsSharing,
    defaultDestination,
    type,
    onReset,
    shareIdentifiers,
}: CreateShareFormProps) {
    const { defaultShareValues, shareCallback } = useCreateShare(
        onShare,
        setIsSharing,
        defaultDestination,
        galleryInfo,
        type,
        undefined,
        shareIdentifiers,
    );

    // @ts-ignore
    const { setHideShareModal } = useContext(GalleryShareContext);

    const validate = (values: CreateShareFormValues) => {
        const errors: any = {};
        const { destination, type } = values;
        if (!destination) {
            errors.destination = 'required';
        } else if (type === 0) {
            if (!isValidEmail(destination)) {
                errors.destination = 'email address is invalid';
            }
        } else if (type === 1) {
            if (!isValidPhoneNumber(destination)) {
                errors.destination = 'phone number is invalid';
            }
        }

        if (galleryInfo?.disclaimerRequired && !values.disclaimerAccepted) {
            errors.disclaimerAccepted = '<- Please accept';
        }

        return errors;
    };

    return (
        <div className="Share-body">
            <LazyLoadImage
                src={galleryInfo?.logo || logo}
                alt="logo"
                loading="lazy"
                effect="opacity"
                style={{
                    objectFit: 'contain',
                    maxWidth: '300px',
                    marginBottom: '60px',
                }}
            />
            {galleryInfo?.createShareDescriptionText ? (
                <p
                    className={`mb-8`}
                    style={{
                        fontFamily: `${
                            galleryInfo.createShareDescriptionTextFont ||
                            'Roboto'
                        }`,
                        fontSize: `${galleryInfo.createShareDescriptionTextSize}px`,
                    }}
                >
                    {galleryInfo.createShareDescriptionText}
                </p>
            ) : null}
            <br />
            <Formik
                validate={validate}
                initialValues={defaultShareValues}
                onSubmit={async (values, actions) => {
                    await shareCallback(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form
                        className="Share-form"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        {errors.destination && touched.destination && (
                            <div className="Form-error">
                                {errors.destination}
                            </div>
                        )}
                        <Field
                            id="destination"
                            name="destination"
                            placeholder={
                                values.type === 0
                                    ? 'hello@smilebooth.com'
                                    : 'enter your phone number' //'(281) 330-8004'
                            }
                            className="destination-field"
                            type={values.type === 0 ? 'email' : 'tel'}
                        />
                        {galleryInfo?.disclaimerEnabled ? (
                            <div className="disclaimer flex row content-center mb-12">
                                <div className="w-[30px]">
                                    <input
                                        id="disclaimer"
                                        name="disclaimer"
                                        type="checkbox"
                                        aria-describedby="disclaimer"
                                        defaultChecked={
                                            values.disclaimerAccepted
                                        }
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={(event: any) => {
                                            setFieldValue(
                                                'disclaimerAccepted',
                                                event.target.checked,
                                            );
                                        }}
                                    />
                                </div>

                                <span className="pt-[-2]">
                                    I agree to the{' '}
                                    <a
                                        href={galleryInfo.disclaimer || ''}
                                        className="underline"
                                    >
                                        terms and conditions
                                    </a>
                                    {galleryInfo.disclaimerRequired
                                        ? '*'
                                        : null}
                                </span>
                                <span className="ml-4">
                                    {errors.disclaimerAccepted &&
                                    touched.disclaimerAccepted ? (
                                        <span className="Form-error">
                                            {errors.disclaimerAccepted}
                                        </span>
                                    ) : null}
                                </span>
                            </div>
                        ) : null}
                        <button type="submit" className="cta-button">
                            send share
                        </button>
                        <span
                            onClick={() =>
                                setHideShareModal && setHideShareModal()
                            }
                            className="cta-button no-border"
                        >
                            cancel
                        </span>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
