import { useCallback } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';
import api from '../../../services/api';

export default function usePhotoDownloadLogger() {
    const authenticatedApi = useAuthenticatedApi();
    const logPhotoDownload = useCallback(
        async (
            mediaId: number,
            source: 'gallery' | 'personal-gallery' | 'share',
        ) => {
            if (!source || !mediaId) {
                return;
            }

            const { data } = await api.post('/stat/log-stat', {
                metadata: {
                    type: 'media-download',
                    mediaId,
                    source,
                },
            });

            return data;
        },
        [],
    );

    const getTotalDownloads = useCallback(
        async (activationId: number | null) => {
            const { data } = await authenticatedApi.get(
                `/reports/stats/media-download?${
                    activationId ? `activationId=${activationId}` : ''
                }`,
            );

            return data;
        },
        [authenticatedApi],
    );

    return {
        logPhotoDownload,
        getTotalDownloads,
    };
}
