import React, { useEffect } from 'react';
import {
    useReactTable,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    SortingState,
    getSortedRowModel,
    ColumnFiltersState,
    FilterFn,
    getFilteredRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
import './Table.css';
import TableFilter from './TableFilter';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';

interface TableProps {
    columns: any[];
    data: any[];
    enableSorting?: boolean;
    enablePagination?: boolean;
}

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>;
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);

    addMeta({
        itemRank,
    });

    return itemRank.passed;
};

export default function Table({
    columns,
    data,
    enableSorting = false,
    enablePagination = false,
}: TableProps) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    const options = {
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            sorting,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getCoreRowModel: getCoreRowModel(),
    };

    if (enablePagination) {
        // @ts-ignore
        options.getPaginationRowModel = getPaginationRowModel();
    }

    // @ts-ignore
    const table = useReactTable(options);

    useEffect(() => {
        if (enablePagination && table) {
            // @ts-ignore
            table.setPageSize(25);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ul className="divide-y divide-gray-100 px-6">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    <th className="w-5"></th>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 capitalize"
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className:
                                                            header.column.getCanSort()
                                                                ? 'cursor-pointer select-none justify-start flex'
                                                                : '',
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                    {{
                                                        asc: ' \u25B2',
                                                        desc: ' \u25BC',
                                                    }[
                                                        header.column.getIsSorted() as string
                                                    ] ?? null}
                                                    {enableSorting &&
                                                    header.column.getCanFilter() ? (
                                                        <div>
                                                            <TableFilter
                                                                column={
                                                                    header.column
                                                                }
                                                                table={table}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr key={row.id}>
                                    <td className="w-1"></td>
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            key={cell.id}
                                            className="relative py-2 pr-2 text-sm font-medium leading-6 text-gray-900"
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {enablePagination ? (
                        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                            <div className="flex flex-1 justify-between sm:hidden">
                                <button
                                    onClick={() => table.previousPage()}
                                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => table.nextPage()}
                                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                >
                                    Next
                                </button>
                            </div>
                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                <nav
                                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                                    aria-label="Pagination"
                                >
                                    <button
                                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                        onClick={() => table.previousPage()}
                                    >
                                        <span className="sr-only">
                                            Previous
                                        </span>
                                        <ChevronLeftIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    {table.getPageOptions().map((page) => {
                                        if (
                                            page >
                                                table.getState().pagination
                                                    .pageIndex +
                                                    2 ||
                                            page <
                                                table.getState().pagination
                                                    .pageIndex -
                                                    2
                                        ) {
                                            return null;
                                        }
                                        const pageIndex = page;
                                        return (
                                            <button
                                                key={pageIndex}
                                                className={`${
                                                    pageIndex ===
                                                    table.getState().pagination
                                                        .pageIndex
                                                        ? 'bg-gray-200'
                                                        : 'bg-white'
                                                } relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                                                onClick={() =>
                                                    table.setPageIndex(
                                                        pageIndex,
                                                    )
                                                }
                                            >
                                                {pageIndex + 1}
                                            </button>
                                        );
                                    })}
                                    <button
                                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                        onClick={() => table.nextPage()}
                                    >
                                        <span className="sr-only">Next</span>
                                        <ChevronRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>

                                    <div className="spacer" />
                                    <div className="px-4 flex justify-center align-middle items-center">
                                        <div className="items-center row text-nowrap ">
                                            page&nbsp;
                                            {table.getState().pagination
                                                .pageIndex + 1}{' '}
                                            of {table.getPageCount()}
                                        </div>
                                    </div>
                                    <span className="flex items-center pr-4">
                                        | &nbsp;&nbsp;&nbsp;go to page: &nbsp;
                                        <input
                                            type="number"
                                            defaultValue={
                                                table.getState().pagination
                                                    .pageIndex + 1
                                            }
                                            onChange={(e) => {
                                                const page = e.target.value
                                                    ? Number(e.target.value) - 1
                                                    : 0;
                                                table.setPageIndex(page);
                                            }}
                                            className="border p-1 rounded w-16"
                                        />
                                    </span>
                                    <select
                                        value={
                                            table.getState().pagination.pageSize
                                        }
                                        onChange={(e) => {
                                            table.setPageSize(
                                                Number(e.target.value),
                                            );
                                        }}
                                    >
                                        {[25, 50, 100].map((pageSize) => (
                                            <option
                                                key={pageSize}
                                                value={pageSize}
                                            >
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </nav>
                            </div>
                        </div>
                    ) : null}
                    {data.length === 0 && <div>zero records... </div>}
                </div>
            </div>
        </ul>
    );
}

export { createColumnHelper };
