import { useEffect, useRef, useState } from 'react';

export default function useSlideshowBehaviors(
    batchSize: number,
    photos: any,
    rotationSpeed: number,
) {
    const currentBatch = useRef<MediaInstance[]>([]);
    const [previousBatch, setPreviousBatch] = useState<MediaInstance[]>([]);

    //const internalPhotos = signal<MediaInstance[]>([]);

    /*useEffect(() => {
        internalPhotos.value = photos;
    }, [photos.length]);*/

    useEffect(() => {
        if (photos.current.length > 0) {
            currentBatch.current = photos.current.slice(0, batchSize);
            setPreviousBatch([...currentBatch.current]);
        }

        const interval = setInterval(() => {
            setPreviousBatch([...currentBatch.current]);
            const randomizedArray = shuffle([...photos.current]);
            const nextBatch = randomizedArray.slice(0, batchSize);
            console.log('Setting next batch', nextBatch);
            currentBatch.current = nextBatch;
        }, rotationSpeed * 1000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchSize, rotationSpeed, photos, photos.current.length]);

    return {
        displayPhotos: currentBatch,
        previousPhotos: previousBatch,
    };
}

function shuffle(array: any[]) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }

    return array;
}
