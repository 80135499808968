import useQueryString from '@/src/Common/components/hooks/useQueryString';
import { useEffect } from 'react';
import usePicTime from './hooks/usePicTime';

export default function PicTimeRedirectHandler() {
    const queryString = useQueryString();
    const { linkAccount, createPictimeFolder, addPhotoToGallery } =
        usePicTime();

    useEffect(() => {
        const getAccessToken = async () => {
            const accessToken = queryString.get('accessToken');

            if (accessToken) {
                const responst = await linkAccount(accessToken);
                console.log('Response', responst);
            }
        };

        getAccessToken();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            PicTimeRedirectHandler
            <button onClick={() => createPictimeFolder('test')}>
                Create Folder
            </button>
            <button onClick={() => addPhotoToGallery()}>Add Photo</button>
        </div>
    );
}
