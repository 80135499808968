import { useState } from 'react';
import AuthContext from './AuthContext';
import api from '../../services/api';

const LOGIN_URL = '/auth';
const GOOGLE_LOGIN_URL = '/google';

const authenticationAPI = {
    isAuthenticated: false,
    async signin(username: string, password: string) {
        const authenticationResponse = await api.post(
            LOGIN_URL,
            JSON.stringify({
                username,
                password,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                },
            },
        );
        const accessToken = authenticationResponse?.data?.accessToken;
        const roles = authenticationResponse?.data?.roles;
        const orgs = authenticationResponse?.data?.orgs;
        const id = authenticationResponse?.data?.id;

        console.log('authenticationResponse', authenticationResponse);
        return { accessToken, roles, orgs, id };
    },
    async signinGoogle(jwt: string) {
        const authenticationResponse = await api.post(
            GOOGLE_LOGIN_URL,
            JSON.stringify({ credential: jwt }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                },
            },
        );
        const accessToken = authenticationResponse?.data?.accessToken;
        const username = authenticationResponse?.data?.username;
        const roles = authenticationResponse?.data?.roles;
        const orgs = authenticationResponse?.data?.orgs;
        const id = authenticationResponse?.data?.id;

        return { accessToken, roles, username, orgs, id };
    },
    async signout() {
        authenticationAPI.isAuthenticated = false;
        return authenticationAPI.isAuthenticated;
    },
};

export default function AuthProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [user, setUser] = useState<any>(null);
    const [persist, setPersist] = useState<boolean>(
        // @ts-ignore
        JSON.parse(localStorage.getItem('persist')) || false,
    );

    const signin = async (username: string, password: string) => {
        const authenticationResponse = await authenticationAPI.signin(
            username,
            password,
        );

        if (authenticationResponse.accessToken) {
            const { accessToken, roles, orgs, id } = authenticationResponse;
            setUser({
                username,
                password,
                accessToken,
                roles,
                orgs,
                id,
            });
        }

        return !!authenticationResponse.accessToken;
    };

    const signInGoogle = async (jwt: string) => {
        const authenticationResponse = await authenticationAPI.signinGoogle(
            jwt,
        );

        localStorage.setItem('persist', JSON.stringify(true));
        setPersist(true);

        if (authenticationResponse.accessToken) {
            const { accessToken, roles, orgs, id } = authenticationResponse;
            setUser({
                username: authenticationResponse.username,
                password: '',
                accessToken,
                roles,
                orgs,
                id,
            });
        }

        return !!authenticationResponse.accessToken;
    };

    const signout = async () => {
        await authenticationAPI.signout();
        setUser(null);
    };

    const authValue = {
        auth: user,
        setAuth: setUser,
        signin,
        signout,
        persist,
        setPersist,
        signInGoogle,
    };

    return (
        <AuthContext.Provider value={authValue}>
            {children}
        </AuthContext.Provider>
    );
}
