import { useEffect, useState } from 'react';
import { useLocalStorage } from '../../../Common/components/hooks/useLocalStorage';
import api from '../../../services/api';

interface GalleryDirectory {
    name: string;
    slug: string;
    previewImageUrls: PhotoInstance[];
    date: string;
}

const year = new Date().getFullYear();

export default function useGalleryDirectory() {
    const [isLoading, setIsLoading] = useState(true);
    const [galleries, setGalleries] = useState<GalleryDirectory[]>([]);
    const [galleryCache, setGalleryCache] = useLocalStorage<{
        [key: number]: GalleryDirectory[];
    }>('sb-gallery-cache', {});
    const [loadedGalleries, setLoadedGalleries] = useState<GalleryDirectory[]>(
        [],
    );
    const [filter, setFilter] = useLocalStorage('year-filter', year);

    const getNewPage = (count: number) => {
        if (
            galleries.length === loadedGalleries.length &&
            loadedGalleries.length !== 0
        ) {
            return;
        }

        setLoadedGalleries([
            ...loadedGalleries,
            ...galleries.slice(
                loadedGalleries.length - 1,
                loadedGalleries.length + count - 1,
            ),
        ]);
    };

    useEffect(() => {
        setIsLoading(true);
        let isMounted = true;

        if (galleryCache[filter]) {
            setGalleries(galleryCache[filter]);
            setIsLoading(false);
        }

        const getData = async () => {
            try {
                let results = await api.get(
                    `/public/galleries/previews/${filter}`,
                );
                isMounted && setGalleries(results.data);
                isMounted &&
                    setGalleryCache({
                        ...galleryCache,
                        [filter]: results.data,
                    });
            } catch (error) {
                console.log(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return {
        isLoading,
        galleries,
        filter,
        setFilter,
        getNewPage,
        loadedGalleries,
    };
}
