import { useState } from 'react';

export default function useRerender() {
    const [renderCount, setRenderCount] = useState(0);

    return {
        render: () => setRenderCount((value) => value + 1),
        renderCount,
    };
}
