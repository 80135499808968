import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import './Login.css';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from '@/src/shadComponents/ui/card';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/src/shadComponents/ui/form';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@/src/shadComponents/ui/input';
import { Separator } from '@/src/shadComponents/ui/separator';
import { Alert, AlertDescription } from '@/src/shadComponents/ui/alert';
import { AlertCircle } from 'lucide-react';
import { Switch } from '@/src/shadComponents/ui/switch';

const formSchema = z.object({
    username: z.string().min(2).max(50),
    password: z.string().min(2).max(50),
    persist: z.boolean().default(true),
});

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const [feedback, setFeedback] = useState<string | null>(null);
    const { signin, setPersist, persist, signInGoogle } = useAuth();

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: '',
        },
    });

    // @ts-ignore
    let from = location.state?.from?.pathname || '/';

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const username = formData.get('username') as string;
        const password = formData.get('password') as string;

        try {
            const authenticated = await signin(username, password);

            if (authenticated) {
                navigate(from, { replace: true });
            } else {
                setFeedback('Log in failed, try again.');
            }
        } catch (e) {
            setFeedback('Log in failed, try again.');
        }
    }

    async function handleGoogleResponse(credential: string) {
        const authenticated = await signInGoogle(credential);
        if (authenticated) {
            navigate(from, { replace: true });
        } else {
            setFeedback('Google account does not exist in Photostat.');
        }
    }

    const togglePersist = () => {
        setPersist((prev: boolean) => !prev);
    };

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        setTimeout(() => {
            // @ts-ignore
            google.accounts.id.initialize({
                client_id:
                    '475304023009-uaqeorfcb9d09jill84neov7bde5ve3m.apps.googleusercontent.com',
                // @ts-ignore
                callback: (jwt: string) => handleGoogleResponse(jwt.credential),
            });

            // @ts-ignore
            google.accounts.id.renderButton(
                document.getElementById('buttonDiv'),
                { theme: 'outline', size: '100' }, // customization attributes
            );

            // @ts-ignore
            google.accounts.id.prompt();
        }, 1000);
        return () => {
            document.body.removeChild(script);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // @ts-ignore
        localStorage.setItem('persist', persist);
    }, [persist]);

    return (
        <>
            <div className="h-screen flex items-center justify-center">
                <Card className="w-[90%] md:min-w-2/6 md:w-2/6 rounded-none shadow-none border-color-black">
                    <CardHeader>
                        <CardTitle>
                            <h1>Welcome to Photostat.</h1>
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="mt-0">
                        <div className="Login-form relative">
                            <Form {...form}>
                                <form
                                    onSubmit={handleSubmit}
                                    className="w-full"
                                >
                                    {feedback && (
                                        <Alert variant="destructive">
                                            <div className="flex items-center">
                                                <AlertCircle className="h-4 w-4" />
                                                <AlertDescription className="ml-2">
                                                    {feedback}
                                                </AlertDescription>
                                            </div>
                                        </Alert>
                                    )}
                                    <FormField
                                        control={form.control}
                                        name="username"
                                        render={({ field }) => (
                                            <FormItem className="my-3">
                                                <FormLabel>Username</FormLabel>
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        className="shadow-none"
                                                    />
                                                </FormControl>

                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="password"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Password</FormLabel>
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        className="shadow-none"
                                                    />
                                                </FormControl>

                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormItem className="my-5">
                                        <div className="flex items-center">
                                            <FormControl>
                                                <Switch
                                                    checked={persist}
                                                    onCheckedChange={
                                                        togglePersist
                                                    }
                                                />
                                            </FormControl>

                                            <FormLabel className="px-3">
                                                Trust this device for 30 days
                                            </FormLabel>
                                        </div>
                                    </FormItem>
                                    <button
                                        type="submit"
                                        className="w-full bg-black text-white py-2 rounded-md shadow-md hover:bg-gray-900 text-center"
                                    >
                                        Log In
                                    </button>
                                </form>
                            </Form>
                        </div>
                        <Separator className="my-4" />
                        <div
                            id="buttonDiv"
                            className="flex justify-center"
                        ></div>
                    </CardContent>
                </Card>
            </div>
        </>
    );
}
