import useAuthenticatedApi, {
    HTTP_STATUS_CODES,
} from '../../../Authentication/components/hooks/useAuthenticatedApi';

interface FTPSettingsSavable {
    galleryId: number;
    ftpHost: string;
    ftpPort: string;
    ftpPassword: string;
    ftpUser: string;
    ftpDirectory?: string;
}

export type FTPGallerySettings = FTPSettingsSavable;

export default function useFTPSettings() {
    const authenticatedApi = useAuthenticatedApi();
    const saveFTPSettingsWithPassword = async (
        galleryId: number,
        ftpGalleryObject: FTPSettingsSavable,
    ) => {
        const response = await authenticatedApi.post(
            `/galleries/sync/${galleryId}/ftp`,
            JSON.stringify(ftpGalleryObject),
        );

        return response.status === HTTP_STATUS_CODES.OK;
    };

    const saveFTPSettings = async (
        galleryId: number,
        ftpGalleryObject: FTPSettingsSavable,
    ) => {
        const response = await authenticatedApi.put(
            `/galleries/sync/${galleryId}/ftp`,
            JSON.stringify(ftpGalleryObject),
        );

        return response.status === HTTP_STATUS_CODES.OK;
    };

    const listFTPDirectories = async (galleryId: number) => {
        const response = await authenticatedApi.get(
            `/galleries/sync/${galleryId}/ftp/directories`,
        );

        return response.data;
    };

    const getFTPSettings = async (
        galleryId: number,
    ): Promise<FTPGallerySettings> => {
        const response = await authenticatedApi.get(
            `/galleries/sync/${galleryId}/ftp`,
        );

        const { data } = response;

        return (
            data || {
                galleryId,
                ftpHost: '',
                ftpPassword: '',
                ftpPort: '',
                ftpUser: '',
                ftpDirectory: '',
            }
        );
    };

    return {
        saveFTPSettings,
        getFTPSettings,
        saveFTPSettingsWithPassword,
        listFTPDirectories,
    };
}
