import { useEffect, useRef, useState } from 'react';
import useFeatureFlagManagment from './hooks/useFeatureFlagManagement';

interface EditRoleFeatureFlagAssociationInputProps {
    checked: boolean;
    roleId: number;
    keyId: number;
}

export default function RoleFeatureFlagAssociationInput({
    checked,
    roleId,
    keyId,
}: EditRoleFeatureFlagAssociationInputProps) {
    const [isToggled, setIsToggled] = useState(checked);
    const hasRendered = useRef(false);

    const { addFeatureFlagToRole, removeFeatureFlagFromRole } =
        useFeatureFlagManagment();

    useEffect(() => {
        if (!hasRendered.current) {
            hasRendered.current = true;
            return;
        }

        if (isToggled) {
            addFeatureFlagToRole(roleId, keyId);
            return;
        }

        removeFeatureFlagFromRole(roleId, keyId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isToggled]);

    return (
        <input
            type="checkbox"
            checked={isToggled}
            onChange={() => setIsToggled(!isToggled)}
        />
    );
}
