import { useState, useEffect } from 'react';
import useGalleryBehaviors from './useGalleryBehvaiors';
import usePersonalGallery from './usePersonalGallery';

export default function usePersonalGalleryInteractions(slug: string) {
    const [isLoading, setIsLoading] = useState(true);
    const [delayed, setDelayed] = useState(false);
    const [gallery, setGallery] = useState<MediaInstance[]>([]);
    const [loadedPhotos, setLoadedPhotos] = useState<MediaInstance[]>([]);
    const [expandedIndex, setExpandedIndex] = useState<number>(-1);

    const { getGallery } = usePersonalGallery();

    const getNewPage = (count: number) => {
        if (
            gallery.length === loadedPhotos.length &&
            loadedPhotos.length !== 0
        ) {
            return;
        }

        setLoadedPhotos([
            ...loadedPhotos,
            ...gallery.slice(
                loadedPhotos.length - 1,
                loadedPhotos.length + count - 1,
            ),
        ]);
    };

    const advance = () => {
        if (expandedIndex === loadedPhotos.length - 1) {
            getNewPage(10);
        }

        expandedIndex < gallery.length && setExpandedIndex(expandedIndex + 1);
    };

    const reverse = () =>
        setExpandedIndex(expandedIndex >= 0 ? expandedIndex - 1 : -1);

    const { swipeHandlers } = useGalleryBehaviors(
        setExpandedIndex,
        advance,
        reverse,
    );

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => setDelayed(true), 100);
        }
    }, [isLoading]);

    useEffect(() => {
        if (loadedPhotos.length === 0) {
            setLoadedPhotos([
                ...gallery.slice(0, window?.outerWidth > 1500 ? 20 : 10),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gallery.length]);

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const gallery = await getGallery(slug);

                setGallery(gallery.reverse());
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        gallery,
        isLoading,
        swipeHandlers,
        expandedIndex,
        setExpandedIndex,
        delayed,
        loadedPhotos,
        getNewPage,
    };
}
