import { Fragment, useEffect, useState } from 'react';
import useJobHelpers from './hooks/useJobHelpers';
import useAuth from '@/src/Authentication/components/hooks/useAuth';
import { format } from 'date-fns';

const statuses: any = {
    Ready: 'text-green-700 bg-green-50 ring-green-600/20',
    Okay: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    'Needs Attention': 'text-red-700 bg-red-50 ring-red-600/10',
};

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export type JobsFilter = 'all' | 'mine' | 'next30' | 'followUp' | 'previous30';

interface JobListProps {
    filter?: JobsFilter;
    search?: string;
}

export default function JobsList({ filter, search }: JobListProps) {
    const [jobs, setJobs] = useState<any[]>([]);
    const [groupings, setGroupings] = useState<Groupings>({
        ...groupEvents([]),
    });

    const { auth } = useAuth();

    const { getJobs } = useJobHelpers();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const records = await getJobs();
                const groupings = groupEvents(records);
                console.log(groupings);
                isMounted && setGroupings(groupings);
                // setTimeout(() => isMounted && setIsLoading(false), 0);
            } catch (error) {
                console.error(error);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const recordsByDateRange = [];
        let past = groupings.past;
        let active = groupings.active;
        let thisWeek = groupings.thisWeek;
        let nextWeek = groupings.nextWeek;
        let nextMonth = groupings.nextMonth;
        let greaterThanNextMonth = groupings.greaterThanNextMonth;

        if (filter === 'mine') {
            past = groupings.past.filter((job) => job.ownerId === auth.id);
            active = groupings.active.filter((job) => job.ownerId === auth.id);
            thisWeek = groupings.thisWeek.filter(
                (job) => job.ownerId === auth.id,
            );
            nextWeek = groupings.nextWeek.filter(
                (job) => job.ownerId === auth.id,
            );
            nextMonth = groupings.nextMonth.filter(
                (job) => job.ownerId === auth.id,
            );
            greaterThanNextMonth = groupings.greaterThanNextMonth.filter(
                (job) => job.ownerId === auth.id,
            );

            recordsByDateRange.push(
                {
                    date: 'Active',
                    jobs: active,
                },
                {
                    date: 'This Week',
                    jobs: thisWeek,
                },
                {
                    date: 'Next Week',
                    jobs: nextWeek,
                },
                {
                    date: 'Next Month',
                    jobs: nextMonth,
                },
                {
                    date: 'Greater Than Next Month',
                    jobs: greaterThanNextMonth,
                },
            );
        } else if (filter === 'next30') {
            recordsByDateRange.push(
                {
                    date: 'Active',
                    jobs: active,
                },
                {
                    date: 'This Week',
                    jobs: thisWeek,
                },
                {
                    date: 'Next Week',
                    jobs: nextWeek,
                },
                {
                    date: 'Next Month',
                    jobs: nextMonth,
                },
            );
        } else if (filter === 'previous30') {
            recordsByDateRange.push({
                date: 'Past',
                jobs: past,
            });
        } else {
            recordsByDateRange.push(
                {
                    date: 'Active',
                    jobs: active,
                },
                {
                    date: 'This Week',
                    jobs: thisWeek,
                },
                {
                    date: 'Next Week',
                    jobs: nextWeek,
                },
                {
                    date: 'Next Month',
                    jobs: nextMonth,
                },
                {
                    date: 'Greater Than Next Month',
                    jobs: greaterThanNextMonth,
                },
            );
        }

        setJobs(recordsByDateRange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, filter, search]);

    return (
        <div className="space-y-16 xl:space-y-20">
            <div>
                <div className="overflow-hidden border-t border-gray-100">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <table className="w-full text-left">
                                <thead className="sr-only">
                                    <tr>
                                        <th></th>
                                        <th className="hidden sm:table-cell"></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobs.map((day) => (
                                        <Fragment key={day.date}>
                                            <tr className="text-sm leading-6 text-gray-900">
                                                <th
                                                    scope="colgroup"
                                                    colSpan={3}
                                                    className="relative isolate py-2 font-semibold"
                                                >
                                                    <time dateTime={day.date}>
                                                        {day.date}
                                                    </time>
                                                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                                </th>
                                            </tr>
                                            {day.jobs.map((job: any) => (
                                                <tr key={job.id}>
                                                    <td className="relative py-5 pr-6">
                                                        <div className="flex gap-x-6">
                                                            {/*<transaction.icon
                                                                    className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                                                    aria-hidden="true"
                                                />*/}
                                                            <div className="flex-auto">
                                                                <div className="flex items-start gap-x-3">
                                                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                                                        {
                                                                            job.name
                                                                        }
                                                                    </div>
                                                                    {job.status ? (
                                                                        <div
                                                                            className={classNames(
                                                                                statuses[
                                                                                    job
                                                                                        .status
                                                                                ],
                                                                                'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset',
                                                                            )}
                                                                        >
                                                                            {
                                                                                job.status
                                                                            }
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                {job.activationCount ? (
                                                                    <div className="mt-1 text-xs leading-5 text-gray-500">
                                                                        {
                                                                            job.activationCount
                                                                        }
                                                                        {` activation${
                                                                            job.activationCount >
                                                                                1 ||
                                                                            job.activationCount ===
                                                                                0
                                                                                ? 's'
                                                                                : ''
                                                                        }`}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                                    </td>
                                                    <td className="hidden py-5 pr-6 sm:table-cell">
                                                        <div className="text-sm leading-6 text-gray-900">
                                                            {job.client}
                                                        </div>
                                                        <div className="text-sm leading-6 text-gray-900">
                                                            {format(
                                                                job.startDate,
                                                                'MM/dd/yyyy',
                                                            )}{' '}
                                                            -{' '}
                                                            {format(
                                                                job.endDate,
                                                                'MM/dd/yyyy',
                                                            )}
                                                        </div>
                                                        <div className="mt-1 text-xs leading-5 text-gray-500">
                                                            {job.description.substring(
                                                                0,
                                                                50,
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="py-5 text-right">
                                                        <div className="flex justify-end">
                                                            <a
                                                                href={`jobs/${job.id}`}
                                                                className="text-sm font-medium leading-6 text-black hover:text-indigo-500"
                                                            >
                                                                View
                                                                <span className="hidden sm:inline">
                                                                    {' '}
                                                                    Job &rarr;
                                                                </span>
                                                                <span className="sr-only">
                                                                    {
                                                                        job.startDate
                                                                    }
                                                                    ,{' '}
                                                                    {job.client}
                                                                </span>
                                                            </a>
                                                        </div>
                                                        <div className="mt-1 text-xs leading-5 text-gray-500">
                                                            Owner{' '}
                                                            <span className="text-gray-900">
                                                                {job.firstName}{' '}
                                                                {job.lastName}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

type Event = {
    id: string;
    name: string;
    startDate: string;
    ownerId: string;
    ownerName: string;
    eneDate: string;
    activationCount: number;
    status: string;
};

type Groupings = {
    past: Event[];
    active: Event[];
    thisWeek: Event[];
    nextWeek: Event[];
    nextMonth: Event[];
    greaterThanNextMonth: Event[];
};

function groupEvents(events: any[]): Groupings {
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const endOfToday = new Date(today.setHours(23, 59, 59, 999));
    const endOfThisWeek = new Date(
        endOfToday.getTime() + (6 - endOfToday.getDay()) * oneDay,
    );
    const endOfNextWeek = new Date(endOfThisWeek.getTime() + 7 * oneDay);
    const endOfNextMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 2,
        0,
    );

    const groupings: Groupings = {
        past: [],
        active: [],
        thisWeek: [],
        nextWeek: [],
        nextMonth: [],
        greaterThanNextMonth: [],
    };

    events.forEach((event) => {
        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        if (startDate < startOfToday) {
            if (endDate < startOfToday) {
                groupings.past.push(event);
            } else {
                groupings.active.push(event);
            }
        } else if (startDate < endOfThisWeek) {
            groupings.thisWeek.push(event);
        } else if (startDate < endOfNextWeek) {
            groupings.nextWeek.push(event);
        } else if (startDate < endOfNextMonth) {
            groupings.nextMonth.push(event);
        } else {
            groupings.greaterThanNextMonth.push(event);
        }
    });

    return groupings;
}
