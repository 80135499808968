import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';

interface DeviceKeyActionsProps {
    orgId: number;
    row: any;
    groupId: number;
    onDelete: () => void;
}

export default function GroupDeviceKeyActions({
    orgId,
    groupId,
    row,
    onDelete,
}: DeviceKeyActionsProps) {
    const deviceKeyId = row.original.id;
    const authenticatedApi = useAuthenticatedApi();

    const performDelete = () => {
        const deleteRequest = async () => {
            const response = await authenticatedApi.delete(
                `${orgId}/device-groups/${groupId}/${deviceKeyId}`,
            );

            if (response.status === 200) {
                onDelete();
            }
        };

        const shouldDelete = window.confirm(
            `Are you sure you would like to remove ${row.original.name}`,
        );

        if (shouldDelete) {
            deleteRequest();
        }
    };
    return (
        <span className="Table-options">
            <i onClick={performDelete} className="bi bi-trash3-fill"></i>
        </span>
    );
}
