import { useEffect, useState } from 'react';
import useProcessing, { ProcessingFlow } from './hooks/useProcessing';
import LoadingIndicator from '../../Common/components/LoadingIndicator';
import Table, { createColumnHelper } from '../../Common/components/Table';
import ProcessingActions from './ProcessingActions';

const columnHelper = createColumnHelper<ProcessingFlow>();

export default function ProcessingList({
    galleryId,
    galleryName,
}: {
    galleryId: number;
    galleryName: string;
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [processingFlows, setProcessingFlows] = useState<ProcessingFlow[]>(
        [],
    );

    const { getProcessingFlows, environments } = useProcessing();
    const [renderCount, setRenderCount] = useState<number>(0);

    const columnDefinitions = [
        columnHelper.accessor('environmentId', {
            id: 'environmentId',
            header: () => <span>environment</span>,
            cell: (info) =>
                environments?.find(
                    (environment) => info.getValue() === environment.id,
                )?.environment,
        }),
        columnHelper.accessor('enabled', {
            id: 'enabled',
            header: () => <span>enabled</span>,
            cell: (info) =>
                info.getValue() ? (
                    <div className="center">
                        <i className="bi bi-check-circle-fill"></i>
                    </div>
                ) : null,
        }),
        columnHelper.accessor('flowDefinition', {
            id: 'flowDefinition',
            header: () => <span>flow definition</span>,
            cell: (info) => {
                const flowDefinition = info.getValue();
                return (
                    <div className="row">
                        <span>
                            {flowDefinition
                                ? JSON.parse(flowDefinition)
                                      .map((step: any) => step.name)
                                      .join('→')
                                : 'none'}
                        </span>
                    </div>
                );
            },
        }),
        columnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <ProcessingActions
                    row={props.row}
                    galleryId={galleryId}
                    onDelete={() => setRenderCount(renderCount + 1)}
                />
            ),
        }),
    ];

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const processingFlows = await getProcessingFlows(
                    galleryId ? +galleryId : 0,
                );

                if (isMounted) {
                    setProcessingFlows(processingFlows);
                }
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCount]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            {processingFlows.length === 0 ? (
                'No flows defined'
            ) : (
                <Table data={processingFlows} columns={columnDefinitions} />
            )}
        </div>
    );
}
