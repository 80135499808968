import { useEffect, useState } from 'react';

export type SurveyAnswers = Record<string, string | boolean>;

interface SharingSurveyProps {
    galleryId: number;
    survey: SurveyQuestion[];
    onComplete: (answers: SurveyAnswers) => void;
    children: React.ReactElement | null;
}

export default function SharingSurvey({
    galleryId,
    survey,
    onComplete,
    children,
}: SharingSurveyProps) {
    const [answers, setAnswers] = useState<SurveyAnswers>({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    useEffect(() => {
        if (currentQuestionIndex >= survey.length) {
            onComplete(answers);
        }
    }, [currentQuestionIndex, survey, answers, onComplete]);

    if (currentQuestionIndex >= survey.length) {
        return children;
    }

    const { question, answerTypeId } = survey[currentQuestionIndex];

    return (
        <div className="flex justify-center items-center centered">
            <div className="Share-container ">
                <div className="Share-prompt Share-body">
                    <div className="Share-form">
                        <h1>{question}</h1>
                        {answerTypeId === 1 ? (
                            <>
                                <button
                                    className="Link-button"
                                    onClick={() => {
                                        setAnswers({
                                            ...answers,
                                            [survey[currentQuestionIndex].id]:
                                                true,
                                        });
                                        setCurrentQuestionIndex(
                                            currentQuestionIndex + 1,
                                        );
                                    }}
                                >
                                    Yes
                                </button>
                                <br />
                                <span
                                    className="Tertiary-button"
                                    onClick={() => {
                                        setAnswers({
                                            ...answers,
                                            [survey[currentQuestionIndex].id]:
                                                false,
                                        });
                                        setCurrentQuestionIndex(
                                            currentQuestionIndex + 1,
                                        );
                                    }}
                                >
                                    No
                                </span>
                            </>
                        ) : null}
                        {answerTypeId === 2 ? (
                            <>
                                <input
                                    type="text"
                                    className="Share-input"
                                    onChange={(e) => {
                                        setAnswers({
                                            ...answers,
                                            [survey[currentQuestionIndex].id]:
                                                e.target.value,
                                        });
                                    }}
                                />
                                <br />
                                <span
                                    className="Tertiary-button"
                                    onClick={() => {
                                        setCurrentQuestionIndex(
                                            currentQuestionIndex + 1,
                                        );
                                    }}
                                >
                                    Next
                                </span>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
