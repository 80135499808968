import useAuthenticatedApi, {
    HTTP_STATUS_CODES,
} from '../../../Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '../../../Authentication/components/hooks/useUserOrg';

export default function useEditActivation(activationId: number) {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const saveActivation = async (activation: any) => {
        const newActivation = { ...activation, orgId };

        const response = await authenticatedApi.put(
            `/activations/${activationId}`,
            JSON.stringify(newActivation),
        );

        return response.status === HTTP_STATUS_CODES.OK;
    };

    return {
        saveActivation,
    };
}
