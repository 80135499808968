import { useCallback, useEffect, useState } from 'react';
import api from '../../../services/api';

export default function usePersonalGallery(destination: string) {
    const [slug, setSlug] = useState('');

    const getSlug = useCallback(async (destination: string) => {
        if (!destination) {
            return;
        }

        const results = await api.get(
            `/share/lookup-slug/${encodeURIComponent(destination)}`,
        );

        if (results.data) {
            setSlug(results.data.slug);
        }
    }, []);

    useEffect(() => {
        getSlug(destination);
    }, [destination, getSlug]);

    return {
        slug,
        getSlug,
    };
}
