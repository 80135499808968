import { useState } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export function useDeviceGroup(orgId: number) {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const createDeviceGroup = async (deviceGroup: NewDeviceGroup) => {
        setIsLoading(true);
        try {
            const results = await authenticatedApi.post(
                `${orgId}/device-groups/create`,
                deviceGroup,
            );

            return results.data;
        } catch (e: any) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const getDeviceGroups = async () => {
        setIsLoading(true);
        try {
            const results = await authenticatedApi.get(
                `${orgId}/device-groups/`,
            );

            return results.data;
        } catch (e: any) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        createDeviceGroup,
        getDeviceGroups,
        isLoading,
        error,
    };
}
