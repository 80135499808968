import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '../../../Authentication/components/hooks/useUserOrg';

export default function useCreateActivation() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const createActivation = async (activationObject: any) => {
        const newActivation = { ...activationObject, orgId };

        const response = await authenticatedApi.post(
            `/${orgId}/activations/create`,
            JSON.stringify(newActivation),
        );

        return response.data;
    };

    const cloneActivation = async (
        activationId: number,
        name: string,
        date: string,
        jobId?: number,
    ) => {
        const response = await authenticatedApi.post(
            `/${orgId}/activations/clone/${activationId}`,
            JSON.stringify({ name, date, jobId }),
        );

        return response.data;
    };

    return {
        createActivation,
        cloneActivation,
    };
}
