import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import useTemplates from './hooks/useTemplates';
import { Button } from '@/src/shadComponents/ui/button';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export default function UploadTemplate() {
    const { importTemplate } = useTemplates();
    const { orgId } = useUserOrg();
    const formik = useFormik({
        initialValues: {
            name: '',
            orgId: orgId.toString(),
        },
        onSubmit: importTemplate,
    });

    return (
        <div>
            <h1>Import Template</h1>
            <p>
                the zip structure expects an index.html <br />
                and an images directory containing all image assets
            </p>
            <form
                className="Form"
                onSubmit={formik.handleSubmit}
                encType="multpart/form-data"
            >
                <div>
                    <label htmlFor="name">name</label>
                    <input
                        id="name"
                        name="name"
                        placeholder="template name"
                        onChange={(e) => {
                            formik.setFieldValue('name', e.currentTarget.value);
                        }}
                    />
                </div>
                <input
                    type="file"
                    name="zip"
                    accept="application/zip"
                    onChange={(e) => {
                        formik.setFieldValue('zip', e.currentTarget.files?.[0]);
                    }}
                />
                <br />
                <br />

                <div className="Layout-row">
                    <Button type="submit" className="Primary-button">
                        import
                    </Button>
                    <NavLink to="/templates">
                        <Button className="Secondary-button">cancel</Button>
                    </NavLink>
                </div>
            </form>
        </div>
    );
}
