import { useState, useCallback } from 'react';
import CreateShare from '../../Share/components/CreateShare';
import useAutoShare from '../../Share/components/hooks/useAutoShare';

interface ShareModalProps {
    gallery: GalleryObject;
    setShowShareModal: (show: boolean) => void;
    shareIdentifiers: string[];
}

export default function ShareModal({
    gallery,
    setShowShareModal,
    shareIdentifiers,
}: ShareModalProps) {
    const [isSharingComplete, setIsSharingComplete] = useState(false);

    const {
        shareDestination,
        setShareDestination,
        shareType,
        setShareType,
        isSharing,
        setAutoShare,
        autoShare,
        setIsSharing,
        setLastSharedIdentifier,
    } = useAutoShare(gallery.id);

    const onShare = async (destination: string, identifier: string) => {
        setLastSharedIdentifier(identifier);
        setShareDestination(destination);
        setIsSharingComplete(true);
    };

    const reset = useCallback(() => {
        setShareDestination('');
        setShareType(null);
        setIsSharingComplete(false);
        setAutoShare(false);
        setLastSharedIdentifier('');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Fullscreen-modal">
            <div
                className="close-modal"
                onClick={() => setShowShareModal(false)}
            >
                <i className="bi bi-x"></i>
            </div>
            <CreateShare
                {...{
                    isSharing,
                    isSharingComplete,
                    shareType,
                    setShareType,
                    galleryInfo: gallery,
                    shareDestination,
                    onShare,
                    setAutoShare,
                    autoShare,
                    setIsSharing,
                    emailEnabled: gallery?.emailEnabled,
                    textEnabled: gallery?.textEnabled,
                    reset,
                    shareIdentifiers,
                }}
            />
        </div>
    );
}
