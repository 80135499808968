import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export default function useJobHelpers() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();
    const getJobTypes = async () => {
        const response = await authenticatedApi.get(`/${orgId}/jobs/types`);
        return response.data;
    };

    const getJobClients = async (jobId: number) => {
        const response = await authenticatedApi.get(
            `/${orgId}/jobs/${jobId}/account/assignments`,
        );
        return response.data;
    };

    const getJobAssignments = async (jobId: number) => {
        const response = await authenticatedApi.get(
            `/${orgId}/jobs/${jobId}/assignments`,
        );

        return response.data;
    };

    const getJobs = async () => {
        const response = await authenticatedApi.get(`/${orgId}/jobs`);
        return response.data;
    };

    const getJob = async (jobId: number) => {
        const response = await authenticatedApi.get(`/${orgId}/jobs/${jobId}`);
        return response.data;
    };

    const getJobActivations = async (jobId: number) => {
        const response = await authenticatedApi.get(
            `/${orgId}/activations/job/${jobId}`,
        );
        return response.data;
    };

    return {
        getJobTypes,
        getJobClients,
        getJobAssignments,
        getJobs,
        getJob,
        getJobActivations,
    };
}
