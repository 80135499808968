import { useState } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export function useDeviceInvite(orgId: number) {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const createDeviceInvite = async (deviceInvite: NewDeviceInvite) => {
        setIsLoading(true);
        try {
            setError(null);
            const results = await authenticatedApi.post(
                `${orgId}/device-invites/create`,
                deviceInvite,
            );

            return results.data;
        } catch (e: any) {
            console.log(e.response.data.error);
            setError(e.response.data.error);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        createDeviceInvite,
        isLoading,
        error,
        setError,
    };
}
