interface ExperienceEditorProps {
    screens: ScreenDefinition[];
    onScreenSelected: (screenId: string) => void;
    onDefinitionChange: (definition: Definition) => void;
}

export default function ExperienceEditor({
    screens,
    onScreenSelected,
    onDefinitionChange,
}: ExperienceEditorProps) {
    if (!screens || screens.length === 0) {
        return (
            <ScreenCard>
                <i className="bi bi-plus text-2xl cursor-pointer"></i>
                <div>Create Screen</div>
            </ScreenCard>
        );
    }

    return (
        <div className="grid grid-cols-4 gap-4">
            {screens.map((screen: ScreenDefinition) => {
                return (
                    <ScreenCard key={screen?.id}>
                        <div
                            className="flex flex-col items-center justify-center"
                            onClick={() => onScreenSelected(screen?.id)}
                        >
                            <div className="text-lg font-bold">
                                {screen?.id}
                            </div>
                            <div className="text-sm text-gray-500 capitalize">
                                {screen?.stage?.type}
                            </div>
                        </div>
                    </ScreenCard>
                );
            })}
        </div>
    );
}

export function ScreenCard({ children }: { children: React.ReactNode }) {
    return (
        <div className="overflow-hidden bg-[#EEEFEF] shadow-md sm:rounded-lg max-w-[280px]">
            <div className="flex flex-col items-center justify-center px-4 py-5 sm:p-6  h-[400px]">
                {children}
            </div>
        </div>
    );
}
