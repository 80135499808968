import { DatePickerWithRange } from '@/src/shadComponents/ui/DateRangePicker';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/src/shadComponents/ui/select';

import { DateRange } from 'react-day-picker';
import useJobHelpers from './hooks/useJobHelpers';
import { useEffect, useState } from 'react';
import useAccounts from '@/src/Accounts/components/hooks/useAccounts';
import { Formik, Form, Field } from 'formik';
import { format } from 'date-fns';
import MultiSelectList from '@/src/shadComponents/ui/MultiSelectList';

const ClientRoleId = 2;

export default function JobForm({ job, onSubmit, onCancel }: any) {
    const pastMonth = job?.startDate ? new Date(job?.startDate) : new Date();
    const endDate = job?.endDate ? new Date(job?.endDate) : new Date();
    const defaultSelected: DateRange = {
        from: pastMonth,
        to: endDate,
    };
    const [range, setRange] = useState<DateRange | undefined>(defaultSelected);

    const { getJobTypes } = useJobHelpers();
    const { getAccounts } = useAccounts();

    const [jobTypes, setJobTypes] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [clients, setClients] = useState<any[]>([]);

    useEffect(() => {
        getJobTypes().then((data) => {
            if (data?.length === 0) {
                return;
            }
            setJobTypes(data);
        });

        getAccounts().then((data) => {
            if (data?.length === 0) {
                return;
            }
            setUsers(
                data
                    .filter((a: any) => a.accountRoleId !== ClientRoleId)
                    .sort((a: any, b: any) =>
                        a.firstName.localeCompare(b.firstName),
                    ),
            );

            setClients(
                data
                    .filter((a: any) => a.accountRoleId === ClientRoleId)
                    .sort((a: any, b: any) =>
                        a.firstName.localeCompare(b.firstName),
                    )
                    .map((c: any) => ({
                        label: `${c.firstName} ${c.lastName} (${c.email})`,
                        value: String(c.id),
                    })),
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Formik
            initialValues={job}
            onSubmit={async (values, actions) => {
                console.log('Saving job', values);
                onSubmit(values);
            }}
        >
            {(props) => (
                <div className="">
                    <header className="relative isolate pt-0">
                        <div
                            className="absolute inset-0 -z-10 overflow-hidden"
                            aria-hidden="true"
                        >
                            <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                            <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                        </div>

                        <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                            <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                                <div className="flex items-center gap-x-6">
                                    <h1>
                                        <div className="text-sm leading-6 text-gray-500">
                                            <span className="text-gray-700">
                                                Create
                                            </span>
                                        </div>
                                        <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                            Add A Job
                                        </div>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </header>
                    <Form
                        className="mt-6"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div className="space-y-12">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10  pb-12 md:grid-cols-3">
                                <div className="px-8">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                        Basic Details
                                    </h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Basic information about the job.
                                    </p>
                                </div>

                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 p-4 sm:p-0">
                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="name"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Name
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-full">
                                        <label
                                            htmlFor="photo"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Job Lead
                                        </label>
                                        <div className="mt-2 flex items-center gap-x-3">
                                            <Select
                                                value={
                                                    props.values.ownerId ||
                                                    undefined
                                                }
                                                onValueChange={(value) => {
                                                    props.setFieldValue(
                                                        'ownerId',
                                                        +value,
                                                    );
                                                }}
                                            >
                                                <SelectTrigger className="w-[180px]">
                                                    <SelectValue placeholder="Select a job owner" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {users.map((user) => (
                                                        <SelectItem
                                                            key={`user-${user.id}`}
                                                            value={user.id}
                                                        >
                                                            {user.firstName}{' '}
                                                            {user.lastName}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="location"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Type of Event
                                        </label>
                                        <div className="mt-2">
                                            <Select
                                                value={
                                                    props.values.type ||
                                                    undefined
                                                }
                                                onValueChange={(value) => {
                                                    props.setFieldValue(
                                                        'type',
                                                        +value,
                                                    );
                                                }}
                                            >
                                                <SelectTrigger className="w-[180px]">
                                                    <SelectValue placeholder="Select an event type" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {jobTypes.map((jobType) => (
                                                        <SelectItem
                                                            key={`jobs-${jobType.id}`}
                                                            value={jobType.id}
                                                        >
                                                            {jobType.name}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="dates"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Dates
                                        </label>
                                        <div>
                                            <DatePickerWithRange
                                                date={range}
                                                setDate={(newRange: any) => {
                                                    props.setFieldValue(
                                                        'startDate',
                                                        newRange.from
                                                            ? format(
                                                                  newRange.from,
                                                                  'yyyy-MM-dd',
                                                              )
                                                            : undefined,
                                                    );
                                                    props.setFieldValue(
                                                        'endDate',
                                                        newRange.to
                                                            ? format(
                                                                  newRange.to,
                                                                  'yyyy-MM-dd',
                                                              )
                                                            : undefined,
                                                    );
                                                    setRange(newRange);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label
                                            htmlFor="description"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Description
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="description"
                                                name="description"
                                                rows={5}
                                                as="textarea"
                                                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={props.values.description}
                                            />
                                        </div>
                                        <p className="mt-3 text-sm leading-6 text-gray-600">
                                            Describe the basic details of the
                                            job.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10  pb-12 md:grid-cols-3">
                                <div className="px-8">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                        Clients
                                    </h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Add clients to this job. This will allow
                                        you to send them documents and an invite
                                        to their job portal.
                                    </p>
                                </div>

                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 p-4 sm:p-0">
                                    <div className="col-span-full">
                                        <label
                                            htmlFor="description"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Clients
                                        </label>
                                        <div className="mt-2">
                                            <MultiSelectList
                                                options={clients}
                                                values={props.values.clientIds}
                                                title="client"
                                                onChange={(value) => {
                                                    props.setFieldValue(
                                                        'clientIds',
                                                        value,
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                            <button
                                type="button"
                                className="text-sm font-semibold leading-6 text-gray-900"
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                            >
                                Save
                            </button>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}
