import { createSlice } from '@reduxjs/toolkit';

interface Experience {
    activationId: number;
    screens: string[];
}

export interface ExperienceState {
    entities: Record<string, Experience>;
    ids: string[];
}

const initialState: ExperienceState = {
    entities: {},
    ids: [],
};

const experienceSlice = createSlice({
    name: 'experience',
    initialState,
    reducers: {
        addExperience(state, action) {
            const experience = action.payload;
            const id = experience.id;
            state.entities[id] = experience;

            if (!state.ids.includes(id)) {
                state.ids.push(id);
            }
        },
        removeExperience(state, action) {
            const { id } = action.payload;

            delete state.entities[id];
            state.ids = state.ids.filter((screenId) => screenId !== id);
        },
        resetExperience(state) {
            state.entities = {};
            state.ids = [];
        },
    },
});

export const { addExperience, removeExperience, resetExperience } =
    experienceSlice.actions;

export default experienceSlice.reducer;
