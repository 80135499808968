import { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';
import useRerender from '../../Common/components/hooks/useRerender';
import ListPickerModal from '../../Common/components/ListPickerModal';
import { createColumnHelper } from '../../Common/components/Table';
import ActivationActions from './ActivationActions';
import ActivationTagAssociations from './ActivationTagAssociations';
import useTags from './hooks/useTags';
import { Button } from '@/src/shadComponents/ui/button';
import PageWithTable from '@/src/Common/components/PageWithTable';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import ActivationsTable from './ActivationsTable';
import { Switch } from '@/src/shadComponents/ui/switch';
import CloneActivationModal from './CloneActivationModal';
import FeatureFlag from '@/src/Features/components/FeatureFlag';
import { FeatureFlags } from '@/src/Features/lib/constants';
import CreateActivationModal from './CreateActivationModal';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

const columnHelper = createColumnHelper<Activation>();

export default function Activations() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigation = useNavigate();
    const [tags, setTags] = useState([]);
    const { getTags } = useTags();
    const [activations, setActivations] = useState<Activation[]>([]);
    const authenticatedApi = useAuthenticatedApi();
    const [showTagFilters, setShowTagFilters] = useState(false);
    const [tagFilters, setTagFilters] = useState<number[]>([]);
    const [showArchived, setShowArchived] = useState(false);
    const [searchTerms, setSearchTerms] = useState('');
    const [showingCloneModal, setShowingCloneModal] = useState(false);
    const [activationToClone, setActivationToClone] =
        useState<Activation | null>(null);
    const [isShowingNewActivationModal, setIsShowingNewActivationModal] =
        useState(false);
    const { orgId } = useUserOrg();

    const { render, renderCount } = useRerender();

    const onDelete = () => {
        render();
    };

    const onClone = async (id: number, activation: Activation) => {
        setActivationToClone(activation);
        setShowingCloneModal(true);
    };

    const columnDefinitions = [
        columnHelper.accessor('id', {
            id: 'id',
            header: () => <span>id</span>,
            cell: (info) => (
                <span>
                    <Link to={`${info.row.original.id}`}>
                        {info.getValue()}
                    </Link>
                </span>
            ),
        }),
        columnHelper.accessor('name', {
            id: 'name',
            header: () => <span>name</span>,
            cell: (info) => (
                <span>
                    <Link to={`${info.row.original.id}`}>
                        {info.getValue()}
                    </Link>
                </span>
            ),
        }),
        columnHelper.accessor('date', {
            id: 'date',
            header: () => <span>date</span>,
            cell: (info) => <span>{info.getValue().split('T')[0]}</span>,
        }),
        columnHelper.accessor('location', {
            id: 'location',
            header: () => <span>location</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.display({
            id: 'tags',
            header: () => (
                <span
                    onClick={() => {
                        setShowTagFilters(true);
                    }}
                >
                    tags
                    <i className="bi bi-filter"></i>
                    {tagFilters.length ? `(${tagFilters.length})` : null}
                </span>
            ),
            cell: (props: { row: any }) => (
                <ActivationTagAssociations
                    activationId={props.row.original.id}
                />
            ),
        }),
        columnHelper.accessor('owner', {
            id: 'owner',
            header: () => <span>owner</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <ActivationActions onDelete={onDelete} row={props.row} />
            ),
        }),
    ];

    useEffect(() => {
        let isMounted = true;
        const getEvents = async () => {
            try {
                const response = await authenticatedApi.get(
                    `${orgId}/activations/${
                        tagFilters.length > 0
                            ? `?tagFilters=${tagFilters.join()}`
                            : ''
                    }`,
                );
                // @ts-ignore
                setActivations(
                    response.data
                        .filter(
                            (activation: Activation) =>
                                showArchived || !activation.isArchived,
                        )
                        .sort((a: Activation, b: Activation) => {
                            if (a.date > b.date) {
                                return -1;
                            }
                            return 1;
                        }),
                );

                const tags = await getTags();
                setTags(tags);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getEvents();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCount, tagFilters, showArchived]);

    if (isLoading) {
        return <div>Loading</div>;
    }

    let actions = (
        <>
            <FeatureFlag
                flag={FeatureFlags.ACTIVATION_TEMPLATES_V1}
                fallback={() => (
                    <NavLink to="create">
                        <Button className="ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black">
                            <PlusSmallIcon
                                className="-ml-1.5 h-5 w-5"
                                aria-hidden="true"
                            />
                            Create Activation
                        </Button>
                    </NavLink>
                )}
            >
                <Button
                    onClick={() => setIsShowingNewActivationModal(true)}
                    className="ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                >
                    <PlusSmallIcon
                        className="-ml-1.5 h-5 w-5"
                        aria-hidden="true"
                    />
                    Create Activation
                </Button>
            </FeatureFlag>
            <FeatureFlag flag={FeatureFlags.LEGACY_ADMIN_V1}>
                <NavLink to="v3sync">
                    <Button className="ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black">
                        v3 sync
                    </Button>
                </NavLink>
            </FeatureFlag>
        </>
    );

    return (
        <PageWithTable title="Activations" actions={actions}>
            <div className="pagination-row">
                <span className="pl-7">
                    <Switch
                        id="archived"
                        checked={showArchived}
                        onCheckedChange={setShowArchived}
                    />
                    <label htmlFor="archived" className="text-sm mx-2">
                        Include Archived
                    </label>
                </span>
                <div className="spacer" />
                <input
                    type="text"
                    value={searchTerms}
                    onChange={(e) => {
                        const search = e.target.value;
                        setSearchTerms(search);
                    }}
                    className="border p-1 rounded w-[400px]"
                />
                <i className="bi bi-search"></i>
            </div>
            <br />
            <ActivationsTable
                onDelete={onDelete}
                onClone={onClone}
                key={`activationTable${activations.length}`}
                data={activations.filter((activation) => {
                    if (searchTerms === '') {
                        return true;
                    }
                    return activation.name
                        .toLocaleLowerCase()
                        .includes(searchTerms.toLocaleLowerCase());
                })}
                columns={columnDefinitions}
                enablePagination={true}
            />
            {showTagFilters && (
                <ListPickerModal
                    list={tags}
                    selectedIds={tagFilters}
                    onSave={(filters: number[]) => {
                        setTagFilters(filters);
                        setShowTagFilters(false);
                    }}
                    onClose={() => setShowTagFilters(false)}
                />
            )}
            {showingCloneModal && activationToClone ? (
                <CloneActivationModal
                    id={activationToClone?.id}
                    activation={activationToClone}
                    onDismiss={(activationId?: number) => {
                        setShowingCloneModal(false);

                        if (!activationId) {
                            return;
                        }

                        navigation(`/activations/${activationId}`);
                    }}
                />
            ) : null}
            {isShowingNewActivationModal && (
                <CreateActivationModal
                    onDismiss={() => {
                        setIsShowingNewActivationModal(false);
                    }}
                />
            )}
        </PageWithTable>
    );
}
