import InfiniteScroll from 'react-infinite-scroll-component';
import useOrgActivity from './useOrgActivity';
import GalleryPreviewImage from '@/src/Gallery/components/GalleryPreviewImage';
import Loading from '@/src/Common/components/Loading';
import { format } from 'date-fns';
import { useState } from 'react';

export default function OrgActivityFeed() {
    const { feed } = useOrgActivity();
    const [expandedIndex, setExpandedIndex] = useState<ShareIdentifier | null>(
        null,
    );

    return (
        <div className="max-w-8xl mx-auto overflow-hidden">
            <header className="flex sticky items-center justify-between border-b border-white/5  py-4  sm:py-6 ">
                <h1 className="text-base font-semibold leading-7 text-black">
                    Recent Media
                </h1>
            </header>
            <div
                className="min-h-[80%] flex pl-0 pb-0 overflow-hidden max-h-full h-full "
                id="orgActivityFeed"
            >
                <InfiniteScroll
                    dataLength={feed.length}
                    style={{
                        padding: 0,
                        paddingBottom: 0,
                        overflow: 'hidden',
                    }}
                    scrollableTarget="orgActivityFeed"
                    hasMore={false}
                    loader={<Loading isLoading={true} />}
                    next={() => {}}
                >
                    {feed.map((item, itemIdx) => (
                        <div key={item.id} className="pl-0">
                            <div className="relative pb-2">
                                {itemIdx !== feed.length - 1 ? (
                                    <span
                                        className="absolute left-[50px] top-5 -ml-px h-full w-[1px] bg-gray-300"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div className="relative flex items-start space-x-3 pl-0">
                                    <span className="flex flex-col items-center border-[1px] border-gray-300 bg-white p-3 rounded-[4px] w-[100px] min-w-[100px]">
                                        <a
                                            href={`/activations/${item?.activationId}`}
                                        >
                                            <p className="clickable text-sm font-regular text-center text-gray-700 text-ellipsis ">
                                                {item?.activationName}
                                            </p>
                                        </a>
                                        <p className="text-[0.5rem] font-medium text-gray-500">
                                            {format(item?.createdAt, 'Pp')}
                                        </p>
                                    </span>
                                    <GalleryPreviewImage
                                        media={item}
                                        isExpanded={
                                            item.shareIdentifier ===
                                            expandedIndex
                                        }
                                        setExpandedIndex={setExpandedIndex}
                                        index={item.shareIdentifier}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    );
}
