import { cn } from '@/src/lib/utils';
import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from './command';
import { Button } from './button';
import { Check, ChevronsUpDown } from 'lucide-react';

type ComboboxOption = {
    label: string;
    value: string;
};

type ComboBoxOptions = ComboboxOption[];

interface ComboBoxProps {
    options: ComboBoxOptions;
    title: string;
    onChange: (value: string) => void;
}

export default function ComboBox({ options, title, onChange }: ComboBoxProps) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    // @ts-ignore
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[500px] justify-between"
                >
                    {value
                        ? options.find((options) => options.value === value)
                              ?.label
                        : `Select ${title}...`}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[500px] p-0">
                <Command>
                    <CommandInput placeholder={`Search ${title}...`} />
                    <CommandEmpty>No {title} found.</CommandEmpty>
                    <CommandGroup>
                        {options.map((option) => (
                            <CommandItem
                                key={option.value}
                                value={option.value}
                                onSelect={(currentValue) => {
                                    setValue(
                                        currentValue === value
                                            ? ''
                                            : currentValue,
                                    );
                                    setOpen(false);
                                    onChange(currentValue);
                                }}
                            >
                                <Check
                                    className={cn(
                                        'mr-2 h-4 w-4',
                                        value === option.value
                                            ? 'opacity-100'
                                            : 'opacity-0',
                                    )}
                                />
                                {option.label}
                            </CommandItem>
                        ))}
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
