import { useEffect, useState } from 'react';
import useGetGalleries from './hooks/useGetGalleries';
import ProcessingList from '../../Processing/components/ProcessingList';
import PageWithTable from '@/src/Common/components/PageWithTable';
import { Link } from 'react-router-dom';
import Loading from '@/src/Common/components/Loading';

interface GalleryListProps {
    activationId: number;
}

interface GalleryObject {
    id: number;
    name: string;
    isPrivate: boolean;
    totalViews: number;
    uniqueViews: number;
}

export default function Processing({ activationId }: GalleryListProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [galleries, setGalleries] = useState<GalleryObject[]>([]);
    const { getGalleriesForActivation } = useGetGalleries();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const galleries = await getGalleriesForActivation(activationId);
                setGalleries(galleries);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    let gallery = galleries[0];

    let actions = (
        <>
            <Link to={`gallery/${gallery.id}/processing/create`}>
                <span className="Primary-button p-2">
                    <i className="bi bi-plus"></i>
                    Add Flow
                </span>
            </Link>
        </>
    );

    return (
        <PageWithTable title="Processing Flows" actions={actions}>
            <ProcessingList galleryId={gallery.id} galleryName={gallery.name} />
        </PageWithTable>
    );
}
