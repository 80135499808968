import { Suspense, useEffect } from 'react';
import { useImage } from 'react-image';
import { ErrorBoundary } from 'react-error-boundary';
import VideoPlayer from './VideoPlayer';

interface GalleryImageProps {
    media: MediaInstance;
    galleryId?: number;
}

interface ErrorFallbackProps {
    error: any;
    resetErrorBoundary: any;
}

const VIDEO_MIME_TYPE = 'video/mp4';

function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
    useEffect(() => {
        setTimeout(resetErrorBoundary, 100);
    });

    return <div />;
}

function ImageWrapper({ media }: GalleryImageProps) {
    const { src } = useImage({
        srcList: media.originalUri,
    });

    return <img src={src} loading="lazy" alt="" draggable={false} />;
}

function MediaWrapper(props: GalleryImageProps) {
    const { media } = props;

    if (media.mimeType === VIDEO_MIME_TYPE) {
        return (
            <div>
                <VideoPlayer url={media.originalUri} showControls={true} />
            </div>
        );
    }

    return <ImageWrapper {...props} />;
}

export default function GalleryImage(props: GalleryImageProps) {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
            <Suspense>
                <div className={`Gallery-cell`}>
                    <MediaWrapper {...props} />
                </div>
            </Suspense>
        </ErrorBoundary>
    );
}
