import { useState, useLayoutEffect, useEffect, useRef } from 'react';
import api from '../../../../services/api';

export default function useAnimatedCollageWall(activationId: number) {
    const smiles = useRef<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(0);
    const [displayedPhotos, setDisplayedPhotos] = useState<any[]>([]);

    useEffect(() => {
        const getSmiles = async () => {
            setLoading(true);

            try {
                const smileData = await api.get(
                    `https://photostat-api.smilebooth.com/public/activations/${activationId}/photos`,
                );

                smiles.current = smileData.data;
            } catch (error) {}

            setLoading(false);
        };

        getSmiles();
        let timer = setInterval(() => {
            getSmiles();
        }, 10000);

        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        const timer = setInterval(() => {
            setIndex((index) => {
                return index + 1;
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useLayoutEffect(() => {
        if (smiles.current.length > 0) {
            const x = Math.random() * window.innerWidth;
            const y = Math.random() * window.innerHeight;
            setDisplayedPhotos([
                ...displayedPhotos,
                { photo: smiles.current[index % smiles.current.length], x, y },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    return {
        displayedPhotos,
        loading,
    };
}
