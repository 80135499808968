import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
    ${({ theme, isLoading }: { theme: any; isLoading: boolean }) => css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
        transition-delay: ${isLoading ? '0s' : '0.3s'};
        opacity: ${isLoading ? '1' : '0'};
        pointer-events: ${isLoading ? 'initial' : 'none'};
    `}
`;

const Background = styled.div`
    ${({ theme }: { theme: any }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    `}
`;

const ImageContainer = styled.div`
    ${({ isLoading }: { isLoading: boolean }) => css`
        display: flex;
        background-color: white;
        position: relative;
        z-index: 20;
        justify-content: center;
        align-items: center;
        width: 125px;
        height: 125px;
        padding: 15px;
        border-radius: 75px;
        transition: 0.6s transform, 0.3s opacity;
        opacity: ${isLoading ? '1' : '0'};
        transform: ${isLoading ? 'none' : 'rotateX(-180deg) scale(0.5)'};
    `}
`;

export default function Loading({
    isLoading,
    showImmediate = false,
}: {
    isLoading: boolean;
    showImmediate?: boolean;
}) {
    const [delayHasPassed, setDelayHasPassed] = useState(showImmediate);

    useEffect(() => {
        let isMounted = true;

        setTimeout(() => isMounted && setDelayHasPassed(true), 250);

        return () => {
            isMounted = false;
        };
    }, []);

    if (!delayHasPassed) {
        return null;
    }

    return (
        <Wrapper isLoading={isLoading} className="Loading-wrapper">
            <Background />
            <ImageContainer isLoading={isLoading}>
                <img src="/loading.gif" alt="Loading..." />
            </ImageContainer>
        </Wrapper>
    );
}
