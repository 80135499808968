import '../Gallery.css';

import './Collage.css';
import useCollageWall from './hooks/useCollageWall';
import { useParams } from 'react-router-dom';

export function CollageWall() {
    const { activationId } = useParams();
    const { smiles } = useCollageWall(activationId ? +activationId : 0);

    return (
        <div className="collage-container">
            <div>
                <section id="photos">
                    {smiles.map((smile, index) => (
                        <img
                            src={
                                smile.thumbnail_800pxUri
                                    ? smile.thumbnail_800pxUri
                                    : smile.originalUri
                            }
                            alt="smile"
                            id={smile.shareIdentifier}
                            key={smile.shareIdentifier}
                        />
                    ))}
                </section>
            </div>

            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
            ></meta>
        </div>
    );
}
