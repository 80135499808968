import { NavLink } from 'react-router-dom';
import Table, { createColumnHelper } from '../../Common/components/Table';
import useUserOrg from '../../Authentication/components/hooks/useUserOrg';
import { useDeviceGroupList } from './hooks/useDeviceGroupList';
import { Button } from '@/src/shadComponents/ui/button';
import Loading from '@/src/Common/components/Loading';

const columnHelper = createColumnHelper<DeviceGroupWithKeys>();

export default function DeviceGroups() {
    const { orgId } = useUserOrg();
    const { deviceGroups, isLoading } = useDeviceGroupList(orgId);

    const columnDefinitions = [
        columnHelper.accessor('name', {
            id: 'name',
            header: () => <span>Name</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.display({
            id: 'deviceCount',
            header: () => <span>Devices</span>,
            cell: (props: { row: { original: DeviceGroupWithKeys } }) => (
                <span>
                    <NavLink
                        to={`device-groups/${props.row.original.id}/device-keys`}
                    >
                        {props.row.original.devices.length}
                    </NavLink>
                </span>
            ),
        }),
        columnHelper.display({
            id: 'unclaimedInviteCount',
            header: () => <span>Invited</span>,
            cell: (props: { row: { original: DeviceGroupWithKeys } }) => (
                <span>
                    <NavLink
                        to={`device-groups/${props.row.original.id}/invites`}
                    >
                        {props.row.original.invites.length}
                    </NavLink>
                </span>
            ),
        }),
        columnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => null,
        }),
    ];

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div>
            <div className="row flex mx-8">
                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                    Groups
                </div>
                <div className="spacer"></div>
                <NavLink to="device-groups/create">
                    <Button className="Primary-button">
                        + Create Device Group
                    </Button>
                </NavLink>
                <div className="w-2"></div>
                <NavLink to="device-invites/create">
                    <Button className="Primary-button">+ Invite Device</Button>
                </NavLink>
            </div>
            <br />
            <br />
            <Table data={deviceGroups} columns={columnDefinitions} />
        </div>
    );
}
