import PageWithSidebar from '@/src/Common/components/PageWithSidebar';
import { Cog6ToothIcon, LinkIcon } from '@heroicons/react/20/solid';
import { Tabs, TabsContent } from '@radix-ui/react-tabs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PicTime from './PicTime';

export default function OrgSettings() {
    const navigate = useNavigate();
    let splitPath = window.location.href.split('#');
    let selectedTab = splitPath.length > 1 ? splitPath[1] : 'details';

    const actions = (
        <>
            <span
                onClick={() => navigate(`/org/edit`)}
                className="ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
            >
                Edit Details
            </span>
        </>
    );

    return (
        <PageWithSidebar
            title="Smilebooth"
            subtitle={'Organization'}
            actions={actions}
            navigation={[
                {
                    name: 'Details',
                    href: '#details',
                    current: selectedTab === 'details',
                    icon: Cog6ToothIcon,
                },
                {
                    name: 'Integrations',
                    href: '#integrations',
                    current: selectedTab === 'integrations',
                    icon: LinkIcon,
                },
            ]}
        >
            <Tabs value={`${selectedTab}`}>
                <TabsContent value="details">
                    <div className="space-y-16 xl:space-y-20">
                        <h1>Details</h1>
                        <p>Organization details</p>
                    </div>
                </TabsContent>
                <TabsContent value="integrations">
                    <div className="row space-y-8 xl:space-y-20">
                        <div className="mt-1 text-xl font-semibold leading-6 text-gray-900 px-8">
                            Integrations
                        </div>
                        <PicTime />
                    </div>
                </TabsContent>
            </Tabs>
        </PageWithSidebar>
    );
}
