import { useState } from 'react';
import LoadingIndicator from '../../Common/components/LoadingIndicator';
import useProcessing from './hooks/useProcessing';
import { useFormik } from 'formik';
import { Button } from '@/src/shadComponents/ui/button';

export default function FlowTest({ flowDefinition, onClose }: any) {
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState('');
    const { testFlow } = useProcessing();
    console.log('Flow definition: ', flowDefinition);
    const formik = useFormik({
        initialValues: {
            asset: '',
            flow: flowDefinition ? JSON.stringify(flowDefinition) : '',
        },
        onSubmit: async (...args) => {
            setIsLoading(true);
            // @ts-ignore
            const response = await testFlow(...args);
            setUrl(response.url);
            setIsLoading(false);
        },
    });

    return (
        <div className="Asset-modal-container">
            <div className="modal">
                <form
                    className="Form test-form"
                    onSubmit={formik.handleSubmit}
                    encType="multpart/form-data"
                >
                    <div className="row">
                        <div className="form">
                            <div className="column">
                                <h2>test flow</h2>

                                <div className="Form-row">
                                    <label>file&nbsp;</label>

                                    <input
                                        type="file"
                                        name="asset"
                                        accept="*/*"
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'asset',
                                                e.currentTarget.files?.[0],
                                            );
                                        }}
                                    />
                                </div>
                                <input
                                    type="hidden"
                                    name="flow"
                                    value={JSON.stringify(flowDefinition)}
                                />
                                <div className="spacer" />
                            </div>
                        </div>
                        <div className="preview">
                            {isLoading ? (
                                <LoadingIndicator />
                            ) : url ? (
                                <img
                                    src={url}
                                    alt="preview"
                                    className="max-h-[200 px]"
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="spacer" />
                    <div className="row bottom-row">
                        <div className="spacer" />
                        <Button type="submit" className="Primary-button">
                            upload &amp; test
                        </Button>
                        <Button onClick={onClose} className="Secondary-button">
                            close
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
