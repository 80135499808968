import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useCreateAccount() {
    const authenticatedApi = useAuthenticatedApi();

    const createAccount = async (userObject: any): Promise<any> => {
        try {
            const response = await authenticatedApi.post(
                '/accounts/create',
                JSON.stringify(userObject),
            );
            return response;
        } catch (error) {
            // @ts-ignore
            return {
                success: false,

                // @ts-ignore
                message: error?.response?.data?.message ?? 'An error occurred',
            };
        }
    };

    return {
        createAccount,
    };
}
