import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';

export default function useOrgs() {
    const authenticatedApi = useAuthenticatedApi();

    const listOrgs = async () => {
        const response = await authenticatedApi.get('/orgs');
        return response.data;
    };

    const getOrg = async (orgId: number) => {
        const response = await authenticatedApi.get(`/orgs/${orgId}`);
        return response.data;
    };

    const createOrg = async (org: any) => {
        const response = await authenticatedApi.post(
            '/orgs',
            JSON.stringify(org),
        );
        return response.data;
    };

    return {
        listOrgs,
        createOrg,
        getOrg,
    };
}
