import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import useUserOrg from '../../Authentication/components/hooks/useUserOrg';
import { useDeviceGroup } from './hooks/useDeviceGroup';

interface CreateDeviceKeyFormValues {
    name: string;
    orgId: number;
}

export default function CreateDeviceGroup() {
    const { orgId } = useUserOrg();
    const navigate = useNavigate();
    const { createDeviceGroup } = useDeviceGroup(orgId);
    const initialValues: CreateDeviceKeyFormValues = {
        name: '',
        orgId,
    };

    return (
        <div className="">
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        Create
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    Add Device Group
                                </div>
                                <div className="mt-1 text-sm leading-6 font-light text-gray-500">
                                    Device groups are used to link multiple
                                    devices to an activation.
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>

            <Formik
                initialValues={initialValues}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await createDeviceGroup(values);
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate(-1);
                    }
                }}
            >
                {(props) => (
                    <Form
                        className="mt-6"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div className="space-y-12">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10  pb-12 md:grid-cols-3">
                                <div className="px-8">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                        Device Group Details
                                    </h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Enter the name of the device group you
                                        want to create.
                                    </p>
                                </div>
                                <div>
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Group Name
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <Field
                                                id="name"
                                                name="name"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                            <button
                                onClick={() => navigate(-1)}
                                className="text-sm font-semibold leading-6 text-gray-900"
                                type="button"
                            >
                                <span>Cancel</span>
                            </button>

                            <button
                                type="submit"
                                disabled={props.isSubmitting}
                                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                            >
                                Create Device Group
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
