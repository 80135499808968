import { Suspense, useCallback, useContext, useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import placeholder from './placeholder.jpg';
import VideoPlayer from './VideoPlayer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import React from 'react';
import usePhotoViewLogger from '../../Stats/components/hooks/usePhotoViewLogger';
import DownloadButton from '../../Share/components/DownloadButton';
import GalleryShareContext from './GalleryShareContext';
import { Skeleton } from '@/src/shadComponents/ui/skeleton';

interface GalleryPreviewImageProps {
    media: MediaInstance;
    isExpanded?: boolean;
    galleryInfo?: any;
    index?: number;
    setExpandedIndex?: (index: any) => void;
}

interface ErrorFallbackProps {
    error: any;
    resetErrorBoundary: any;
}

const VIDEO_MIME_TYPE = 'video/mp4';

function ErrorFallback({ resetErrorBoundary }: ErrorFallbackProps) {
    useEffect(() => {
        setTimeout(resetErrorBoundary, 100);
    });

    return <PlaceholderImage />;
}

function ImageWrapper({ media, isExpanded }: GalleryPreviewImageProps) {
    return (
        <LazyLoadImage
            src={
                isExpanded
                    ? media.originalUri
                    : media.thumbnail_800pxUri
                    ? media.thumbnail_800pxUri
                    : media.originalUri
            }
            loading="lazy"
            alt=""
            placeholder={
                <div className="flex items-center space-x-4 w-[100%] rounded-md">
                    <Skeleton className="h-[120px] w-[100%]" />
                </div>
            }
            draggable={false}
            effect="opacity"
            className="rounded-[0px]"
        />
    );
}

const GifWrapper = React.memo(({ url }: { url: string }) => {
    return (
        <div className="Gif-frame">
            <img src={url} alt="" draggable={false} />
            <div className="button-container">
                <i
                    className="bi bi-play"
                    style={{ filter: 'drop-shadow(0px 0px 5px #222)' }}
                />
            </div>
        </div>
    );
});

export function MediaWrapper(props: GalleryPreviewImageProps) {
    const { media, isExpanded } = props;
    const { originalUri, thumbnailUri, mimeType } = media;

    let component = null;
    if (mimeType === VIDEO_MIME_TYPE && isExpanded) {
        component = <VideoPlayer url={originalUri} showControls={false} />;
    } else if (mimeType === VIDEO_MIME_TYPE) {
        component = <GifWrapper url={thumbnailUri} />;
    } else {
        component = <ImageWrapper {...props} />;
    }

    return (
        <div className="Media-container">
            {component}
            <div className="click-blocker" />
        </div>
    );
}

function PlaceholderImage() {
    return <img src={placeholder} alt="" />;
}

function VisibleImage(props: GalleryPreviewImageProps) {
    const delayed = useRef(false);

    useEffect(() => {
        setTimeout(() => (delayed.current = true), 100);
    }, []);

    return <MediaWrapper {...props} />;
}

export default function GalleryPreviewImage(props: GalleryPreviewImageProps) {
    const { isExpanded, media, setExpandedIndex, index } = props;

    const galleryShareContext = useContext(GalleryShareContext);
    const onClick = useCallback(() => {
        if (setExpandedIndex && index !== undefined) {
            const newIndex = isExpanded ? -1 : index;
            setExpandedIndex(newIndex);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setExpandedIndex, isExpanded, index]);

    const { mimeType } = media;
    const { logPhotoView } = usePhotoViewLogger();
    const viewSent = useRef(false);

    useEffect(() => {
        if (isExpanded && !viewSent.current) {
            const { activationId, id } = media;
            logPhotoView(activationId, id);
            viewSent.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded]);

    const isImage =
        mimeType?.toLowerCase() === 'image/jpeg' ||
        mimeType?.toLowerCase() === 'image/jpg';

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
            <Suspense>
                <div
                    className={`${!isExpanded ? '' : 'expanded-view'}`}
                    onClick={() => !isExpanded && onClick()}
                >
                    <div
                        className={`Gallery-cell${
                            isExpanded ? ' expanded' : ''
                        }${isImage ? ' image' : ''}`}
                    >
                        {isExpanded ? (
                            <div className="Close-lightbox" onClick={onClick}>
                                <i className="bi bi-x"></i>
                            </div>
                        ) : null}
                        <VisibleImage {...props} isExpanded={isExpanded} />
                        {isExpanded && (
                            <div
                                className={`options ${
                                    isImage ? '' : 'video'
                                } z-100`}
                            >
                                <DownloadButton
                                    uri={media.originalUri}
                                    mimeType={media.mimeType}
                                    mediaId={media.id}
                                    source="gallery"
                                />
                                &nbsp; &nbsp;
                                {galleryShareContext && (
                                    <i
                                        onClick={() => {
                                            galleryShareContext.setShowShareModal(
                                                media.shareIdentifier,
                                            );
                                        }}
                                        className="bi bi-send save-button"
                                    ></i>
                                )}
                                <div className="spacer" />
                            </div>
                        )}
                    </div>
                </div>
            </Suspense>
        </ErrorBoundary>
    );
}
