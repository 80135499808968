import React from 'react';
import usePicTime from './hooks/usePicTime';

export default function PicTime() {
    const { isLinked, unlinkAccount, accountName } = usePicTime();
    return (
        <div className="px-8">
            <div className="mt-1 text-xl font-semibold leading-6 text-gray-900 mb-2">
                <span className="text-gray-900">Pic-Time</span>
            </div>
            <div>
                <p>
                    Pic-Time is a professional photo gallery platform that
                    allows you to create and share your photo galleries with
                    clients. Enabling this integration will allow you to link
                    your Pic-Time account to your Photo Engineers account
                    allowing for automatic gallery synchronization.
                </p>
                <p className="text-sm mt-0.5 p-6 flex row">
                    <span className="flex-1"></span>
                    {isLinked ? (
                        <>
                            <span className="text-sm text-gray-500 center p-2">
                                Linked to: {accountName}
                            </span>
                            <button
                                className="ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                                onClick={unlinkAccount}
                            >
                                Unlink Account
                            </button>
                        </>
                    ) : (
                        <a
                            href={`https://smileboothtest.pic-time.com/oauth?responseType=accessToken&appId=AAAAAI8AAAC5kp6fo0ayQ4MaDSqN_Rt1dHgq&redirectUrl=${encodeURIComponent(
                                'https://photostat.smilebooth.com/integrations/pic-time/redirect',
                            )}`}
                            className="ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                        >
                            Link your Pic-Time account &rarr;
                        </a>
                    )}
                </p>
            </div>
        </div>
    );
}
