import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import useRerender from '../../Common/components/hooks/useRerender';
import Table, { createColumnHelper } from '../../Common/components/Table';
import AccountActions from './AccountActions';
import { Button } from '@/src/shadComponents/ui/button';
import useAccounts from './hooks/useAccounts';

type Account = {
    username: string;
    email: string;
    id: number;
    firstName: string;
    lastName: string;
};

const columnHelper = createColumnHelper<Account>();

export default function Accounts() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const { getAccounts } = useAccounts();

    const { render, renderCount } = useRerender();

    const onDelete = () => {
        render();
    };

    const columnDefinitions = [
        columnHelper.accessor('username', {
            id: 'username',
            header: () => <span>username</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => <span>email</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.accessor('firstName', {
            id: 'firstName',
            header: () => <span>first name</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.accessor('lastName', {
            id: 'lastName',
            header: () => <span>last name</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <AccountActions onDelete={onDelete} row={props.row} />
            ),
        }),
    ];

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const response = await getAccounts();
                // @ts-ignore
                setAccounts(response);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCount]);

    if (isLoading) {
        return <div>Loading</div>;
    }

    return (
        <div>
            <h1>accounts</h1>
            <NavLink to="create">
                <Button className="Primary-button"> + create account</Button>
            </NavLink>
            <br />
            <br />
            <Table data={accounts} columns={columnDefinitions} />
        </div>
    );
}
