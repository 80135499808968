import useKeyPress from 'react-use-keypress';
import { useSwipeable } from 'react-swipeable';

export default function useGalleryBehaviors(
    setExpandedIndex: (index: any) => void,
    advance: () => void,
    reverse: () => void,
) {
    useKeyPress(['ArrowLeft', 'ArrowRight', 'Escape'], (event: any) => {
        if (event.key === 'ArrowLeft') {
            reverse();
        } else if (event.key === 'ArrowRight') {
            advance();
        } else {
            setExpandedIndex(-1);
        }
    });

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            advance();
        },
        onSwipedRight: (eventData) => {
            reverse();
        },
        preventScrollOnSwipe: true,
        trackMouse: true,
        swipeDuration: 1000,
    });

    return {
        swipeHandlers,
    };
}
