import Loading from '@/src/Common/components/Loading';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react';
import useActivations from './hooks/useActivations';
import { format } from 'date-fns';

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

const statuses: any = {
    Today: 'text-green-700 bg-green-50 ring-green-600/20',
    'This Week': 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    'Next Week': 'text-gray-600 bg-gray-50 ring-gray-500/10',
};

export type JobsFilter = 'all' | 'next30' | 'followUp' | 'previous30';

interface ActivationsListProps {
    filter?: JobsFilter;
    search?: string;
}

export default function CurrentActivations(
    { filter, search }: ActivationsListProps = { filter: 'next30', search: '' },
) {
    const [activations, setActivations] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [groupings, setGroupings] = useState<Groupings>({
        ...groupEvents([]),
    });
    const { getActivations } = useActivations();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const records = await getActivations();
                const groupings = groupEvents(records);
                console.log(groupings);
                isMounted && setGroupings(groupings);
                setTimeout(() => isMounted && setIsLoading(false), 0);
            } catch (error) {
                console.error(error);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const recordsByDateRange = [];
        let past = groupings.past;
        let active = groupings.active;
        let thisWeek = groupings.thisWeek;
        let nextWeek = groupings.nextWeek;
        let nextMonth = groupings.nextMonth;
        let greaterThanNextMonth = groupings.greaterThanNextMonth;

        if (filter === 'next30') {
            recordsByDateRange.push(
                {
                    date: 'Today',
                    activations: active,
                },
                {
                    date: 'This Week',
                    activations: thisWeek,
                },
                {
                    date: 'Next Week',
                    activations: nextWeek,
                },
            );
        } else if (filter === 'previous30') {
            recordsByDateRange.push({
                date: 'Past',
                activations: past,
            });
        } else {
            recordsByDateRange.push(
                {
                    date: 'Today',
                    activations: active,
                },
                {
                    date: 'This Week',
                    activations: thisWeek,
                },
                {
                    date: 'Next Week',
                    activations: nextWeek,
                },
                {
                    date: 'Next Month',
                    activations: nextMonth,
                },
                {
                    date: 'Greater Than Next Month',
                    activations: greaterThanNextMonth,
                },
            );
        }

        setActivations(recordsByDateRange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, filter, search]);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <ul className="divide-y divide-gray-100">
            {activations.map((activationGroup: any) => {
                return activationGroup.activations.map(
                    (activation: Activation) => (
                        <li
                            key={activation.id}
                            className="flex items-center justify-between gap-x-6 py-5"
                        >
                            <div className="min-w-0">
                                <div className="flex items-start gap-x-3">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">
                                        {activation.name}
                                    </p>
                                    <p
                                        className={classNames(
                                            statuses[activationGroup.date],
                                            'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                                        )}
                                    >
                                        {activationGroup.date}
                                    </p>
                                </div>
                                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                    <p className="whitespace-nowrap">
                                        <time dateTime={activation.date}>
                                            {format(
                                                new Date(activation.date),
                                                'MMM d',
                                            )}
                                        </time>{' '}
                                        in {activation.location}
                                    </p>
                                    <svg
                                        viewBox="0 0 2 2"
                                        className="h-0.5 w-0.5 fill-current"
                                    >
                                        <circle cx={1} cy={1} r={1} />
                                    </svg>
                                    <p className="truncate">
                                        Created by {activation.owner}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-none items-center gap-x-4">
                                <a
                                    href={`/activations/${activation.id}`}
                                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                >
                                    View activation
                                    <span className="sr-only">
                                        , {activation.name}
                                    </span>
                                </a>
                                <Menu as="div" className="relative flex-none">
                                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                        <span className="sr-only">
                                            Open options
                                        </span>
                                        <EllipsisVerticalIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={`/activations/${activation.id}`}
                                                        className={classNames(
                                                            active
                                                                ? 'bg-gray-50'
                                                                : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                        )}
                                                    >
                                                        Edit
                                                        <span className="sr-only">
                                                            , {activation.name}
                                                        </span>
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </li>
                    ),
                );
            })}
        </ul>
    );
}

type ActivationList = Activation[];
type Groupings = {
    past: ActivationList;
    active: ActivationList;
    thisWeek: ActivationList;
    nextWeek: ActivationList;
    nextMonth: ActivationList;
    greaterThanNextMonth: ActivationList;
};

function groupEvents(events: any[]): Groupings {
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const endOfToday = new Date(today.setHours(23, 59, 59, 999));
    const endOfThisWeek = new Date(
        endOfToday.getTime() + (6 - endOfToday.getDay()) * oneDay,
    );
    const endOfNextWeek = new Date(endOfThisWeek.getTime() + 7 * oneDay);
    const endOfNextMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 2,
        0,
    );

    const groupings: Groupings = {
        past: [],
        active: [],
        thisWeek: [],
        nextWeek: [],
        nextMonth: [],
        greaterThanNextMonth: [],
    };

    events.forEach((event) => {
        const startDate = new Date(event.date);
        if (startDate < startOfToday) {
            groupings.past.push(event);
        } else if (startDate < endOfToday) {
            groupings.active.push(event);
        } else if (startDate < endOfThisWeek) {
            groupings.thisWeek.push(event);
        } else if (startDate < endOfNextWeek) {
            groupings.nextWeek.push(event);
        } else if (startDate < endOfNextMonth) {
            groupings.nextMonth.push(event);
        } else {
            groupings.greaterThanNextMonth.push(event);
        }
    });

    return groupings;
}
