import { useParams } from 'react-router-dom';
import Loading from '../../Common/components/Loading';
import './Gallery.css';
import GalleryPreviewImage from './GalleryPreviewImage';
import fullLogo from './white_logo.png';
import usePersonalGalleryInteractions from './hooks/usePersonalGalleryInteractions';
import { Masonry } from 'masonic';

export default function PersonalGallery() {
    const { slug } = useParams();
    const {
        gallery,
        isLoading,
        delayed,
        expandedIndex,
        setExpandedIndex,
        swipeHandlers,
    } = usePersonalGalleryInteractions(slug || '');

    if (!gallery && !isLoading) {
        return (
            <div>
                <h3>gallery not found...</h3>
            </div>
        );
    }

    const GalleryPreviewImageWrapper = ({
        data,
        index,
    }: {
        data: MediaInstance;
        index: number;
    }) => {
        return (
            <GalleryPreviewImage
                media={data}
                setExpandedIndex={setExpandedIndex}
                index={index}
                isExpanded={expandedIndex === index}
            />
        );
    };

    return (
        <div className="Gallery-view">
            <div>
                <Loading isLoading={isLoading} />
                <div
                    className={`Gallery-header ${
                        expandedIndex > -1 ? 'hidden' : ''
                    }`}
                >
                    <div className="Gallery-header-title">
                        <img src={fullLogo} alt="Smilebooth Logo" />
                    </div>
                    <div className="Find-your-own-photos"></div>
                    <div className="Gallery-name"></div>
                </div>
                <div
                    className="Gallery-content pb-[20px]"
                    id="galleryContainer"
                >
                    <div className="Gallery-grid-container">
                        <div
                            {...swipeHandlers}
                            className={`Gallery-grid ${delayed ? '' : 'hide'}`}
                        >
                            <Masonry
                                items={gallery}
                                render={GalleryPreviewImageWrapper}
                                columnGutter={12}
                                rowGutter={12}
                                maxColumnCount={5}
                                columnWidth={
                                    gallery.length > 3
                                        ? 400
                                        : gallery.length > 1
                                        ? 784
                                        : 1080
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
