import { Suspense, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import placeholder from '../../Gallery/components/placeholder.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import React from 'react';
import VideoPlayer from '../../Gallery/components/VideoPlayer';
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from '@/src/shadComponents/ui/hover-card';

interface GalleryPreviewImageProps {
    media: MediaInstance;
    isExpanded?: boolean;
    onClick?: () => void;
    onReplace?: (mediaId: number, onChange: (uri: string) => void) => void;
    onHide?: (mediaId: number, isHidden: boolean) => Promise<boolean>;
    onFavorite?: (mediaId: number, isFavorite: boolean) => Promise<boolean>;
}

interface ErrorFallbackProps {
    error: any;
    resetErrorBoundary: any;
}

const VIDEO_MIME_TYPE = 'video/mp4';

function ErrorFallback({ resetErrorBoundary }: ErrorFallbackProps) {
    useEffect(() => {
        setTimeout(resetErrorBoundary, 100);
    });

    return <PlaceholderImage />;
}

function ImageWrapper({ media, isExpanded }: GalleryPreviewImageProps) {
    return (
        <LazyLoadImage
            src={
                isExpanded
                    ? media.originalUri
                    : media.thumbnail_800pxUri
                    ? media.thumbnail_800pxUri
                    : media.originalUri
            }
            loading="lazy"
            alt=""
            draggable={false}
            effect="opacity"
        />
    );
}

const GifWrapper = React.memo(({ url }: { url: string }) => {
    return (
        <div className="Gif-frame">
            <img src={url} alt="" draggable={false} />
            <div className="button-container">
                <i className="bi bi-play" />
            </div>
        </div>
    );
});

function MediaWrapper(props: GalleryPreviewImageProps) {
    const { media, isExpanded, onHide, onFavorite, onReplace } = props;
    const { originalUri, thumbnailUri, mimeType } = media;

    const [photoUri, setPhotoUri] = useState(originalUri);

    const [isHidden, setIsHidden] = useState(media.isHidden);
    const [isHiding, setIsHiding] = useState(false);

    const [isFavorite, setIsFavorite] = useState(media.isFavorite);
    const [isFavoriting, setIsFavoriting] = useState(false);

    let component = null;
    if (mimeType === VIDEO_MIME_TYPE && isExpanded) {
        component = <VideoPlayer url={photoUri} showControls={false} />;
    } else if (mimeType === VIDEO_MIME_TYPE) {
        component = <GifWrapper url={thumbnailUri} />;
    } else {
        component = <ImageWrapper {...props} />;
    }

    return !isExpanded ? (
        <HoverCard>
            <HoverCardTrigger>
                <div
                    className={
                        isHidden
                            ? 'opacity-20 transition-opacity'
                            : 'transition-opacity'
                    }
                >
                    {component}
                </div>
                <div className="click-blocker" />
            </HoverCardTrigger>
            <HoverCardContent>
                <span className="text-xs">
                    Share ID:
                    <br /> {media.shareIdentifier}
                </span>
                <div className="flex row">
                    <div
                        onClick={async (event: any) => {
                            if (!onFavorite) {
                                return;
                            }
                            setTimeout(() => setIsFavoriting(true), 0);
                            event.stopPropagation();
                            event.preventDefault();

                            const newState = await onFavorite(
                                media.id,
                                isFavorite,
                            );
                            setIsFavorite(newState);
                            setTimeout(() => setIsFavoriting(false), 0);
                        }}
                    >
                        <i
                            className={`bi ${
                                isFavoriting
                                    ? 'bi-hourglass'
                                    : !isFavorite
                                    ? 'bi-heart'
                                    : 'bi-heart-fill'
                            } clickable`}
                        />
                    </div>
                    &nbsp; &nbsp;
                    <div
                        onClick={async (event: any) => {
                            if (!onReplace) {
                                return;
                            }
                            event.stopPropagation();
                            event.preventDefault();

                            onReplace(media.id, (uri) => {
                                setPhotoUri(uri);
                            });
                        }}
                    >
                        <i className={`bi bi-pencil clickable`} />
                    </div>
                    &nbsp; &nbsp;
                    <div
                        onClick={async (event: any) => {
                            if (!onHide) {
                                return;
                            }
                            setTimeout(() => setIsHiding(true), 0);
                            event.stopPropagation();
                            event.preventDefault();

                            const newState = await onHide(media.id, isHidden);
                            setIsHidden(newState);
                            setTimeout(() => setIsHiding(false), 0);
                        }}
                    >
                        <i
                            className={`bi ${
                                isHiding
                                    ? 'bi-hourglass'
                                    : !isHidden
                                    ? 'bi-eye'
                                    : 'bi-eye-slash'
                            } clickable`}
                        />
                    </div>
                </div>
            </HoverCardContent>
        </HoverCard>
    ) : (
        <div
            className={`
                 fixed inset-0 bg-white bg-opacity-80 flex flex-col justify-center items-center
                ${
                    isHidden
                        ? 'opacity-20 transition-opacity'
                        : 'transition-opacity'
                }`}
        >
            <span className="text-md">
                Share ID:
                <span>{media.shareIdentifier}</span>
            </span>
            {component}
            <div className="flex row">
                <div
                    onClick={async (event: any) => {
                        if (!onFavorite) {
                            return;
                        }
                        setTimeout(() => setIsFavoriting(true), 0);
                        event.stopPropagation();
                        event.preventDefault();

                        const newState = await onFavorite(media.id, isFavorite);
                        setIsFavorite(newState);
                        setTimeout(() => setIsFavoriting(false), 0);
                    }}
                >
                    <i
                        className={`bi ${
                            isFavoriting
                                ? 'bi-hourglass'
                                : !isFavorite
                                ? 'bi-heart'
                                : 'bi-heart-fill'
                        } clickable`}
                    />
                </div>
                &nbsp; &nbsp;
                <div
                    onClick={async (event: any) => {
                        if (!onReplace) {
                            return;
                        }
                        event.stopPropagation();
                        event.preventDefault();

                        onReplace(media.id, (uri) => {
                            setPhotoUri(uri);
                        });
                    }}
                >
                    <i className={`bi bi-pencil clickable`} />
                </div>
                &nbsp; &nbsp;
                <div
                    onClick={async (event: any) => {
                        if (!onHide) {
                            return;
                        }
                        setTimeout(() => setIsHiding(true), 0);
                        event.stopPropagation();
                        event.preventDefault();

                        const newState = await onHide(media.id, isHidden);
                        setIsHidden(newState);
                        setTimeout(() => setIsHiding(false), 0);
                    }}
                >
                    <i
                        className={`bi ${
                            isHiding
                                ? 'bi-hourglass'
                                : !isHidden
                                ? 'bi-eye'
                                : 'bi-eye-slash'
                        } clickable`}
                    />
                </div>
            </div>
            <div className="click-blocker" />
        </div>
    );
}

function PlaceholderImage() {
    return <img src={placeholder} alt="" />;
}

function VisibleImage(props: GalleryPreviewImageProps) {
    const delayed = useRef(false);

    useEffect(() => {
        setTimeout(() => (delayed.current = true), 100);
    }, []);

    return <MediaWrapper {...props} />;
}

export default function NonTrackingGalleryImage(
    props: GalleryPreviewImageProps,
) {
    const { onClick, isExpanded, media } = props;
    const { mimeType } = media;

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
            <Suspense>
                <div
                    className={`Gallery-cell${isExpanded ? ' expanded' : ''}${
                        mimeType === 'image/jpeg' ? ' image' : ''
                    } centered`}
                    onClick={() => onClick && onClick()}
                >
                    <VisibleImage {...props} isExpanded={isExpanded} />
                </div>
            </Suspense>
        </ErrorBoundary>
    );
}
