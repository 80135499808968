import { useState, useMemo } from 'react';
import { useLocalStorage } from '../../../Common/components/hooks/useLocalStorage';
import useQueryString from '../../../Common/components/hooks/useQueryString';

export default function useAutoShare(galleryId: number) {
    const query = useQueryString();
    const shareDestinationKey = `sb-share-destination-${galleryId}`;
    const shareTypeKey = `sb-share-type-${galleryId}`;

    // eslint-disable-next-line eqeqeq
    if (galleryId == 0) {
        console.error('galleryId must be set');
    }

    const shareIdentifiers = useMemo(
        () =>
            query
                .getAll('shareIdentifier')
                .map((item) => item.split(','))
                .flat(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [galleryId],
    );

    const [shareDestination, setShareDestination] = useLocalStorage<string>(
        shareDestinationKey,
        '',
    );

    const [lastSharedIdentifier, setLastSharedIdentifier] =
        useLocalStorage<string>(`sb-last-shared-identifier`, '');

    const wasLastShared = lastSharedIdentifier === shareIdentifiers.toString();

    const [shareType, setShareType] = useLocalStorage<ShareType | null>(
        shareTypeKey,
        null,
    );

    const [autoShare, setAutoShare] = useState(
        !!shareDestination && shareType !== null,
    );

    const [isSharing, setIsSharing] = useState(false);

    return {
        isSharing,
        setIsSharing,
        autoShare,
        setAutoShare,
        shareType,
        setShareType,
        shareDestination,
        setShareDestination,
        setLastSharedIdentifier,
        wasLastShared,
        shareDestinationKey,
        shareTypeKey,
    };
}
