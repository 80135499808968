import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Loading from '../../Common/components/Loading';
import useGallery from '../../Gallery/components/hooks/useGallery';
import CreateShare from './CreateShare';
import useAutoShare from './hooks/useAutoShare';
import useCreateShare from './hooks/useCreateShare';
import useShareGallery from './hooks/useShareGallery';
import ShareGallery from './ShareGallery';
import useQueryString from '../../Common/components/hooks/useQueryString';
import AgeVerification from './AgeVerification';
import SharingSurvey, { SurveyAnswers } from './SharingSurvey';
import useShareSurvey from './hooks/useShareSurvey';
import GlobalStyle from '@/src/Share/components/GalleryTheme';

export default function ShareLanding() {
    const { galleryId } = useParams();
    const [galleryInfo, setGalleryInfo] = useState<GalleryObject | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const { gallery, isAllMediaInCloud, isLoadingMedia } = useShareGallery();
    const [showAgeVerificationScreen, setShowAgeVerificationScreen] =
        useState(false);

    const [showSurveyScreen, setShowSurveyScreen] = useState(true);
    const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswers | null>(
        null,
    );

    const { getGalleryById } = useGallery({
        galleryId: galleryId ? +galleryId : 0,
        slug: '',
    });

    const {
        shareDestination,
        setShareDestination,
        shareType,
        setShareType,
        autoShare,
        setAutoShare,
        isSharing,
        setIsSharing,
        setLastSharedIdentifier,
        wasLastShared,
    } = useAutoShare(galleryId ? +galleryId : 0);

    const [isSharingComplete, setIsSharingComplete] = useState(wasLastShared);
    const { saveSurveyAnswers } = useShareSurvey();

    const query = useQueryString();
    const shareIdentifiers = query
        .getAll('shareIdentifier')
        .map((item) => item.split(','))
        .flat();

    const onShare = (destination: string, identifier: string, id: number) => {
        setLastSharedIdentifier(identifier);
        setShareDestination(destination);
        setIsSharingComplete(true);
        setShowSurveyScreen(false);

        if (surveyAnswers && galleryInfo?.surveyQuestions) {
            // save answers
            console.log('Save Answers', surveyAnswers);
            saveSurveyAnswers(
                surveyAnswers,
                galleryInfo?.surveyQuestions,
                galleryInfo?.activationId,
                id,
            );
        }
    };

    const reset = () => {
        setShareDestination('');
        setShareType(null);
        setIsSharingComplete(false);
        setAutoShare(false);
        setLastSharedIdentifier('');
        setSurveyAnswers(null);
        setShowSurveyScreen(true);
    };

    const { personalGalleryLink } = useCreateShare(
        onShare,
        setIsSharing,
        shareDestination,
        galleryInfo,
        shareType,
        wasLastShared,
        shareIdentifiers,
    );

    useEffect(() => {
        let isMounted = true;

        const getGalleryInfo = async () => {
            try {
                const galleryInfo = await getGalleryById();
                isMounted && setGalleryInfo(galleryInfo);

                if (galleryInfo?.ageVerification) {
                    setShowAgeVerificationScreen(true);
                }

                if (galleryInfo?.surveyRequired) {
                    setShowSurveyScreen(true);
                }

                if (galleryInfo.emailEnabled && galleryInfo.textEnabled) {
                    return;
                }

                isMounted && setShareType(galleryInfo.emailEnabled ? 0 : 1);
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getGalleryInfo();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onVerified = useCallback(
        () => setShowAgeVerificationScreen(false),
        [],
    );

    const onSurveyComplete = useCallback((answers: SurveyAnswers) => {
        console.log('Setting answers', answers);
        setSurveyAnswers(answers);
    }, []);

    const galleryHasContent = gallery.length > 0;
    const showCreateShare = !isLoading && !isLoadingMedia && !galleryHasContent;
    const showShareGallery = !isLoading && !isLoadingMedia && !showCreateShare;
    const showSurveyFlow = showSurveyScreen && !autoShare;

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    if (showAgeVerificationScreen) {
        return (
            <AgeVerification
                onVerified={onVerified}
                age={galleryInfo?.verifiedAge ? galleryInfo.verifiedAge : null}
                galleryId={galleryId ? +galleryId : 0}
            />
        );
    }

    if (showSurveyFlow) {
        return (
            <>
                <GlobalStyle
                    foregroundColor={galleryInfo?.foregroundColor}
                    backgroundColor={galleryInfo?.backgroundColor}
                    headerBackgroundColor={galleryInfo?.headerBackgroundColor}
                    headerForegroundColor={galleryInfo?.headerForegroundColor}
                />
                <SharingSurvey
                    galleryId={galleryId ? +galleryId : 0}
                    survey={
                        galleryInfo?.surveyRequired
                            ? galleryInfo?.surveyQuestions || []
                            : []
                    }
                    onComplete={onSurveyComplete}
                >
                    <CreateShare
                        {...{
                            isSharing,
                            isSharingComplete,
                            shareType,
                            setShareType,
                            galleryInfo,
                            shareDestination,
                            onShare,
                            setAutoShare,
                            autoShare,
                            setIsSharing,
                            emailEnabled: galleryInfo?.emailEnabled,
                            textEnabled: galleryInfo?.textEnabled,
                            reset,
                            shareIdentifiers,
                        }}
                    />
                </SharingSurvey>
            </>
        );
    }

    return (
        <div>
            <GlobalStyle
                foregroundColor={galleryInfo?.foregroundColor}
                backgroundColor={galleryInfo?.backgroundColor}
                headerBackgroundColor={galleryInfo?.headerBackgroundColor}
                headerForegroundColor={galleryInfo?.headerForegroundColor}
            />
            <Loading isLoading={isLoadingMedia || isLoading || isSharing} />
            {showCreateShare ? (
                <CreateShare
                    {...{
                        isSharing,
                        isSharingComplete,
                        shareType,
                        setShareType,
                        galleryInfo,
                        shareDestination,
                        onShare,
                        setAutoShare,
                        autoShare,
                        setIsSharing,
                        emailEnabled: galleryInfo?.emailEnabled,
                        textEnabled: galleryInfo?.textEnabled,
                        reset,
                        shareIdentifiers,
                    }}
                />
            ) : null}
            {showShareGallery ? (
                <ShareGallery
                    galleryInfo={galleryInfo}
                    galleryPhotos={gallery}
                    isAllMediaInCloud={isAllMediaInCloud}
                    isSharingComplete={isSharingComplete}
                    personalGalleryLink={personalGalleryLink}
                    onReset={reset}
                >
                    <CreateShare
                        {...{
                            isSharing,
                            isSharingComplete,
                            shareType,
                            setShareType,
                            galleryInfo,
                            shareDestination,
                            onShare,
                            setAutoShare,
                            autoShare,
                            setIsSharing,
                            emailEnabled: galleryInfo?.emailEnabled,
                            textEnabled: galleryInfo?.textEnabled,
                            reset,
                            shareIdentifiers,
                        }}
                    />
                </ShareGallery>
            ) : null}
        </div>
    );
}
