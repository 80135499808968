import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ShareTypeSelectionProps {
    type: ShareType | null;
    emailEnabled: boolean;
    textEnabled: boolean;
    logo: string;
    onSelect: (type: ShareType) => void;
}

export default function ShareTypeSelection({
    type,
    emailEnabled,
    textEnabled,
    onSelect,
    logo,
}: ShareTypeSelectionProps) {
    return (
        <div className="Choices">
            <LazyLoadImage
                src={logo}
                alt="logo"
                loading="lazy"
                effect="opacity"
                style={{
                    objectFit: 'contain',
                    maxWidth: '300px',
                    marginBottom: '80px',
                }}
            />
            <div className="flex row">
                {textEnabled ? (
                    <div className="Choice" onClick={() => onSelect(1)}>
                        <i className="bi bi-phone bounce"></i>
                        <div>
                            <h4 className="thin-title">text</h4>
                        </div>
                    </div>
                ) : null}
                {emailEnabled ? (
                    <div className="Choice" onClick={() => onSelect(0)}>
                        <i className="bi bi-envelope-paper bounce"></i>
                        <div>
                            <h4 className="thin-title">email</h4>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
