import { useEffect } from 'react';
import { useLocalStorage } from '../../Common/components/hooks/useLocalStorage';

interface AgeVerificationProps {
    onVerified: () => void;
    age: number | null;
    galleryId: number;
}

export default function AgeVerification({
    galleryId,
    age,
    onVerified,
}: AgeVerificationProps) {
    const [ageVerified, setAgeVerified] = useLocalStorage<Boolean>(
        `sb-age-verified-${galleryId}-${age}`,
        false,
    );

    useEffect(() => {
        if (ageVerified) {
            onVerified();
        }
    }, [ageVerified, onVerified]);

    return (
        <div className="flex justify-center items-center">
            <div className="Share-container ">
                <div className="Share-prompt Share-body">
                    <div className="Share-form">
                        <h1>Are you {age}+?</h1>
                        <button
                            className="Link-button"
                            onClick={() => {
                                setAgeVerified(true);
                            }}
                        >
                            Yes
                        </button>
                        <br />
                        <span
                            className="Tertiary-button"
                            onClick={() => {
                                window.location.href = 'https://google.com';
                            }}
                        >
                            No
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
