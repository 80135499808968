import CaptureMetadataForm from './CaptureMetdataForm';

interface StageMetadataFormProps {
    screen: ScreenDefinition;
}

export default function StageMetadataForm({ screen }: StageMetadataFormProps) {
    const stageType = screen.stage.type;

    switch (stageType) {
        case 'capture':
            return <CaptureMetadataForm screen={screen} />;
        case 'processing':
            return null;
        //return <ProcessingMetadataForm screen={screen} />;
        default:
            return null;
    }
}
