import Loading from '../../../Common/components/Loading';
import useFavorites from './hooks/useFavorites';
import '../Gallery.css';
import GalleryView from '../GalleryView';
import GlobalStyle from '@/src/Share/components/GalleryTheme';

export function FavoritesGallery() {
    const {
        gallery,
        isLoading,
        photos,
        delayed,
        expandedIndex,
        setExpandedIndex,
        swipeHandlers,
        loadedPhotos,
        getNewPage,
    } = useFavorites();

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div className="Gallery-view">
            <GlobalStyle
                foregroundColor={gallery?.foregroundColor}
                backgroundColor={gallery?.backgroundColor}
                headerBackgroundColor={gallery?.headerBackgroundColor}
                headerForegroundColor={gallery?.headerForegroundColor}
            />
            <GalleryView
                {...{
                    gallery,
                    isLoading,
                    photos,
                    delayed,
                    expandedIndex,
                    setExpandedIndex,
                    swipeHandlers,
                    loadedPhotos,
                    getNewPage,
                    galleryId: gallery?.id || 0,
                }}
            />
        </div>
    );
}
