import { createColumnHelper } from '@tanstack/react-table';
import { useLocalStorage } from '../../Common/components/hooks/useLocalStorage';
import Table from '../../Common/components/Table';
import { Switch } from '@/src/shadComponents/ui/switch';
import NonTrackingGalleryImage from '@/src/Common/components/NonTrackingGalleryImage';

interface PhotoManagerListProps {
    shares: (ShareObject & MediaInstance)[];
}

const columnHelper = createColumnHelper<ShareObject & MediaInstance>();

export default function ShareList({ shares }: PhotoManagerListProps) {
    const [showPhotoPreview, setShowPhotoPreview] = useLocalStorage(
        'sb-pref-show-share-photo-preview',
        true,
    );
    const columnDefinitions = [
        columnHelper.accessor('originalUri', {
            id: 'originalUri',
            header: () => <span>photo</span>,
            enableColumnFilter: false,
            cell: (info) => (
                <div className="row">
                    <div className="image-preview">
                        {showPhotoPreview ? (
                            <div className="w-[100px]">
                                <NonTrackingGalleryImage
                                    media={info.row.original}
                                />
                            </div>
                        ) : (
                            <a
                                href={info.row.original.originalUri}
                                target="_blank"
                                rel="noreferrer"
                            >
                                [link]
                            </a>
                        )}
                    </div>
                </div>
            ),
        }),
        columnHelper.accessor('destination', {
            id: 'destination',
            filterFn: 'fuzzy',
            header: () => <span>destination</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('type', {
            id: 'type',
            header: () => <span>type</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('sent', {
            id: 'sent',
            header: () => <span>sent</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue() ? 'yes' : 'no'}</span>
                </div>
            ),
        }),
    ];

    return (
        <div>
            <span className="pl-7">
                <Switch
                    id="show-archived"
                    onCheckedChange={() =>
                        setShowPhotoPreview((preview: boolean) => !preview)
                    }
                    checked={showPhotoPreview}
                />
                <label htmlFor="show-archived" className="mx-2 text-sm">
                    Display Preview
                </label>
            </span>
            <Table
                data={shares}
                columns={columnDefinitions}
                enableSorting={true}
            />
        </div>
    );
}
