import { useCallback, useRef, useState } from 'react';
import {
    ProcessingEnvironment,
    ProcessingFlow,
    Processor,
} from './hooks/useProcessing';
import '../../Common/components/Form.css';
import '../FlowEditor.css';
import { useNavigate } from 'react-router-dom';
import AssetPicker from '../../UploadedAssets/components/AssetPicker';
import FlowTest from './FlowTest';
import useFlowEditor from './hooks/useFlowEditor';
import FlowStep from '../FlowStep';
import FlowTemplatePicker from './FlowTemplatePicker';
import { Button } from '@/src/shadComponents/ui/button';

interface FlowEditorProps {
    flow: ProcessingFlow;
    environments: ProcessingEnvironment[];
    processors: Processor[];
    onSave: (
        environmentId: number,
        enabled: boolean,
        definition: Processor[],
    ) => void;
}

export default function FlowEditor({
    flow,
    environments,
    processors,
    onSave,
}: FlowEditorProps) {
    const [environmentId, setEnvironmentId] = useState<number>(
        flow.environmentId,
    );

    const {
        definition,
        addStep,
        removeStep,
        moveStep,
        replaceFlow,
        updateStep,
    } = useFlowEditor(flow, processors);

    const flowDefinition = definition as unknown as Processor[];

    const [feedback, setFeedback] = useState<string>('');
    const [showAssetPicker, setShowAssetPicker] = useState<boolean>(false);
    const [showTestFlowModal, setShowTestFlowModal] = useState<boolean>(false);
    const [showTemplatePicker, setShowTemplatePicker] =
        useState<boolean>(false);
    const onAssetChosen = useRef<((value: string) => void) | null>(null);

    const navigate = useNavigate();

    const [isEnabled, setIsEnabled] = useState<boolean>(flow.enabled);
    const showAssetPickerCallback = useCallback(
        (handler: (value: string) => void) => {
            onAssetChosen.current = handler;
            setShowAssetPicker(true);
        },
        [],
    );

    const currentEnvironment =
        environments.find((env) => env.id === environmentId)?.environment || '';

    return (
        <div className="mb-24">
            <div className="Form-row">
                <div className="spacer" />
                <Button onClick={() => setShowTemplatePicker(true)}>
                    Presets
                </Button>
                <Button onClick={() => setShowAssetPicker(true)}>
                    Asset Picker
                </Button>
            </div>
            {feedback && <div>{feedback}</div>}
            <div className="form">
                <div className="Form-row mx-4">
                    <div>Environment: &nbsp;</div>
                    <select onChange={(e) => setEnvironmentId(+e.target.value)}>
                        <option value="">---</option>
                        {environments.map((environment) => {
                            return (
                                <option
                                    value={environment.id}
                                    key={environment.id}
                                    selected={environment.id === environmentId}
                                >
                                    {environment.environment}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="Form-row mb-6 mx-4">
                    <label htmlFor="enabled">Enabled&nbsp;</label>
                    <input
                        type="checkbox"
                        name="enabled"
                        checked={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                    />
                </div>
                {flowDefinition.map((step, index) => {
                    console.log(`flow-step-${index}-${step.name}`);
                    return (
                        <FlowStep
                            step={step}
                            stepNumber={index}
                            totalSteps={flowDefinition.length}
                            onDelete={removeStep}
                            key={`flow-step-${index}-${step.name}`}
                            moveStep={moveStep}
                            updateStep={updateStep}
                            onLaunchAssetPicker={showAssetPickerCallback}
                            currentEnvironment={currentEnvironment}
                        />
                    );
                })}
                <div className="Flow-step mx-4">
                    <i className="bi bi-plus" />
                    Add step&nbsp;
                    <select
                        key={`processors-${flowDefinition.length}`}
                        onChange={(e) => addStep(e.target.value)}
                    >
                        <option value="">Select a processor</option>
                        {processors.map((processor) => {
                            return (
                                <option
                                    value={processor.name}
                                    key={processor.name}
                                >
                                    {processor.title || processor.name}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                    {(currentEnvironment === 'cloud' ||
                        currentEnvironment === 'text') && (
                        <button
                            className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                            onClick={() => setShowTestFlowModal(true)}
                        >
                            Test
                        </button>
                    )}
                    <button
                        className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                        onClick={() => {
                            const fileName = 'flow-definition';
                            const json = JSON.stringify(definition, null, 2);
                            const blob = new Blob([json], {
                                type: 'application/json',
                            });
                            const href = URL.createObjectURL(blob);

                            const link = document.createElement('a');
                            link.href = href;
                            link.download = fileName + '.json';
                            document.body.appendChild(link);
                            link.click();

                            // clean up "a" element & remove ObjectURL
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }}
                    >
                        Export
                    </button>
                    <button
                        className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                        onClick={() => {
                            const input = document.createElement('input');
                            input.type = 'file';
                            input.accept = '.json';
                            input.onchange = (e) => {
                                const file = (e.target as HTMLInputElement)
                                    .files
                                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                      //@ts-ignore
                                      (e.target as HTMLInputElement).files[0]
                                    : null;
                                if (file) {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        const text = e.target?.result;
                                        if (typeof text === 'string') {
                                            const flow = JSON.parse(text);
                                            replaceFlow(flow);
                                        }
                                    };
                                    reader.readAsText(file);
                                }
                            };
                            input.click();
                        }}
                    >
                        Import
                    </button>

                    <div className="spacer" />
                    <button
                        className="text-sm font-semibold leading-6 text-gray-900"
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        <span>Cancel</span>
                    </button>
                    <button
                        className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                        type="submit"
                        onClick={() =>
                            onSave(environmentId, isEnabled, flowDefinition)
                        }
                    >
                        Save
                    </button>
                </div>
            </div>
            {showAssetPicker && (
                <AssetPicker
                    onAssetSelected={(asset) => {
                        navigator.clipboard.writeText(asset.url);
                        onAssetChosen.current &&
                            onAssetChosen.current(asset.url);

                        setShowAssetPicker(false);
                        setFeedback('Asset URL Copied to clipboard');
                        console.log('Asset', asset);

                        setTimeout(() => {
                            setFeedback('');
                        }, 5000);
                    }}
                    onClose={() => setShowAssetPicker(false)}
                />
            )}
            {showTestFlowModal && (
                <FlowTest
                    flowDefinition={definition}
                    onClose={() => setShowTestFlowModal(false)}
                />
            )}
            {showTemplatePicker && (
                <FlowTemplatePicker
                    onClose={() => setShowTemplatePicker(false)}
                    onSelect={(definition: Processor[]) => {
                        replaceFlow(definition);
                        setShowTemplatePicker(false);
                    }}
                />
            )}
        </div>
    );
}
