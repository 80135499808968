import { useState } from 'react';
import FlipNumbers from 'react-flip-numbers';

const MAX_YEAR = new Date().getFullYear();
const MIN_YEAR = 2015;

export default function YearSelector({
    currentYear,
    onChange,
}: {
    currentYear: number;
    onChange: (year: number) => void;
}) {
    const [year, setYear] = useState(currentYear);

    return (
        <div className="year-filter">
            <i
                className={`bi bi-chevron-compact-left clickable ${
                    year < MIN_YEAR ? 'hidden' : ''
                }`}
                onClick={() => {
                    setYear(year - 1);
                    onChange(year - 1);
                }}
            ></i>
            <span className="year-display">
                <FlipNumbers
                    height={20}
                    width={20}
                    play={true}
                    color="white"
                    numbers={year.toString()}
                />
            </span>
            <i
                className={`bi bi-chevron-compact-right clickable ${
                    year < MAX_YEAR ? '' : 'hidden'
                }`}
                onClick={() => {
                    setYear(year + 1);
                    onChange(year + 1);
                }}
            ></i>
        </div>
    );
}
