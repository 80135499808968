export default function HeadlessTable({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div className="space-y-16 xl:space-y-20">
            <div>
                <div className="overflow-hidden border-t border-gray-100">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <table className="w-full text-left">
                                <thead className="sr-only">
                                    <tr>
                                        <th></th>
                                        <th className="hidden sm:table-cell"></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{children}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
