import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useDeviceAssignments() {
    const authenticatedApi = useAuthenticatedApi();
    const getDeviceAssignments = async () => {
        const response = await authenticatedApi.get(
            '/device-management/assignments',
        );
        return response.data;
    };

    const addDeviceAssignmentsForGallery = async (
        galleryId: number,
        deviceIds: number[],
    ) => {
        const response = await authenticatedApi.post(
            `/device-management/assignments/gallery/${galleryId}/create`,
            JSON.stringify({
                deviceIds,
            }),
        );

        return response.data;
    };

    const listDeviceAssignmentsForGallery = async (galleryId: number) => {
        const response = await authenticatedApi.get(
            `/device-management/assignments/gallery/${galleryId}`,
        );

        return response.data;
    };

    return {
        getDeviceAssignments,
        addDeviceAssignmentsForGallery,
        listDeviceAssignmentsForGallery,
    };
}
