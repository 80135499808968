import { useCallback } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';
import api from '../../../services/api';

export default function useGalleryViewLogger() {
    const authenticatedApi = useAuthenticatedApi();
    const logGalleryView = useCallback(async (galleryId: number) => {
        const { data } = await api.post('/stat/log-stat', {
            metadata: {
                type: 'gallery-view',
                galleryId,
            },
        });

        return data;
    }, []);

    const getViews = useCallback(
        async (galleryId: number | null) => {
            const { data } = await authenticatedApi.get(
                `/reports/stats/gallery-view?${
                    galleryId ? `galleryId=${galleryId}` : ''
                }`,
            );

            return data;
        },
        [authenticatedApi],
    );

    return {
        getViews,
        logGalleryView,
    };
}
