import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import Loading from '../../Common/components/Loading';
import useFeatureFlagManagment from './hooks/useFeatureFlagManagement';
import RoleFeatureFlagAssociationInput from './RoleFeatureFlagAssociationInput';

export default function EditRoleFeatureFlagAssociation() {
    const { id, name } = useParams();

    const {
        isLoading,
        setIsLoading,
        getFeatureFlagsForRole,
        fetchFeatureFlags,
    } = useFeatureFlagManagment();

    const [associations, setAssociations] = useState<any[]>([]);
    const [featureFlags, setFeatureFlags] = useState<any[]>([]);

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const associations = await getFeatureFlagsForRole(
                    id ? +id : -1,
                );
                const featureFlags = await fetchFeatureFlags();

                isMounted && setAssociations(associations);
                isMounted && setFeatureFlags(featureFlags);
            } catch (error) {
                console.error('Failed to load assocaiations', error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    return (
        <div>
            <div className="Row-container">
                <h1>{name} role association</h1>
            </div>
            <table className="Table">
                <thead>
                    <tr>
                        <th>Feature Flag</th>
                        <th>Enabled</th>
                    </tr>
                </thead>
                <tbody>
                    {featureFlags.map((flag) => {
                        return (
                            <tr>
                                <td>{flag.key}</td>
                                <td>
                                    <RoleFeatureFlagAssociationInput
                                        checked={associations.includes(
                                            flag.key,
                                        )}
                                        roleId={id ? +id : -1}
                                        keyId={flag.id}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br />
            <div className="Layout-row">
                <NavLink to="/features" className="Secondary-button">
                    <span>done</span>
                </NavLink>
            </div>
        </div>
    );
}
