import { Button } from '@/src/shadComponents/ui/button';

type FlowTemplates = FlowTemplate[];
type FlowTemplate = {
    name: string;
    definition: any;
};

const Romr_3_2 = {
    name: 'ROMR 3:2',
    definition: [
        {
            name: 'resize',
            arguments: {
                in: 'input file',
                out: 'output file',
                width: '',
                height: '',
                totalPixels: '2160000',
            },
            fileType: 'jpg',
            fileCount: 1,
            saveOutput: true,
            argumentTypes: {
                width: 'number',
                height: 'number',
                totalPixels: 'number',
            },
        },
        {
            name: 'sbcolor',
            arguments: {
                in: 'input file',
                out: 'output file',
            },
            fileType: 'jpg',
            fileCount: 1,
            saveOutput: true,
        },
        {
            name: 'overlay',
            arguments: {
                in: 'input file',
                out: 'output file',
                overlay: 'overlay file 1',
                aspectRatio: '0.66',
            },
            fileType: 'jpg',
            fileCount: 1,
            saveOutput: true,
            argumentTypes: {
                overlay: 'fileurl',
                aspectRatio: 'aspectConditional',
            },
        },
        {
            name: 'overlay',
            arguments: {
                in: 'input file',
                out: 'output file',
                overlay: 'overlay file 2',
                aspectRatio: '1.5',
            },
            fileType: 'jpg',
            fileCount: 1,
            saveOutput: true,
            argumentTypes: {
                overlay: 'fileurl',
                aspectRatio: 'aspectConditional',
            },
        },
    ],
};

const Romr_16_9 = {
    name: 'ROMR 16:9',
    definition: [
        {
            name: 'resize',
            arguments: {
                in: 'input file',
                out: 'output file',
                width: '',
                height: '',
                totalPixels: '2073600',
            },
            fileType: 'jpg',
            fileCount: 1,
            saveOutput: true,
            argumentTypes: {
                width: 'number',
                height: 'number',
                totalPixels: 'number',
            },
        },
        {
            name: 'sbcolor',
            arguments: {
                in: 'input file',
                out: 'output file',
            },
            fileType: 'jpg',
            fileCount: 1,
            saveOutput: true,
        },
        {
            name: 'overlay',
            arguments: {
                in: 'input file',
                out: 'output file',
                overlay: 'overlay file 1',
                aspectRatio: '0.56',
            },
            fileType: 'jpg',
            fileCount: 1,
            saveOutput: true,
            argumentTypes: {
                overlay: 'fileurl',
                aspectRatio: 'aspectConditional',
            },
        },
        {
            name: 'overlay',
            arguments: {
                in: 'input file',
                out: 'output file',
                overlay: 'overlay file 2',
                aspectRatio: '1.77',
            },
            fileType: 'jpg',
            fileCount: 1,
            saveOutput: true,
            argumentTypes: {
                overlay: 'fileurl',
                aspectRatio: 'aspectConditional',
            },
        },
    ],
};

const flowTemplates: FlowTemplates = [Romr_3_2, Romr_16_9];

export default function FlowTemplatePicker({ onSelect, onClose }: any) {
    return (
        <div className="Asset-modal-container">
            <div className="modal">
                {flowTemplates.map((template) => {
                    return (
                        <div
                            className="FlowTemplate clickable"
                            onClick={() => onSelect(template.definition)}
                            key={template.name}
                        >
                            {template.name}
                        </div>
                    );
                })}
                <div className="spacer" />
                <div className="row bottom-row">
                    <div className="spacer" />
                    <Button onClick={onClose} className="Secondary-button">
                        close
                    </Button>
                </div>
            </div>
        </div>
    );
}
