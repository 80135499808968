import { useState } from 'react';
import LoadingIndicator from '../../Common/components/LoadingIndicator';
import usePersonalGallery from './hooks/usePersonalGallery';

interface FindMyPhotosModalProps {
    setShowModal: (show: boolean) => void;
}

export default function FindMyPhotosModal({
    setShowModal,
}: FindMyPhotosModalProps) {
    const [destination, setDestination] = useState<string>('');
    const { sendGalleryLink } = usePersonalGallery();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [wasSent, setWasSent] = useState<boolean>(false);
    const [shareType, setShareType] = useState<number | null>(null);

    return (
        <>
            <div
                className="SB-Modal-container"
                onClick={() => setShowModal(false)}
            ></div>
            <div className={`SB-Modal`}>
                <div
                    className="close-modal"
                    onClick={() => setShowModal(false)}
                >
                    <i className="bi bi-x"></i>
                </div>
                <div className="spacer" />
                <div className="spacer" />

                {!wasSent ? (
                    <>
                        {' '}
                        <h3 className="Find-title">
                            All of your Smilebooth <br /> photos in one place!
                        </h3>
                        <span className="Find-subtitle">
                            Enter in the phone number or email address you used
                            to get your last Smilebooth photos, and we'll send
                            you the link to your personal gallery!
                        </span>
                        <div className="spacer" />
                        <div className="Share-form">
                            {shareType === null ? (
                                <div className="Choices row">
                                    <div
                                        className="Choice"
                                        onClick={() => setShareType(1)}
                                    >
                                        <i className="bi bi-phone bounce"></i>
                                        <div>
                                            <h4 className="thin-title">text</h4>
                                        </div>
                                    </div>

                                    <div
                                        className="Choice"
                                        onClick={() => setShareType(0)}
                                    >
                                        <i className="bi bi-envelope-paper bounce"></i>
                                        <div>
                                            <h4 className="thin-title">
                                                email
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <input
                                    id="destination"
                                    name="destination"
                                    placeholder={
                                        shareType === 0
                                            ? 'hello@smilebooth.com'
                                            : '(281) 330-8004'
                                    }
                                    className="destination-field"
                                    type={shareType === 0 ? 'email' : 'tel'}
                                    value={destination}
                                    onChange={(e) =>
                                        setDestination(e.target.value)
                                    }
                                />
                            )}
                        </div>
                        <div className="spacer" />
                        {shareType !== null ? (
                            <span
                                className="cta-button"
                                onClick={async () => {
                                    if (isProcessing) return;

                                    setIsProcessing(true);
                                    setTimeout(async () => {
                                        await sendGalleryLink(destination);
                                        setIsProcessing(false);
                                        setWasSent(true);

                                        setTimeout(() => {
                                            setShowModal(false);
                                        }, 2400);
                                    });
                                }}
                            >
                                {isProcessing ? (
                                    <LoadingIndicator color="white" />
                                ) : (
                                    'send'
                                )}
                            </span>
                        ) : null}
                        <div className="spacer" />
                    </>
                ) : (
                    <>
                        <h2 className="Find-title">All set!</h2>
                        <p className="Find-subtitle">
                            If we find photos associated with your{' '}
                            {shareType === 0 ? 'email' : 'phone number'}, we'll
                            send you a link to your personal gallery!
                        </p>
                        <div className="spacer" />
                    </>
                )}
                <br />
            </div>
        </>
    );
}
