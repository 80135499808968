import { Switch } from '../shadComponents/ui/switch';
import FlowStepInput from './FlowStepInput';
import { Processor } from './components/hooks/useProcessing';

interface FlowStepProps {
    step: Processor;
    stepNumber: number;
    totalSteps: number;
    onDelete: (index: number) => void;
    moveStep: (from: number, to: number) => void;
    updateStep: (newStep: any) => void;
    onLaunchAssetPicker?: (callback: (value: string) => void) => void;
    currentEnvironment: string;
}
export default function FlowStep({
    step,
    stepNumber,
    totalSteps,
    onDelete,
    moveStep,
    updateStep,
    onLaunchAssetPicker,
    currentEnvironment,
}: FlowStepProps) {
    const isLastStep = stepNumber === totalSteps - 1;
    const isFirstStep = stepNumber === 0;

    const isProcessingEnvironmentSupported =
        step.environments?.includes(currentEnvironment) ?? true;

    return (
        <div className="mx-4" key={`step_${stepNumber}`}>
            <div className="Flow-step relative" key={stepNumber}>
                <div className="Form-row">
                    <div>
                        <div className="flex">
                            <div className="title capitalize mr-4">
                                {!isProcessingEnvironmentSupported && (
                                    <i
                                        title={`This processor may not be supported by the processing environment. Supported in ${
                                            step.environments?.join(',') ?? ''
                                        }.`}
                                        className="bi bi-exclamation-diamond mr-2 text-yellow-600"
                                    ></i>
                                )}
                                {step.title || step.name}
                            </div>

                            <div className="flex items-center">
                                <Switch
                                    checked={step.saveOutput}
                                    id={`step_${stepNumber}_saveoutput`}
                                    onCheckedChange={(checked) => {
                                        updateStep({
                                            index: stepNumber,
                                            ...step,
                                            saveOutput: checked,
                                        });
                                    }}
                                />
                                <label
                                    className="text-sm mx-2"
                                    htmlFor={`step_${stepNumber}_saveoutput`}
                                >
                                    Save Output
                                </label>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="footnote mr-4 my-3">
                                {step.description}
                            </div>
                        </div>

                        <div className="arguments">
                            {Object.keys(step.arguments)
                                .filter((key) => key !== 'in' && key !== 'out')
                                .map((key) => (
                                    <FlowStepInput
                                        key={`${totalSteps}_${stepNumber}_${key}`}
                                        name={key}
                                        stepNumber={stepNumber}
                                        argumentType={
                                            step.argumentTypes
                                                ? step.argumentTypes[key]
                                                : undefined
                                        }
                                        value={step.arguments[key]}
                                        onChange={(value: string | number) => {
                                            step.arguments[key] = value;
                                            updateStep({
                                                index: stepNumber,
                                                ...step,
                                                arguments: {
                                                    ...step.arguments,
                                                    [key]: value,
                                                },
                                            });
                                        }}
                                        onLaunchAssetPicker={
                                            onLaunchAssetPicker
                                        }
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="spacer" />
                    <div>
                        {!isFirstStep && (
                            <i
                                className="bi bi-arrow-up clickable"
                                onClick={() => {
                                    moveStep(stepNumber, stepNumber - 1);
                                }}
                            ></i>
                        )}
                        {!isLastStep && (
                            <i
                                className="bi bi-arrow-down clickable"
                                onClick={() => {
                                    moveStep(stepNumber, stepNumber + 1);
                                }}
                            ></i>
                        )}
                        &nbsp;
                        <i
                            className="bi bi-trash clickable"
                            onClick={() => {
                                onDelete(stepNumber);
                            }}
                        ></i>
                    </div>
                </div>
                <div className="flex absolute b-0 r-0"></div>
            </div>
            {stepNumber < totalSteps - 1 && (
                <div className="flow-arrow">
                    <i className="bi bi-arrow-down"></i>
                </div>
            )}
        </div>
    );
}
