import React from 'react';
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useOrgs from './hooks/useOrgs';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export default function OrgsTable() {
    const navigate = useNavigate();

    const { listOrgs } = useOrgs();
    const [orgs, setOrgs] = useState<Org[]>([]);
    const [isMounted, setIsMounted] = useState(true);
    const { orgId } = useUserOrg();

    useEffect(() => {
        setIsMounted(true);
        const fetchOrgs = async () => {
            const orgs = await listOrgs();
            isMounted && setOrgs(orgs);
        };
        fetchOrgs();

        return () => {
            setIsMounted(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="">
            <tr className="text-sm leading-6 text-gray-900">
                <th
                    scope="colgroup"
                    colSpan={3}
                    className="relative isolate py-2 font-semibold"
                >
                    <div className="px-4 sm:px-8 w-max relative ">
                        Organizations
                    </div>
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('create');
                            }}
                            className="mr-2 my-[6px] px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-100 rounded-sm absolute right-0"
                        >
                            <Plus className="h-5 w-5 text-black" />
                        </a>
                    </div>
                </th>
            </tr>
            <div>
                <div className="overflow-hidden border-t border-gray-100">
                    <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <table className="w-full text-left">
                                <thead className="sr-only">
                                    <tr>
                                        <th>Organization Name</th>
                                        <th className="hidden sm:table-cell"></th>
                                        <th>More details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orgs.map((org) => (
                                        <tr key={org.id}>
                                            <td className="relative py-5 pr-6">
                                                <div className="flex gap-x-6">
                                                    <div className="flex-auto">
                                                        <div className="flex items-start gap-x-3">
                                                            <div className="text-sm font-medium leading-6 text-gray-900 flex flex-row">
                                                                {org.name}
                                                                {org.id ===
                                                                orgId ? (
                                                                    <div className="flex flex-row pl-4">
                                                                        <i className="bi bi-check-circle-fill" />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="mt-1 text-xs leading-5 text-gray-500">
                                                            {org.logo}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                            </td>
                                            <td className="py-5 text-right">
                                                <div className="flex justify-end">
                                                    <a
                                                        href={`orgs/${org.id}`}
                                                        className="text-sm font-medium leading-6 text-black hover:text-indigo-500"
                                                    >
                                                        Administer
                                                        <span className="hidden sm:inline">
                                                            {' '}
                                                            Org &rarr;
                                                        </span>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
