import { useRef, useEffect, useCallback, useMemo } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

interface VideoPlayerProps {
    url: string;
    showControls: boolean;
}

export default function VideoPlayer({
    url,
    showControls = false,
}: VideoPlayerProps) {
    const playerRef = useRef(null);

    const videoJsOptions = useMemo(
        () => ({
            controls: showControls,
            responsive: true,
            fluid: true,
            loop: true,
            sources: [
                {
                    src: url,
                    type: 'video/mp4',
                },
            ],
        }),
        [url, showControls],
    );

    const handlePlayerReady = useCallback((player: any) => {
        playerRef.current = player;
        player.on('error', () => {
            player.reset();
        });
        player.volume(50);
        setTimeout(() => {
            player.play();
        }, 500);
    }, []);

    return (
        <VideoPlayerWrapper
            options={videoJsOptions}
            onReady={handlePlayerReady}
        />
    );
}

function VideoPlayerWrapper(props: any) {
    const videoRef = useRef<any>(null);
    const playerRef = useRef<any>(null);
    const { options, onReady } = props;

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = (playerRef.current = videojs(
                videoElement,
                options,
                () => {
                    onReady && onReady(player);
                },
            ));
        } else {
            const player = playerRef.current;
            player.autoplay('muted');
        }
    }, [options, videoRef, onReady]);

    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <video
                ref={videoRef}
                className="video-js vjs-big-play-centered"
                playsInline={true}
                muted={false}
            />
        </div>
    );
}
