import { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import useShare from '../../Share/components/hooks/useShare';
import useActivation from './hooks/useActivation';
import ShareList from './ShareList';
import { CSVLink } from 'react-csv';
import PageWithTable from '@/src/Common/components/PageWithTable';
import { DownloadIcon } from '@radix-ui/react-icons';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import Loading from '@/src/Common/components/Loading';

export default function ShareManager() {
    const { activationId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isReprocessing, setIsReprocessing] = useState(false);
    const [activation, setActivation] = useState<ActivationObject | null>(null);
    const [shares, setShares] = useState<(ShareObject & MediaInstance)[]>([]);
    const [csvShares, setCsvShares] = useState<any[]>([]);
    const { getActivation } = useActivation();
    const { getSharesForActivation, reprocessUnsentShares } = useShare();

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const activation = await getActivation(
                    activationId ? +activationId : 0,
                );

                setActivation(activation);

                const shares = await getSharesForActivation(activation.id);
                const csvShares = shares.map((share: any) => ({
                    destination: share.destination,
                    mediaUrl: share.originalUri,
                    type: share.type,
                    disclaimerAccepted: share.disclaimerAccepted,
                    createdAt: share.createdAt,
                }));

                setCsvShares(csvShares);
                setShares(shares);
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reprocessSharesAction = async () => {
        if (isReprocessing) {
            return;
        }
        setIsReprocessing(true);
        await reprocessUnsentShares(activationId ? +activationId : 0);
        setIsReprocessing(false);
    };

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    let actions = (
        <>
            <CSVLink
                data={csvShares}
                filename={`${activation?.name}Shares.csv`}
                target="_blank"
            >
                <DownloadIcon className="h-6 w-6" />
            </CSVLink>

            <Menu as="div" className="relative flex-none">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                    />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item>
                            {({ active }) => (
                                <span
                                    onClick={() => reprocessSharesAction()}
                                    className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'block px-3 py-1 text-sm leading-6 text-gray-900',
                                    )}
                                >
                                    {isReprocessing
                                        ? 'Reprocessing...'
                                        : 'Reprocess'}
                                </span>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        </>
    );

    return (
        <PageWithTable
            title="Shares"
            subtitle={`Total ${shares.length}`}
            actions={actions}
        >
            <ShareList shares={shares} />
        </PageWithTable>
    );
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}
