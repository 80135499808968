import { useState, useCallback, useContext } from 'react';
import SharingStationCreateShare from '@/src/Share/components/SharingStationCreateShare';
import QRCode from 'react-qr-code';
import GalleryShareContext from './GalleryShareContext';

interface ShareModalProps {
    gallery: GalleryObject;
    setShowShareModal: (show: boolean) => void;
    shareIdentifiers: string[];
    setShareType: (type: any) => void;
    shareType: any;
}

export default function SharingStationModal({
    gallery,
    setShowShareModal,
    shareIdentifiers,
    setShareType,
    shareType,
}: ShareModalProps) {
    const [isSharingComplete, setIsSharingComplete] = useState(false);

    // @ts-ignore
    const { setHideShareModal } = useContext(GalleryShareContext);

    const onShare = useCallback((destination: string, identifier: string) => {
        setIsSharingComplete(true);
        setShareType(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reset = useCallback(() => {
        setIsSharingComplete(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shareUri = `${window.location.origin}/gallery/${
        gallery.id
    }/share?shareIdentifier=${encodeURIComponent(shareIdentifiers.join(','))}`;

    return (
        <div className="Fullscreen-modal">
            <div
                className="close-modal"
                onClick={() => setShowShareModal(false)}
            >
                <i className="bi bi-x"></i>
            </div>
            {shareType === 2 ? (
                <div className="flex justify-center content-center w-full h-screen">
                    <div className="m-auto flex-col justify-center content-center">
                        <p className="center uppercase">Scan to Share</p>
                        <br />

                        <QRCode
                            value={shareUri}
                            fgColor={gallery?.headerBackgroundColor}
                            bgColor={gallery?.headerForegroundColor}
                            size={250}
                            className="qr-code"
                        />
                        <br />
                        <br />
                        <span
                            onClick={() =>
                                setHideShareModal && setHideShareModal()
                            }
                            className="cta-button no-border"
                        >
                            done
                        </span>
                    </div>
                </div>
            ) : (
                <SharingStationCreateShare
                    {...{
                        isSharingComplete,
                        galleryInfo: gallery,
                        onShare,
                        emailEnabled: gallery?.emailEnabled,
                        textEnabled: gallery?.textEnabled,
                        reset,
                        shareIdentifiers,
                        shareType,
                    }}
                />
            )}
        </div>
    );
}
