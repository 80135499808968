import React from 'react';
import OrgActivityFeed from './OrgActivityFeed';
import CurrentActivations from '@/src/Activations/components/CurrentActivations';

export default function Home() {
    return (
        <div className="p-4">
            <main className="lg:pr-96">
                <header className="flex items-center justify-between border-b border-white/5 ">
                    <h1 className="text-base font-semibold leading-7 text-black">
                        Activations
                    </h1>
                    <a href="/activations/">
                        <button className="ml-auto flex items-center gap-x-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black">
                            View All &rarr;
                        </button>
                    </a>
                    <div className="spacer"></div>
                </header>
                <CurrentActivations />
            </main>
            <aside className="bg-white/10 lg:fixed lg:bottom-0 lg:right-0 lg:top-[45px] lg:w-96 lg:overflow-y-auto lg:border-l lg:border-white/5">
                <OrgActivityFeed />
            </aside>
        </div>
    );
}
