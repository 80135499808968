import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';
import { useEffect, useState } from 'react';

const TEN_SECONDS = 10 * 1000;

export default function useOrgActivity() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();
    const [feed, setFeed] = useState<any[]>([]);

    const getLatestOrgPhotos = async () => {
        const response = await authenticatedApi.get(
            `${orgId}/media/latest?${
                feed.length > 0 ? `lastId=${feed[feed.length - 1].id}` : ''
            }`,
        );
        return response.data;
    };

    useEffect(() => {
        let isMounted = true;
        const getFeed = async () => {
            try {
                const response = await getLatestOrgPhotos();
                console.log('response', response);
                isMounted && setFeed(response);
            } catch (error) {
                console.error(error);
            }
        };

        getFeed();

        const timer = setInterval(() => {
            getFeed();
        }, TEN_SECONDS);

        return () => {
            clearInterval(timer);
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        feed,
    };
}
