import { Button } from '@/src/shadComponents/ui/button';
import { Card, CardContent, CardHeader } from '@/src/shadComponents/ui/card';
import { Checkbox } from '@/src/shadComponents/ui/checkbox';
import { Input } from '@/src/shadComponents/ui/input';
import { Label } from '@/src/shadComponents/ui/label';

interface SlideshowSettingsModalProps {
    gridWidth: number;
    gridHeight: number;
    gallery: GalleryObject;
    gridGap: number;
    rotationSpeed: number;
    displayHeader: boolean;
    setGridWidth: (width: number) => void;
    setGridHeight: (height: number) => void;
    setGridGap: (gap: number) => void;
    setRotationSpeed: (speed: number) => void;
    setShowModal: (show: boolean) => void;
    setDisplayHeader: (display: boolean) => void;
    displayNewest: boolean;
    setDisplayNewest: (display: boolean) => void;
}

export default function SlideshowSettingsModal({
    gridWidth,
    gridHeight,
    gridGap,
    rotationSpeed,
    displayHeader,
    setDisplayHeader,
    setGridWidth,
    setGridHeight,
    setGridGap,
    setRotationSpeed,
    setShowModal,
    displayNewest,
    setDisplayNewest,
}: SlideshowSettingsModalProps) {
    return (
        <div className="Fullscreen-modal flex justify-center">
            <div className="flex flex-row justify-center content-center align-center h-screen">
                <div className="spacer"></div>
                <Card className="m-auto flex-col justify-center content-center">
                    <CardHeader>
                        <h2>Slideshow Settings</h2>
                    </CardHeader>
                    <CardContent>
                        <div className="flex justify-between">
                            <div>
                                <Label htmlFor="gridHeight">Horizontal</Label>
                                <Input
                                    type="number"
                                    id="gridHeight"
                                    value={gridHeight}
                                    onChange={(e) => {
                                        setGridHeight(parseInt(e.target.value));
                                    }}
                                ></Input>
                            </div>
                            <div>
                                <br />
                                &nbsp; &nbsp;
                                {'x'}
                                &nbsp; &nbsp;
                            </div>
                            <div>
                                <Label htmlFor="gridWidth">Vertical</Label>
                                <Input
                                    type="number"
                                    id="gridWidth"
                                    value={gridWidth}
                                    onChange={(e) => {
                                        setGridWidth(parseInt(e.target.value));
                                    }}
                                ></Input>
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <div>
                                <Label htmlFor="gridGap">Grid Gap</Label>
                                <Input
                                    type="number"
                                    id="gridGap"
                                    value={gridGap}
                                    onChange={(e) => {
                                        setGridGap(parseInt(e.target.value));
                                    }}
                                ></Input>
                            </div>
                            <div>
                                <Label htmlFor="displayTime">
                                    Display Time (s)
                                </Label>
                                <Input
                                    type="number"
                                    id="displayTime"
                                    value={rotationSpeed}
                                    onChange={(e) => {
                                        setRotationSpeed(
                                            parseInt(e.target.value),
                                        );
                                    }}
                                ></Input>
                            </div>
                        </div>
                        <br />
                        <div className="flex align-middle">
                            <div>
                                <Checkbox
                                    id="displayHeader"
                                    className="mr-2 my-1"
                                    checked={displayHeader}
                                    onCheckedChange={() =>
                                        setDisplayHeader(!displayHeader)
                                    }
                                />

                                <Label htmlFor="displayHeader">
                                    Display Header
                                </Label>
                            </div>
                        </div>
                        <br />
                        <div className="flex align-middle">
                            <div>
                                <Checkbox
                                    id="displayNewest"
                                    className="mr-2 my-1"
                                    checked={displayNewest}
                                    onCheckedChange={() =>
                                        setDisplayNewest(!displayNewest)
                                    }
                                />

                                <Label htmlFor="displayNewest">
                                    Display Newest Photo First
                                </Label>
                            </div>
                        </div>
                        <br />

                        <br />
                        <div className="flex justify-end">
                            <Button
                                type="button"
                                className="Primary-button"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </Button>
                        </div>
                    </CardContent>
                </Card>
                <div className="spacer"></div>
            </div>
        </div>
    );
}
