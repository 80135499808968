import useAuthenticatedApi, {
    HTTP_STATUS_CODES,
} from '../../../Authentication/components/hooks/useAuthenticatedApi';

interface CrowdGallerySavable {
    galleryId: number;
    keyword: string;
    moderationNumber: string;
    welcomeMessage: string;
    responseMessage: string;
    sendPersonalGalleryLink: boolean;
    sendGalleryLink: boolean;
    sendPhoto: boolean;
    enabled: boolean;
}

export type CrowdGallerySettings = {
    id: number;
} & CrowdGallerySavable;

export default function useCrowdGallery() {
    const authenticatedApi = useAuthenticatedApi();
    const saveCrowdGallery = async (
        crowdGalleryId: number,
        crowdGalleryObject: CrowdGallerySavable,
    ) => {
        const response = await authenticatedApi.put(
            `/galleries/crowd/${crowdGalleryId}`,
            JSON.stringify(crowdGalleryObject),
        );

        return response.status === HTTP_STATUS_CODES.OK;
    };

    const getCrowdGallery = async (
        galleryId: number,
    ): Promise<CrowdGallerySettings> => {
        const response = await authenticatedApi.get(
            `/galleries/crowd/${galleryId}`,
        );

        const { data } = response;

        return data;
    };

    return {
        saveCrowdGallery,
        getCrowdGallery,
    };
}
