import { ChangeEvent, useState } from 'react';
import useLocationQuery from './hooks/useLocationQuery';

export default function LocationSmartField(props: any) {
    const [locations, setLocations] = useState<string[]>([]);
    const { getLocations } = useLocationQuery();

    const onLocationChange = async (e: ChangeEvent) => {
        // @ts-ignore
        const value = e.target?.value;

        props.setValue(value);
        if (!value) {
            return;
        }

        const response = await getLocations(value);

        if (Array.isArray(response?.features)) {
            setLocations(
                response.features.map(
                    (place: { place_name: string }) => place.place_name,
                ),
            );
        }
    };

    return (
        <div className="expand flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
            <input
                list="locations"
                type="text"
                id="location"
                name="location"
                onChange={onLocationChange}
                autoComplete="on"
                {...props}
                value={props.value}
            />
            <datalist id="locations">
                {locations.map((location: string, i: number) => {
                    return <option key={i}>{location}</option>;
                })}
            </datalist>
        </div>
    );
}
