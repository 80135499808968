import { useEffect, useState } from 'react';
import useAuthenticatedApi, {
    HTTP_STATUS_CODES,
} from '../../../Authentication/components/hooks/useAuthenticatedApi';

interface SyncStats {
    syncedFolderCount: number;
    neverSyncedCount: number;
    eligibleForSyncCount: number;
    lastSync: string;
    automaticSyncCount: number;
    isBulkFolderSyncRunning: boolean;
    isBulkMediaSyncRunning: boolean;
}

export default function useV3Sync() {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState(false);
    const [feedback, setFeedback] = useState<string | null>(null);
    const [syncStats, setSyncStats] = useState<SyncStats | null>(null);
    const [foldersNeverSyncd, setFoldersNeverSynced] = useState<
        V3ImportedFolder[]
    >([]);
    const [folderSyncStats, setFolderSyncStats] = useState<any | null>(null);

    const syncAllMedia = async () => {
        const response = await authenticatedApi.get(`/v3/sync/media`);
        if (response.status === HTTP_STATUS_CODES.OK) {
            console.log('response.data', response.data);
            setFeedback('media sync started');
            return;
        }

        setFeedback('error syncing all media');

        setTimeout(() => {
            setFeedback(null);
        }, 5000);
    };

    const fixOldVideoThumbnails = async () => {
        const response = await authenticatedApi.get(
            `/media/fix-video-thumbnails`,
        );
        if (response.status === HTTP_STATUS_CODES.OK) {
            console.log('response.data', response.data);
            setFeedback('fixing thumbnails started');
            return;
        }

        setFeedback('error fixing thumbnails');

        setTimeout(() => {
            setFeedback(null);
        }, 5000);
    };

    const syncAllFolders = async () => {
        const response = await authenticatedApi.get(`/v3/sync`);
        if (response.status === HTTP_STATUS_CODES.OK) {
            console.log('response.data', response.data);
            setFeedback('folders sync started');
            return;
        }

        setFeedback('error syncing all folders');

        setTimeout(() => {
            setFeedback(null);
        }, 5000);
    };

    const getSyncStats = async () => {
        setIsLoading(true);
        const response = await authenticatedApi.get(`/v3/sync/stats`);
        if (response.status === HTTP_STATUS_CODES.OK) {
            setSyncStats(response.data);
        }
        setIsLoading(false);
    };

    const repairAndSyncFolder = async (portal: string, folder: string) => {
        const response = await authenticatedApi.get(
            `/v3/sync/repair/${portal}/${folder}`,
        );

        if (response.status === HTTP_STATUS_CODES.OK) {
            setFeedback('repair complete and sync started');
        } else {
            setFeedback('error repairing and syncing folder');
        }

        setTimeout(() => {
            setFeedback(null);
        }, 5000);
    };

    const syncSingleFolderMedia = async (portal: string, folder: string) => {
        const response = await authenticatedApi.get(
            `/v3/sync/media/${portal}/${folder}`,
        );

        if (response.status === HTTP_STATUS_CODES.OK) {
            setFeedback('folder sync started');
        } else {
            setFeedback('error syncing folder');
        }

        setTimeout(() => {
            setFeedback(null);
        }, 5000);
    };

    const getFolderSyncStats = async (folder: string) => {
        setIsLoading(true);
        const response = await authenticatedApi.get(
            `/v3/sync/stats/${encodeURIComponent(folder)}`,
        );
        if (response.status === HTTP_STATUS_CODES.OK) {
            setFolderSyncStats(response.data);
        }
        setIsLoading(false);
    };

    const getFoldersNeverSynced = async () => {
        setIsLoading(true);
        const response = await authenticatedApi.get(`/v3/sync/unsyncd`);
        if (response.status === HTTP_STATUS_CODES.OK) {
            setFoldersNeverSynced(response.data);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getSyncStats();
        getFoldersNeverSynced();

        const interval = setInterval(() => {
            getSyncStats();
        }, 60 * 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        syncAllMedia,
        syncAllFolders,
        syncSingleFolderMedia,
        getFolderSyncStats,
        folderSyncStats,
        syncStats,
        feedback,
        foldersNeverSyncd,
        repairAndSyncFolder,
        fixOldVideoThumbnails,
    };
}
