import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useTags() {
    const authenticatedApi = useAuthenticatedApi();
    const getTags = async () => {
        const response = await authenticatedApi.get('/tags');
        return response.data;
    };

    const addTag = async (values: any) => {
        const response = await authenticatedApi.post(
            '/tags/create',
            JSON.stringify(values),
        );

        return response.data;
    };

    return {
        getTags,
        addTag,
    };
}
