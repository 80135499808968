import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import JobForm from './JobForm';
import useJobHelpers from './hooks/useJobHelpers';
import useCreateJob from './hooks/useCreateJob';

export default function JobEditor() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [job, setJob] = useState<any | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const { getJob } = useJobHelpers();
    const { updateJob } = useCreateJob();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                // @ts-ignore
                const job = await getJob(+id);
                job.clientIds = job.clientIds.map((clientId: number) =>
                    String(clientId),
                );
                setJob(job);
                setTimeout(() => isMounted && setIsLoading(false), 0);
            } catch (e) {
                console.log(e);
            }
        };
        getData();
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // @ts-ignore
    if (!job) {
        return null;
    }

    if (isLoading) {
        return <div>loading...</div>;
    }

    return (
        <JobForm
            job={job}
            onCancel={() => navigate(-1)}
            onSubmit={async (job: any) => {
                if (await updateJob(job)) {
                    toast.success('Job Saved');

                    setTimeout(() => {
                        navigate(-1);
                    }, 1000);
                }
            }}
        />
    );
}
