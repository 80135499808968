import { authenticatedApi } from '../../../services/api';
import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken';
import useAuth from './useAuth';

export enum HTTP_STATUS_CODES {
    OK = 200,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
}

export default function useAuthenticatedApi() {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        const requestInterceptor = authenticatedApi.interceptors.request.use(
            (config: any) => {
                if (!config.headers['Authorization']) {
                    config.headers[
                        'Authorization'
                    ] = `Bearer ${auth?.accessToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseInterceptor = authenticatedApi.interceptors.response.use(
            (response) => response,
            async (error) => {
                const previousRequest = error?.config;
                if (error?.response?.status === 403 && !previousRequest?.sent) {
                    previousRequest.sent = true;
                    const newAccessToken = await refresh();
                    previousRequest.headers[
                        'Authorization'
                    ] = `Bearer ${newAccessToken}`;

                    return authenticatedApi(previousRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            authenticatedApi.interceptors.request.eject(requestInterceptor);
            authenticatedApi.interceptors.response.eject(responseInterceptor);
        };
    }, [auth, refresh]);

    return authenticatedApi;
}
