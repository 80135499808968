import { useNavigate, useParams } from 'react-router-dom';
import useExperienceBuilder from './hooks/useExperienceBuilder';
import {
    XCircleIcon,
    ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import useExperienceDefinition from './hooks/useExperienceDefinition';
import { useEffect, useState } from 'react';
import ExperienceEditor from './ExperienceEditor';
import ExperienceDrawer from './ExperienceDrawer';
import { useSelector } from 'react-redux';
import ScreenEditor from './ScreenEditor';

export default function ExperienceBuilder() {
    const navigate = useNavigate();
    const { activationId } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const {
        definition,
        screenCount,
        processingFlowCount,
        warnings,
        errors,
        importDefinition,
        exportDefinition,
        screens,
    } = useExperienceBuilder(activationId ? +activationId : 0);

    const [selectedScreenId, setSelectedScreenId] = useState<string | null>(
        null,
    );

    const currentScreen: ScreenDefinition | null = useSelector((state: any) => {
        if (selectedScreenId) {
            return state.present.screens.entities[selectedScreenId];
        }
        return null;
    });

    const { getDefinition, saveDefinition } = useExperienceDefinition();

    useEffect(() => {
        if (definition === null) {
            getDefinition(activationId ? +activationId : 0).then(
                (experience) => {
                    if (experience) {
                        importDefinition(
                            JSON.stringify(experience.definition),
                            activationId ? +activationId : 0,
                        );
                    }
                },
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mb-20">
            <h2 className="text-xl font-semibold text-gray-900 mx-8 my-4">
                Experience Builder
            </h2>
            <div className="Form-row">
                <div className="spacer" />
            </div>
            {definition === null && (
                <>
                    <div className="mx-8">
                        <div className="rounded-md bg-yellow-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-yellow-800">
                                        No experience definition found for
                                        activation.
                                    </h3>
                                    <div className="mt-2 text-sm text-yellow-700">
                                        <p>
                                            Please import or create a new
                                            experience definition.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {warnings.length > 0 && (
                <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-yellow-400"
                            />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">
                                {warnings.length} warning
                                {warnings.length > 1 ? 's' : ''} in the
                                experience.
                            </h3>
                            <div className="mt-2 text-sm text-yellow-700">
                                <ul className="list-disc space-y-1 pl-5">
                                    {warnings.map((error) => (
                                        <li key={error}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {errors.length > 0 && (
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-red-400"
                            />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">
                                {errors.length} error
                                {errors.length > 1 ? 's' : ''} in the
                                experience.
                            </h3>
                            <div className="mt-2 text-sm text-red-700">
                                <ul className="list-disc space-y-1 pl-5">
                                    {errors.map((error) => (
                                        <li key={error}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="mx-8 mt-4">
                {definition && (
                    <>
                        <div className="Form-row">
                            <label
                                htmlFor="screenCount"
                                className="text-sm font-semibold text-gray-900"
                            >
                                Total Screens:
                            </label>
                            <span className="text-sm font-semibold text-gray-900">
                                {screenCount}
                            </span>
                        </div>
                        <div className="Form-row">
                            <label
                                htmlFor="processingFlowCount"
                                className="text-sm font-semibold text-gray-900"
                            >
                                Processing Flows:
                            </label>
                            <span className="text-sm font-semibold text-gray-900">
                                {processingFlowCount}
                            </span>
                        </div>
                    </>
                )}
                <ExperienceEditor
                    screens={screens}
                    onScreenSelected={(screenId) => {
                        console.log('Screen selected', screenId);
                        setSelectedScreenId(screenId);
                    }}
                    onDefinitionChange={(definition) => {}}
                />
            </div>
            <div className="form">
                <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                    <button
                        className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black  disabled:opacity-50"
                        onClick={() => {
                            const fileName = 'experience-definition';
                            const json = exportDefinition();
                            const blob = new Blob([json], {
                                type: 'application/json',
                            });
                            const href = URL.createObjectURL(blob);

                            const link = document.createElement('a');
                            link.href = href;
                            link.download = fileName + '.json';
                            document.body.appendChild(link);
                            link.click();

                            // clean up "a" element & remove ObjectURL
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }}
                        disabled={!definition}
                    >
                        Export
                    </button>
                    <button
                        className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                        onClick={() => {
                            const input = document.createElement('input');
                            input.type = 'file';
                            input.accept = '.json';
                            input.onchange = (e) => {
                                const file = (e.target as HTMLInputElement)
                                    .files
                                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                      //@ts-ignore
                                      (e.target as HTMLInputElement).files[0]
                                    : null;
                                if (file) {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        const text = e.target?.result;
                                        if (typeof text === 'string') {
                                            importDefinition(
                                                text,
                                                activationId
                                                    ? +activationId
                                                    : 0,
                                            );
                                        }
                                    };
                                    reader.readAsText(file);
                                }
                            };
                            input.click();
                        }}
                    >
                        Import
                    </button>

                    <div className="spacer" />
                    <button
                        className="text-sm font-semibold leading-6 text-gray-900"
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        <span>Cancel</span>
                    </button>
                    <button
                        className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black disabled:opacity-50"
                        type="submit"
                        onClick={
                            async () => {
                                if (submitting) {
                                    return;
                                }
                                console.log('Save button clicked');
                                setSubmitting(true);
                                await saveDefinition(
                                    activationId ? +activationId : 0,
                                    exportDefinition(),
                                );
                                setSubmitting(false);
                            }
                            //onSave(environmentId, isEnabled, flowDefinition)
                        }
                        disabled={!definition || submitting}
                    >
                        Save
                    </button>
                </div>
            </div>
            <ExperienceDrawer
                isOpen={!!selectedScreenId}
                onClose={() => setSelectedScreenId(null)}
                title={`Edit Screen: ${currentScreen?.id}` || ''}
            >
                <ScreenEditor
                    screen={currentScreen}
                    onIdChange={(newId: string) => setSelectedScreenId(newId)}
                />
            </ExperienceDrawer>
        </div>
    );
}
