import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useShare from '../../Share/components/hooks/useShare';
import useActivation from './hooks/useActivation';
import useV3Sync from './hooks/useV3Sync';
import GalleryList from '../../GalleryConfiguration/components/GalleryList';
import Loading from '../../Common/components/Loading';
import MediaStatsTable from './MediaStatsTable';
import ShareStatsTable from './ShareStatsTable';
import SessionStatsTable from './SessionStatsTable';
import { Tabs, TabsContent } from '@/src/shadComponents/ui/tabs';
import Processing from '@/src/GalleryConfiguration/components/Processing';
import { Link } from 'react-router-dom';
//import ActivationStats from './ActivationStats';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from '@/src/shadComponents/ui/card';
import useJobHelpers from '@/src/Jobs/components/hooks/useJobHelpers';
import ActivationDocuments from './ActivationDocuments';
import PageWithSidebar from '@/src/Common/components/PageWithSidebar';
import {
    DocumentCheckIcon,
    Cog6ToothIcon,
    AdjustmentsHorizontalIcon,
    ChartBarIcon,
    PhotoIcon,
    ShareIcon,
    SparklesIcon,
} from '@heroicons/react/20/solid';
import PhotoManager from './PhotoManager';
import FeatureFlag from '@/src/Features/components/FeatureFlag';
import { FeatureFlags } from '@/src/Features/lib/constants';
import ShareManager from './ShareManager';
import useGetGalleries from '@/src/GalleryConfiguration/components/hooks/useGetGalleries';
import ExperienceBuilder from '@/src/ExperienceBuilder/ExperienceBuilder';
import useFeatureFlags from '@/src/Features/components/hooks/useFeatureFlags';

interface ActivationObject {
    id: number;
    name: string;
    date: Date;
    location: string;
    owner: string;
    isArchived: boolean;
    v3Folder: string;
    jobId?: number;
}

export default function ActivationDashboard() {
    const navigate = useNavigate();
    const { activationId: id } = useParams();
    const [galleries, setGalleries] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { getActivation, getActivationMediaStats, getActivationSessions } =
        useActivation();
    const { getSharesForActivation, getShareStatsForActivation } = useShare();
    const [, setShares] = useState<any[]>([]);
    const [shareStats, setShareStats] = useState<any[]>([]);
    const [mediaStats, setMediaStats] = useState<any[]>([]);
    const [sessions, setSessions] = useState<any[]>([]);
    const [job, setJob] = useState<any>(null);
    const { getJob } = useJobHelpers();
    const { getGalleriesForActivation } = useGetGalleries();

    const [activation, setActivation] = useState<ActivationObject | null>(null);
    const { getFolderSyncStats } = useV3Sync();
    const { featureFlags } = useFeatureFlags();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const activationId = id ? +id : 0;
                const activation = await getActivation(activationId);

                if (activation.jobId) {
                    const job = await getJob(activation.jobId);
                    setJob(job);
                }

                const shares = await getSharesForActivation(activationId);
                const shareStats = await getShareStatsForActivation(
                    activationId,
                );

                const mediaStats = await getActivationMediaStats(activationId);

                const sessions = await getActivationSessions(activationId);

                if (activation.v3Folder) {
                    await getFolderSyncStats(activation.v3Folder);
                }

                const galleries = await getGalleriesForActivation(activationId);

                setActivation(activation);
                setShares(shares);
                setShareStats(shareStats);
                setMediaStats(mediaStats);
                setSessions(sessions);
                setGalleries(galleries);

                setTimeout(() => isMounted && setIsLoading(false), 0);
            } catch (error) {
                console.error(error);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let splitPath = window.location.href.split('#');
    let selectedTab = splitPath.length > 1 ? splitPath[1] : 'details';

    const navigation = useMemo(() => {
        var nav = [
            {
                name: 'Details',
                href: '#details',
                current: selectedTab === 'details',
                icon: DocumentCheckIcon,
            },
            {
                name: 'Gallery Configuration',
                href: '#galleries',
                current: selectedTab === 'galleries',
                icon: Cog6ToothIcon,
            },
            {
                name: 'Media',
                href: '#media',
                current: selectedTab === 'media',
                icon: PhotoIcon,
            },
            {
                name: 'Processing',
                href: '#processing',
                current: selectedTab === 'processing',
                icon: AdjustmentsHorizontalIcon,
            },
            {
                name: 'Shares',
                href: '#shares',
                current: selectedTab === 'shares',
                icon: ShareIcon,
            },
            {
                name: 'Stats',
                href: '#stats',
                current: selectedTab === 'stats',
                icon: ChartBarIcon,
            },
        ];

        if (featureFlags[FeatureFlags.EXPERIENCE_BUILDER_V1]) {
            nav.push({
                name: 'Experience Builder',
                href: '#experience',
                current: selectedTab === 'experience',
                icon: SparklesIcon,
            });
        }

        return nav;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureFlags, selectedTab]);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    if (!activation) {
        return <div></div>;
    }

    const actions = (
        <>
            <span
                onClick={() => navigate(`/activations/${id}/edit`)}
                className="ml-auto flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
            >
                Edit Details
            </span>
        </>
    );

    return (
        <PageWithSidebar
            title={activation.name}
            subtitle={job?.name}
            actions={actions}
            navigation={navigation}
        >
            {/*selectedTab !== 'media' ? <ActivationStats /> : null*/}
            <Tabs value={`${selectedTab}`}>
                <TabsContent value="galleries">
                    <GalleryList activationId={id ? +id : 0} />
                </TabsContent>
                <TabsContent value="processing">
                    <Processing activationId={id ? +id : 0} />
                </TabsContent>
                <TabsContent value="media">
                    <PhotoManager />
                </TabsContent>
                <FeatureFlag flag={FeatureFlags.EXPERIENCE_BUILDER_V1}>
                    <TabsContent value="experience">
                        <ExperienceBuilder />
                    </TabsContent>
                </FeatureFlag>
                <TabsContent value="details">
                    <div className="grid px-2">
                        <CardHeader>
                            <CardTitle>Activation Details</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="">
                                <span className="label">Date: </span>
                                <span>{activation.date.toString()}</span>
                            </div>
                            <div className="Block-row">
                                <span className="label">Owner: </span>
                                <span>{activation.owner}</span>
                            </div>
                            <div className="Block-row">
                                <span className="label">Location: </span>
                                <span>{activation.location}</span>
                            </div>

                            {activation.v3Folder && (
                                <div className="Block-row">
                                    <span className="label">Linked To: </span>
                                    <span>{activation.v3Folder}</span>
                                </div>
                            )}

                            {galleries.map((gallery) => {
                                return (
                                    <>
                                        <div
                                            key={gallery.id}
                                            className="Block-row"
                                        >
                                            <span className="label">
                                                Gallery:{' '}
                                            </span>
                                            <Link
                                                to={`/gallery/${encodeURIComponent(
                                                    gallery.slug,
                                                )}`}
                                            >
                                                {gallery.name}
                                                &nbsp;&nbsp;
                                                <i className="bi bi-box-arrow-up-right"></i>
                                            </Link>
                                        </div>

                                        <div
                                            key={gallery.id}
                                            className="Block-row"
                                        >
                                            <span className="label">
                                                Slideshow:{' '}
                                            </span>
                                            <Link
                                                to={`/gallery/${encodeURIComponent(
                                                    gallery.slug,
                                                )}/slideshow`}
                                            >
                                                {gallery.name}
                                                &nbsp;&nbsp;
                                                <i className="bi bi-box-arrow-up-right"></i>
                                            </Link>
                                        </div>

                                        <div
                                            key={gallery.id}
                                            className="Block-row"
                                        >
                                            <span className="label">
                                                Sharing Station:{' '}
                                            </span>
                                            <Link
                                                to={`/gallery/${encodeURIComponent(
                                                    gallery.slug,
                                                )}/sharing`}
                                            >
                                                {gallery.name}
                                                &nbsp;&nbsp;
                                                <i className="bi bi-box-arrow-up-right"></i>
                                            </Link>
                                        </div>
                                    </>
                                );
                            })}
                        </CardContent>
                        <FeatureFlag flag={FeatureFlags.DOCUMENT_VIEWER_V1}>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Activation Documents</CardTitle>
                                    <CardContent className="p-0">
                                        <ActivationDocuments />
                                    </CardContent>
                                </CardHeader>
                                <CardContent>
                                    <div className="space-y-16 xl:space-y-20">
                                        <div>
                                            <div className="overflow-hidden border-t border-gray-100">
                                                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                                                        <table className="w-full text-left">
                                                            <thead className="sr-only">
                                                                <tr>
                                                                    <th></th>
                                                                    <th className="hidden sm:table-cell"></th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody></tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </FeatureFlag>
                    </div>
                </TabsContent>
                <TabsContent value="shares">
                    <ShareManager />
                </TabsContent>
                <TabsContent value="stats">
                    <div className="row">
                        {sessions.length > 0 && (
                            <>
                                <div className="mx-auto flex max-w-10xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                                    <h1 className="mt-1 text-xl font-semibold leading-6 text-gray-900">
                                        Sessions
                                    </h1>
                                </div>
                                <SessionStatsTable
                                    activationId={activation.id}
                                    sessionStats={sessions}
                                />
                            </>
                        )}

                        {shareStats.length > 0 && (
                            <>
                                <div className="pt-10 mx-auto flex max-w-10xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                                    <h1 className="mt-1 text-xl font-semibold leading-6 text-gray-900">
                                        Share Stats
                                    </h1>
                                </div>
                                <ShareStatsTable shareStats={shareStats} />
                            </>
                        )}
                        {mediaStats.length > 0 && (
                            <>
                                <div className="pt-10 mx-auto flex max-w-10xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                                    <h1 className="mt-1 text-xl font-semibold leading-6 text-gray-900">
                                        Media Stats
                                    </h1>
                                </div>
                                <MediaStatsTable mediaStats={mediaStats} />
                            </>
                        )}
                    </div>
                </TabsContent>
            </Tabs>
        </PageWithSidebar>
    );
}
