import { Table, Column } from '@tanstack/react-table';
import React from 'react';
import DebouncedInput from './DebouncedInput';

export default function TableFilter({
    column,
    table,
}: {
    column: Column<any, unknown>;
    table: Table<any>;
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === 'number'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [column.getFacetedUniqueValues()]
    );

    return (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`Search... (${
                    column.getFacetedUniqueValues().size
                })`}
                className="w-36 border shadow rounded"
                list={column.id + 'list'}
            />
            <div className="h-1" />
        </>
    );
}
