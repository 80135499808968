import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

type ReleaseType =
    | 'smileport-adapter'
    | 'mac-client'
    | 'mac-sharing-utility'
    | 'mac-processing-utility'
    | 'mac-upload-utility'
    | 'romr-android';

export function useSoftwareReleases() {
    const authenticatedApi = useAuthenticatedApi();
    const getLatestRelease = async (releaseType: ReleaseType) => {
        const response = await authenticatedApi.get(`/software/${releaseType}`);
        return response.data;
    };

    return {
        getLatestRelease,
    };
}
