import React from 'react';

export interface AuthContextType {
    auth: any;
    setAuth: (auth: any) => void;
    persist: boolean;
    setPersist: (persist: any) => void;
    signin: (user: string, password: string) => Promise<any>;
    signInGoogle: (jwt: string) => Promise<any>;
    signout: () => Promise<any>;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export default AuthContext;
