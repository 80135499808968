import { useState, useEffect } from 'react';
import useGalleryBehaviors from '../../../Gallery/components/hooks/useGalleryBehvaiors';

export default function useShareGalleryInteractions(photos: MediaInstance[]) {
    const [gallery] = useState<MediaInstance[]>(photos);
    const [loadedPhotos, setLoadedPhotos] = useState<MediaInstance[]>([]);
    const [expandedIndex, setExpandedIndex] = useState<number>(-1);

    const getNewPage = (count: number) => {
        if (
            gallery.length === loadedPhotos.length &&
            loadedPhotos.length !== 0
        ) {
            return;
        }

        setLoadedPhotos([
            ...loadedPhotos,
            ...gallery.slice(
                loadedPhotos.length - 1,
                loadedPhotos.length + count - 1,
            ),
        ]);
    };

    const advance = () => {
        if (expandedIndex === loadedPhotos.length - 1) {
            getNewPage(10);
        }

        expandedIndex < gallery.length && setExpandedIndex(expandedIndex + 1);
    };

    const reverse = () =>
        setExpandedIndex(expandedIndex >= 0 ? expandedIndex - 1 : -1);

    const { swipeHandlers } = useGalleryBehaviors(
        setExpandedIndex,
        advance,
        reverse,
    );

    useEffect(() => {
        if (loadedPhotos.length === 0) {
            setLoadedPhotos([
                ...gallery.slice(0, window?.outerWidth > 1500 ? 20 : 10),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gallery.length]);

    return {
        gallery,
        swipeHandlers,
        expandedIndex,
        setExpandedIndex,
        loadedPhotos,
        getNewPage,
    };
}
