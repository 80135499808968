import { Button } from '@/src/shadComponents/ui/button';
import { Input } from '@/src/shadComponents/ui/input';
import { useState } from 'react';

export default function ListPickerAdd({ onSubmit }: any) {
    const [value, setValue] = useState<string>('');

    return (
        <div className="flex flex-row mt-4">
            <Input
                value={value}
                onInput={(e: any) => setValue(e.target.value)}
                className="mt-0.5"
            />
            <Button
                onClick={() => {
                    try {
                        onSubmit(value);
                        setValue('');
                    } catch {}
                }}
                className="Tertiary-button"
            >
                add
            </Button>
            <Button onClick={() => setValue('')} className="Tertiary-button">
                clear
            </Button>
        </div>
    );
}
