import PageWithTable from '@/src/Common/components/PageWithTable';
import OrgsTable from './OrgsTable';

export default function OrgsList() {
    const actions = (
        <div className="flex justify-end">
            <button className="btn btn-primary">Create Org</button>
        </div>
    );

    return (
        <PageWithTable title="Organization Administration" action={actions}>
            <OrgsTable />
        </PageWithTable>
    );
}
