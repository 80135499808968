import useAuthenticatedApi, {
    HTTP_STATUS_CODES,
} from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useGallery() {
    const authenticatedApi = useAuthenticatedApi();
    const saveGallery = async (galleryId: number, galleryObject: any) => {
        const response = await authenticatedApi.put(
            `/galleries/${galleryId}`,
            JSON.stringify(galleryObject),
        );

        return response.status === HTTP_STATUS_CODES.OK;
    };

    const getGallery = async (galleryId: number) => {
        const response = await authenticatedApi.get(`/galleries/${galleryId}`);

        const { data } = response;

        return data;
    };

    return {
        saveGallery,
        getGallery,
    };
}
