import PageWithSidebar from '@/src/Common/components/PageWithSidebar';
import { Tabs, TabsContent } from '@/src/shadComponents/ui/tabs';
import DeviceKeys from './DeviceKeys';
import DeviceGroups from './DeviceGroups';
import {
    FolderArrowDownIcon,
    KeyIcon,
    UserGroupIcon,
} from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import SoftwareReleases from './SoftwareReleases';

export default function DeviceManagement() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const navigate = useNavigate();
    let splitPath = window.location.href.split('#');
    let selectedTab = splitPath.length > 1 ? splitPath[1] : 'keys';

    return (
        <PageWithSidebar
            title="Device Management"
            subtitle="Manage your devices and groups."
            actions={null}
            navigation={[
                {
                    name: 'Device Keys',
                    href: '#keys',
                    current: selectedTab === 'keys',
                    icon: KeyIcon,
                },
                {
                    name: 'Device Groups',
                    href: '#groups',
                    current: selectedTab === 'groups',
                    icon: UserGroupIcon,
                },
                {
                    name: 'Software',
                    href: '#software',
                    current: selectedTab === 'software',
                    icon: FolderArrowDownIcon,
                },
            ]}
        >
            <Tabs value={`${selectedTab}`}>
                <TabsContent value="keys">
                    <DeviceKeys />
                </TabsContent>
                <TabsContent value="groups">
                    <DeviceGroups />
                </TabsContent>
                <TabsContent value="software">
                    <SoftwareReleases />
                </TabsContent>
            </Tabs>
        </PageWithSidebar>
    );
}
