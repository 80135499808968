import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

export default function TextFieldType({ field }: { field: DocumentField }) {
    const [value, setValue] = useState<string>(field.value || '');
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        setFieldValue(field.name, value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, setFieldValue]);

    return (
        <>
            <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                        type="text"
                        name={field.name}
                        id={field.name}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
            {field.helperText ? (
                <p className="mt-3 text-sm leading-6 text-gray-600">
                    {field.helperText}
                </p>
            ) : null}
        </>
    );
}
