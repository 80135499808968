import axios from 'axios';
const LOCAL_API_URL = 'http://localhost:8000';
const ALPHA_API_URL = 'https://photostat-api.alpha.smilebooth.com';
const PRODUCTION_API_URL = 'https://photostat-api.smilebooth.com';

const API_URL =
    process.env.NODE_ENV === 'development'
        ? LOCAL_API_URL
        : window.location.hostname.indexOf('.alpha.') === -1
        ? PRODUCTION_API_URL
        : ALPHA_API_URL;

// const API_URL = ALPHA_API_URL;

export default axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

export const authenticatedApi = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});
