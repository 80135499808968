import { useLocalStorage } from '@/src/Common/components/hooks/useLocalStorage';
import api from '../../../services/api';
import { useEffect, useState } from 'react';

export default function useGalleryUser(
    gallery: GalleryObject | null,
    onPromptUserToJoin: () => void,
) {
    const [promptedToJoin, setPromptedToJoin] = useLocalStorage(
        `wasAskedForGalleryUser-${gallery?.id}`,
        false,
    );

    const [isGalleryUser, setIsGalleryUser] = useState<Boolean | undefined>(
        undefined,
    );
    const [shouldPromptUserToJoin, setShouldPromptUserToJoin] = useState(false);

    const lookupGalleryUser = async (destination: string) => {
        const results = await api
            .get(
                `/gallery-users/lookup?destination=${encodeURIComponent(
                    destination,
                )}`,
            )
            .catch((e) => {
                console.error(e);
            });

        return results;
    };

    const checkGalleryUser = async (destination: string) => {
        if (!destination) {
            return;
        }

        const galleryUserResponse = await lookupGalleryUser(destination);

        console.log('galleryUserResponse', galleryUserResponse);

        if (galleryUserResponse) {
            setIsGalleryUser(true);
        } else {
            setIsGalleryUser(false);
            setShouldPromptUserToJoin(true);
        }
    };

    useEffect(() => {
        if (!shouldPromptUserToJoin) {
            return;
        }

        if (isGalleryUser === undefined) {
            return;
        }

        if (promptedToJoin || isGalleryUser) {
            return;
        }

        setTimeout(() => {
            onPromptUserToJoin();
        }, 1000);

        setPromptedToJoin(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldPromptUserToJoin]);

    return { lookupGalleryUser, checkGalleryUser };
}
