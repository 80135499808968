import NonTrackingGalleryImage from './NonTrackingGalleryImage';
import '../../Gallery/components/Gallery.css';
import { useState } from 'react';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';
import ReplaceMedia from './ReplaceMedia';
import useGalleryBehaviors from '@/src/Gallery/components/hooks/useGalleryBehvaiors';

interface GalleryViewProps {
    media: MediaInstance[];
}

export default function GalleryView({ media }: GalleryViewProps) {
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const [replacedMediaId, setReplacedMediaId] = useState(-1);

    const advance = () => {
        if (expandedIndex < media.length) {
            setExpandedIndex(expandedIndex + 1);
        } else {
            setExpandedIndex(-1);
        }
    };

    const reverse = () => {
        setExpandedIndex(expandedIndex > 0 ? expandedIndex - 1 : -1);
    };

    const { swipeHandlers } = useGalleryBehaviors(
        setExpandedIndex,
        advance,
        reverse,
    );

    const authenticatedApi = useAuthenticatedApi();
    const toggleHidden = async (
        mediaId: number,
        isHidden: boolean,
    ): Promise<boolean> => {
        const toggleVisibility = async () => {
            await authenticatedApi.put(`media/${mediaId}/visibility`, {
                isHidden: !isHidden,
            });

            return !isHidden;
        };

        return await toggleVisibility();
    };

    const toggleFavorite = async (
        mediaId: number,
        isFavorite: boolean,
    ): Promise<boolean> => {
        const toggle = async () => {
            await authenticatedApi.put(`media/${mediaId}/favorite`, {
                isFavorite: !isFavorite,
            });

            return !isFavorite;
        };

        return await toggle();
    };

    return (
        <>
            <div className="Gallery-grid-container">
                <div {...swipeHandlers} className="Gallery-grid">
                    <div>
                        <div className="Grid-display manager">
                            {media.map((mediaInstance, index) => (
                                <NonTrackingGalleryImage
                                    key={mediaInstance.id}
                                    media={mediaInstance}
                                    isExpanded={expandedIndex === index}
                                    onClick={() =>
                                        setExpandedIndex(
                                            expandedIndex === index
                                                ? -1
                                                : index,
                                        )
                                    }
                                    onReplace={(mediaId: number) => {
                                        setReplacedMediaId(mediaId);
                                    }}
                                    onHide={(
                                        mediaId: number,
                                        isHidden: boolean,
                                    ) => toggleHidden(mediaId, isHidden)}
                                    onFavorite={(
                                        mediaId: number,
                                        isHidden: boolean,
                                    ) => toggleFavorite(mediaId, isHidden)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {replacedMediaId > -1 && (
                <ReplaceMedia
                    mediaId={replacedMediaId}
                    onComplete={(uri: string) => {
                        const mediaItem = media.find(
                            (m) => m.id === replacedMediaId,
                        );

                        if (mediaItem) {
                            mediaItem.originalUri = uri;
                        }

                        setReplacedMediaId(-1);
                    }}
                    onCancel={() => {
                        setReplacedMediaId(-1);
                    }}
                />
            )}
        </>
    );
}
