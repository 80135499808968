import React, { useEffect, useState } from 'react';
import '../../Common/components/Form.css';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router';
import { NavLink, useParams } from 'react-router-dom';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';
import useEditAccount from './hooks/useEditAccount';
import { Button } from '@/src/shadComponents/ui/button';

interface AccountObject {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    role: number;
}

export default function EditAccount() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [account, setAccount] = useState<AccountObject | null>(null);
    const authenticatedApi = useAuthenticatedApi();
    const { editAccount } = useEditAccount();
    const { id } = useParams();

    useEffect(() => {
        let isMounted = true;
        const getAccounts = async () => {
            try {
                const response: any = await authenticatedApi.get(
                    `/accounts/${id}`,
                );

                const { data } = response;

                const acccount: AccountObject = {
                    // @ts-ignore
                    id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                    username: data.username,
                    email: data.email,
                    role: data.role,
                };
                setAccount(acccount);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getAccounts();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <div>loading</div>;
    }

    if (!account) {
        return <div>account loading error, does it exist?</div>;
    }

    return (
        <div>
            <div className="Row-container">
                <h1>edit account</h1>
                <div className="Row-container-actions">
                    <span
                        onClick={() => navigate(`/accounts/${id}/password`)}
                        className="Tertiary-button"
                    >
                        [change password]
                    </span>
                </div>
            </div>
            <Formik
                initialValues={account}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await editAccount(values);
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate('/accounts');
                    }
                }}
            >
                <Form
                    className="Form"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                >
                    <div>
                        <label htmlFor="username">username</label>
                        <Field id="username" name="username" placeholder="" />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="email">email</label>
                        <Field
                            type="email"
                            id="email"
                            name="email"
                            placeholder="yeah@smilebooth.com"
                        />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="firstName">first name</label>
                        <Field id="firstName" name="firstName" placeholder="" />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="firstName">last name</label>
                        <Field id="lastName" name="lastName" placeholder="" />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="role">role</label>
                        <Field id="role" as="select" name="role" placeholder="">
                            <option value={2}>client</option>
                            <option value={1}>user</option>
                            <option value={0}>admin</option>
                        </Field>
                    </div>
                    <br />
                    <br />
                    <div className="Layout-row">
                        <Button type="submit" className="Primary-button">
                            save
                        </Button>
                        <NavLink to="/accounts">
                            <Button className="Secondary-button">cancel</Button>
                        </NavLink>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}
