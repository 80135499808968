import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

export default function TextAreaFieldType({ field }: { field: DocumentField }) {
    const [value, setValue] = useState<string>(field.value || '');
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        setFieldValue(field.name, value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, setFieldValue]);

    return (
        <>
            <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 width-full">
                    <textarea
                        rows={5}
                        name={field.name}
                        id={`${field.name}-field`}
                        value={value}
                        placeholder={field.placeholder}
                        onChange={(e) => setValue(e.target.value)}
                        className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
            {field.helperText ? (
                <p className="mt-3 text-sm leading-6 text-gray-600">
                    {field.helperText}
                </p>
            ) : null}
        </>
    );
}
