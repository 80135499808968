import { LazyLoadImage } from 'react-lazy-load-image-component';
import DefaultGalleryImage from './DefaultGalleryImage';

export default function RotatingPhoto({ urls }: { urls: PhotoInstance[] }) {
    if (urls.length === 0) {
        return <DefaultGalleryImage />;
    }

    const photo = urls[0];

    return (
        <div className="Rotating-image">
            {/*urls.map((photo, index) => {
                return (
                    <LazyLoadImage
                        src={
                            photo.mimeType === 'video/mp4'
                                ? photo.thumbnail
                                : photo.full
                        }
                        placeholderSrc={photo.thumbnail}
                        loading="lazy"
                        alt=""
                        draggable={false}
                        effect="opacity"
                        visibleByDefault={true}
                        placeholder={<DefaultGalleryImage />}
                    />
                );
            })*/}
            <LazyLoadImage
                src={
                    photo.mimeType === 'video/mp4'
                        ? photo.thumbnail
                        : photo.full
                }
                placeholderSrc={photo.thumbnail}
                loading="lazy"
                alt=""
                draggable={false}
                effect="opacity"
                visibleByDefault={true}
                placeholder={<DefaultGalleryImage />}
            />
        </div>
    );
}
