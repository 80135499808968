import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export default function useActivations() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const getActivations = async () => {
        const response = await authenticatedApi.get(`/${orgId}/activations`);
        return response.data;
    };

    return { getActivations };
}
