import { useParams } from 'react-router-dom';
import './Gallery.css';
import { useCallback, useMemo, useState } from 'react';
import GalleryShareContext from './GalleryShareContext';
import useSharingInteractions from './hooks/useSharingInteractions';
import SharingStationModal from './SharingStationModal';
import SharingStationGalleryView from './SharingStationGalleryView';
import GlobalStyle from '@/src/Share/components/GalleryTheme';

export default function SharingStation() {
    const { slug } = useParams();
    const {
        gallery,
        isLoading,
        photos,
        delayed,
        expandedIndex,
        setExpandedIndex,
        swipeHandlers,
        loadedPhotos,
        getNewPage,
    } = useSharingInteractions(String(slug));

    const [showShareModal, setShowShareModal] = useState(false);
    const [shareIdentifiers, setShareIdentifiers] = useState<string[]>([]);

    const [shareType, setShareType] = useState<ShareType | null>(null);
    const onShareButtonClick = useCallback(
        (shareIdentifier: string, type: 0 | 1 | 2 | undefined) => {
            setShareIdentifiers([shareIdentifier]);
            setTimeout(() => {
                setShowShareModal(true);
            }, 50);
            setShareType(type === undefined ? null : type);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [],
    );

    const context = useMemo(
        () => ({
            setShowShareModal: onShareButtonClick,
            setHideShareModal: () => setShowShareModal(false),
            setExpandedIndex,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (!gallery && !isLoading) {
        return (
            <div>
                <h3>gallery not found...</h3>
            </div>
        );
    }

    return (
        <GalleryShareContext.Provider value={context}>
            <div className="Gallery-view">
                <GlobalStyle
                    foregroundColor={gallery?.foregroundColor}
                    backgroundColor={gallery?.backgroundColor}
                    headerBackgroundColor={gallery?.headerBackgroundColor}
                    headerForegroundColor={gallery?.headerForegroundColor}
                />
                <SharingStationGalleryView
                    {...{
                        gallery,
                        isLoading,
                        photos,
                        delayed,
                        expandedIndex,
                        setExpandedIndex,
                        swipeHandlers,
                        loadedPhotos,
                        getNewPage,
                        galleryId: gallery?.id || 0,
                    }}
                />
                {gallery && showShareModal && (
                    <SharingStationModal
                        {...{
                            gallery,
                            shareIdentifiers,
                            setShowShareModal,
                            shareType,
                            setShareType,
                        }}
                    />
                )}
            </div>
        </GalleryShareContext.Provider>
    );
}
