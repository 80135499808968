import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';
import { Button } from '@/src/shadComponents/ui/button';
import { useFormik } from 'formik';

export default function AssetUpload({
    onSubmit,
}: {
    onSubmit: (values: { [key: string]: string }) => void;
}) {
    const { orgId } = useUserOrg();
    const formik = useFormik({
        initialValues: {
            file: '',
            folder: '',
            name: '',
            orgId: orgId.toString(),
        },
        onSubmit,
    });
    return (
        <div>
            <h1>new asset</h1>
            <form
                className="Form"
                onSubmit={formik.handleSubmit}
                encType="multpart/form-data"
            >
                <div className="Form-row">
                    <label>name&nbsp;</label>

                    <input
                        type="file"
                        name="asset"
                        accept="*/*"
                        onChange={(e) => {
                            formik.setFieldValue(
                                'asset',
                                e.currentTarget.files?.[0],
                            );
                        }}
                    />
                </div>
                <br />
                <div className="Form-row">
                    <label>name&nbsp;</label>
                    <input
                        type="text"
                        name="name"
                        onChange={(e) => {
                            formik.setFieldValue('name', e.currentTarget.value);
                        }}
                    />
                </div>
                <br />
                <div className="Form-row">
                    <label>folder&nbsp;</label>
                    <select
                        name="folder"
                        onChange={(e) => {
                            formik.setFieldValue(
                                'folder',
                                e.currentTarget.value,
                            );
                        }}
                    >
                        <option value="overlays">overlays</option>
                        <option value="samples">samples</option>
                    </select>
                </div>
                <br />
                <br />
                <Button type="submit" className="Primary-button">
                    upload
                </Button>
            </form>
        </div>
    );
}
