import moment from 'moment';
import RotatingPhoto from './RotatingPhoto';

interface GalleryThumbnailProps {
    urls: PhotoInstance[];
    title: string;
    slug: string;
    date: string;
}

export default function GalleryThumbnail({
    urls,
    title,
    slug,
    date,
}: GalleryThumbnailProps) {
    return (
        <div
            className="tilting-card-content clickable"
            onClick={() => {
                if (slug) {
                    var slash = '';
                    if (!window.location.href.endsWith('/')) {
                        slash = '/';
                    }
                    window.location.assign(
                        `${window.location.href}${slash}${slug}`,
                    );
                }
            }}
        >
            <div className="Gallery-preview-cell gallery-cell-preview">
                <RotatingPhoto urls={urls} />
                <div className="gallery-cell-title-container">
                    <span>{title}</span>
                    <span className="date">{moment(date).format('ll')}</span>
                </div>
            </div>
            <div className="mouse-position-tracker">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}
