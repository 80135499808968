import { useEffect, useRef, useState } from 'react';
import useGallery from './useGallery';

export default function useSlideshow(slug: string) {
    const [isLoading, setIsLoading] = useState(true);
    const [gallery, setGallery] = useState<GalleryObject | null>(null);
    const photos = useRef<MediaInstance[]>([]);

    const { getGallery, getActivationPhotos } = useGallery({
        slug,
        galleryId: gallery?.id || 0,
    });

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const gallery = await getGallery();
                const galleryPhotos = await getActivationPhotos(
                    gallery.activationId,
                );

                setGallery(gallery);
                photos.current = galleryPhotos;
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        let interval = setInterval(() => {
            const fetchGallery = async () => {
                try {
                    const newPhotos = await getActivationPhotos(
                        gallery?.activationId || 0,
                    );

                    isMounted && (photos.current = newPhotos);
                } catch (e) {
                    console.error(e);
                } finally {
                    isMounted && setIsLoading(false);
                }
            };
            fetchGallery();
        }, 5 * 1000);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gallery?.activationId]);

    return {
        photos,
        isLoading,
        gallery,
    };
}
