import ShareGalleryView from '@/src/Gallery/components/ShareGalleryView';
import useShareGalleryInteractions from './hooks/useShareGalleryInteractions';
import { useCallback, useEffect, useRef, useState } from 'react';
import ShareSheet from './ShareSheet';
import useGalleryUser from './hooks/useGalleryUser';
import CreateUserAccountModal from '@/src/Gallery/components/CreateUserAccountModal';

interface ShareGalleryProps {
    galleryPhotos: any;
    isAllMediaInCloud: boolean;
    isSharingComplete: boolean;
    personalGalleryLink: string | null;
    onReset?: () => void;
    galleryInfo: GalleryObject | null;
    children: React.ReactNode;
}

export default function ShareGallery({
    galleryPhotos,
    isAllMediaInCloud,
    isSharingComplete,
    onReset,
    children,
    personalGalleryLink,
    galleryInfo,
}: ShareGalleryProps) {
    const {
        gallery,
        expandedIndex,
        setExpandedIndex,
        swipeHandlers,
        loadedPhotos,
        getNewPage,
    } = useShareGalleryInteractions(galleryPhotos);

    const [showShareModal, setShowShareModal] = useState(false);
    const [showJoinModal, setShowJoinModal] = useState(false);

    const showShareSheetButton = isAllMediaInCloud && gallery.length === 1;

    const isSharingEnabled =
        galleryInfo?.emailEnabled || galleryInfo?.textEnabled;

    const message =
        isSharingComplete && personalGalleryLink ? (
            <span className="share-message">
                Hey there! We've added all of these photos to your{' '}
                <a href={personalGalleryLink}>Smilebooth gallery</a>. <br />
                {!isAllMediaInCloud
                    ? `Some of the photos may not be on the cloud just yet, but we'll let you know when they are.`
                    : ''}
                <br />
                <span>
                    <span onClick={() => onReset && onReset()} className="link">
                        Click here
                    </span>{' '}
                    to share to another destination.
                </span>
            </span>
        ) : !isAllMediaInCloud ? (
            `Hey there! Not all the photos have made it to the cloud yet,
                    use the button below and we'll send them your way once
                    they're ready!`
        ) : (
            ''
        );

    const firstPhoto = gallery[0];

    const triggerPrompt = useCallback(() => {
        setShowJoinModal(true);
    }, []);

    const { checkGalleryUser } = useGalleryUser(galleryInfo, triggerPrompt);

    useUpdateEffect(() => {
        setTimeout(() => {
            if (!galleryInfo) {
                return;
            }

            if (!isSharingComplete) {
                return;
            }

            if (!galleryInfo?.displayGalleryUserSignup) {
                return;
            }

            let previousShareDestination = JSON.parse(
                // @ts-ignore
                localStorage.getItem(`sb-share-destination-${galleryInfo?.id}`),
            );

            if (!previousShareDestination) {
                return;
            }

            checkGalleryUser(previousShareDestination);
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryInfo?.id, isSharingComplete]);

    return (
        <div className="Gallery-view">
            <ShareGalleryView
                {...{
                    gallery: galleryInfo || null,
                    photos: gallery,
                    expandedIndex,
                    setExpandedIndex,
                    swipeHandlers,
                    loadedPhotos,
                    getNewPage,
                    message,
                }}
            >
                <div className="relative p-6">
                    <div className="code-container">
                        {isSharingComplete || expandedIndex > -1 ? null : (
                            <div className="row">
                                {showShareSheetButton ? (
                                    <ShareSheet
                                        source="share"
                                        uri={firstPhoto.originalUri}
                                        mimeType={firstPhoto.mimeType}
                                        mediaId={firstPhoto.id}
                                    >
                                        <span className="cta-button">
                                            Download
                                        </span>
                                    </ShareSheet>
                                ) : null}
                                {isSharingEnabled && (
                                    <span
                                        onClick={() => setShowShareModal(true)}
                                        className="cta-button"
                                    >
                                        {!isAllMediaInCloud
                                            ? 'Send me photos! '
                                            : 'Save for Later'}
                                        &nbsp;
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </ShareGalleryView>

            {showShareModal && !isSharingComplete && (
                <div className="Fullscreen-modal">
                    <div
                        className="close-modal"
                        onClick={() => setShowShareModal(false)}
                    >
                        <i className="bi bi-x"></i>
                    </div>
                    {children}
                </div>
            )}

            {showJoinModal && (
                <CreateUserAccountModal
                    galleryId={galleryInfo?.id || 0}
                    onDismiss={() => {
                        setShowJoinModal(false);
                    }}
                />
            )}
        </div>
    );
}

function useUpdateEffect(
    effect: React.EffectCallback,
    deps: React.DependencyList,
) {
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
        } else {
            return effect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
