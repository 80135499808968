import { useEffect, useState } from 'react';
import usePersonalGallery from './usePersonalGallery';
import useShare from './useShare';

export default function useCreateShare(
    onShare: (destination: string, identifier: string, id: number) => void,
    setIsSharing: (isSharing: boolean) => void,
    defaultDestination: string,
    galleryInfo: GalleryObject | null,
    type: ShareType,
    wasAlreadyShared: boolean = false,
    shareIdentifiers: string[] = [],
) {
    const { slug } = usePersonalGallery(defaultDestination);
    const [personalGalleryLink, setPersonalGalleryLink] = useState(slug);

    const { createShare } = useShare();

    const shareCallback = async (values: CreateShareFormValues) => {
        setIsSharing(true);

        const { personalGallerySlug: slug, id } = await createShare(values);

        setTimeout(() => {
            setIsSharing(false);
        }, 1000);

        onShare(values.destination, shareIdentifiers.toString(), id);

        if (slug) {
            setPersonalGalleryLink(`/photos/${slug}`);
        }
    };

    const defaultShareValues: CreateShareFormValues = {
        destination: defaultDestination,
        type,
        activationId: galleryInfo?.activationId || 0,
        galleryId: galleryInfo?.id || 0,
        shareIdentifiers,
        disclaimerAccepted: galleryInfo?.disclaimerOptOut || false,
    };

    useEffect(() => {
        if (slug) {
            setPersonalGalleryLink(`/photos/${slug}`);
        }
    }, [slug]);

    useEffect(() => {
        if (wasAlreadyShared) {
            return;
        }

        if (defaultDestination && galleryInfo?.id) {
            shareCallback(defaultShareValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryInfo]);

    return {
        defaultShareValues,
        shareCallback,
        personalGalleryLink,
    };
}
