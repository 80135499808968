import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import JobForm from './JobForm';
import useCreateJob from './hooks/useCreateJob';

export default function CreateJob() {
    const navigate = useNavigate();

    const job = {
        id: 1,
        name: '',
        startDate: null,
        endDate: null,
        type: null,
        ownerId: null,
        clientIds: [],
    };

    const { createJob } = useCreateJob();

    // @ts-ignore
    const defaultSelected: DateRange = {
        from: undefined,
        to: undefined,
    };
    const [range, setRange] = useState<DateRange | undefined>(defaultSelected);

    return (
        <JobForm
            job={job}
            range={range}
            setRange={setRange}
            onCancel={() => navigate(-1)}
            onSubmit={async (job: any) => {
                if (await createJob(job)) {
                    toast.success('Job Created');
                    setTimeout(() => {
                        navigate(-1);
                    }, 1000);
                }
            }}
        />
    );
}
