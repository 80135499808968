import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import Loading from '@/src/Common/components/Loading';
import { useParams } from 'react-router-dom';
import useOrgs from './hooks/useOrgs';
import OrgAccounts from './OrgAccounts';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export default function OrgAdministration() {
    const [org, setOrg] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(true);
    const { getOrg } = useOrgs();
    const { orgId } = useParams();
    const { setOrg: setAccountOrg, orgId: currentOrgId } = useUserOrg();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                // @ts-ignore
                const org = await getOrg(+orgId || 0);

                setOrg(org);

                setTimeout(() => isMounted && setIsLoading(false), 0);
            } catch (error) {
                console.error(error);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    if (!org) {
        return <div>Org not found</div>;
    }

    return (
        <main>
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        {org.client}
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    {org.name}
                                </div>
                                <div className="mt-1 text-sm leading-6 text-green-600 font-semibold">
                                    {org.id === currentOrgId && 'Current Org'}
                                </div>

                                <div className="mt-1 text-sm font-thin leading-6 text-gray-900">
                                    <div className="flex items-center gap-x-1"></div>
                                </div>
                                <div className="mt-1 text-sm font-thin leading-6 text-gray-900">
                                    <div className="flex items-center gap-x-1 align-start">
                                        <span className=""></span>
                                    </div>
                                </div>
                            </h1>
                        </div>

                        <div className="flex items-center gap-x-4 sm:gap-x-6">
                            {org.id !== currentOrgId && (
                                <button
                                    onClick={() => {
                                        setAccountOrg(org);
                                    }}
                                    className="hidden text-sm font-semibold leading-6 sm:block bg-black px-3 py-2 text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                                >
                                    Switch To Org
                                    <i className="bi bi-shield-lock pl-2" />
                                </button>
                            )}
                            <Menu as="div" className="relative sm:hidden">
                                <Menu.Button className="-m-3 block p-3">
                                    <span className="sr-only">More</span>
                                    <EllipsisVerticalIcon
                                        className="h-5 w-5 text-gray-500"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    className={classNames(
                                                        active
                                                            ? 'bg-gray-50'
                                                            : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                    )}
                                                >
                                                    Edit Details
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
            </header>
            <OrgAccounts />
        </main>
    );
}
