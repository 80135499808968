import { useEffect, useState } from 'react';
import { ProcessingFlow, Processor } from './useProcessing';
import useFlowStore from './useFlowStore';

export default function useFlowEditor(
    flow: ProcessingFlow,
    processors: Processor[],
) {
    const [definition, setDefinition] = useState<Processor[]>(
        flow.flowDefinition ? JSON.parse(flow.flowDefinition) : [],
    );

    const {
        flow: flowDefinition,
        loadFlow,
        addStep: addStepToStore,
        updateStep: updateStepInStore,
        removeStep: removeStepFromStore,
        moveStep: moveStepInStore,
    } = useFlowStore();

    useEffect(() => {
        loadFlow(definition);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addStep = (name: string) => {
        const processor = processors.find(
            (processor) => processor.name === name,
        );

        if (processor) {
            addStepToStore(processor);
        }
    };

    const removeStep = (index: number) => {
        removeStepFromStore(index);
    };

    const insertStep = (index: number, name: string) => {
        const processor = processors.find(
            (processor) => processor.name === name,
        );

        if (processor) {
            const newDefinition = [...definition];
            newDefinition.splice(index, 0, structuredClone(processor));
            setDefinition(newDefinition);
        }
    };

    const moveStep = (from: number, to: number) => {
        moveStepInStore(from, to);
    };

    const updateStep = (step: any) => {
        updateStepInStore(step);
    };

    const replaceFlow = (newFlowDefinition: Processor[]) => {
        loadFlow(newFlowDefinition);
    };

    return {
        addStep,
        removeStep,
        definition: flowDefinition,
        insertStep,
        moveStep,
        updateStep,
        replaceFlow,
    };
}
