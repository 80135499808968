import { useState, useEffect } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export function useDeviceGroupList(orgId: number) {
    const authenticatedApi = useAuthenticatedApi();
    const [deviceGroups, setDeviceGroups] = useState<DeviceGroup[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchDeviceGroups = async () => {
            try {
                const results = await authenticatedApi.get(
                    `${orgId}/device-groups`,
                );

                setDeviceGroups(results.data);
            } catch (e: any) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDeviceGroups();
    }, [orgId, authenticatedApi]);

    return {
        deviceGroups,
        isLoading,
        error,
    };
}
