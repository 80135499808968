import api from '../../../services/api';

export default function usePersonalGallery() {
    const getGallery = async (slug: string) => {
        const response = await api.get(`/public/galleries/personal/${slug}`);

        const { data } = response;
        return data;
    };

    const sendGalleryLink = async (destination: string) => {
        await api.post(`/share/lookup-slug`, {
            destination,
        });

        return true;
    };

    return {
        getGallery,
        sendGalleryLink,
    };
}
