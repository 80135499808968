import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useDeviceKeys from './hooks/useDeviceKeys';
import { Button } from '@/src/shadComponents/ui/button';
import useUserOrg from '../../Authentication/components/hooks/useUserOrg';

interface CreateDeviceKeyFormValues {
    name: string;
    keyId: string;
    secretAccessKey: string;
}

export default function CreateDeviceKey() {
    const { orgId } = useUserOrg();
    const navigate = useNavigate();
    const [isGeneratingKey, setIsGeneratingKey] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [keyPair, setKeyPair] = useState<{
        keyId: string;
        secretAccessKey: string;
    } | null>(null);
    const { generateDeviceKey, addDeviceKey } = useDeviceKeys(orgId);

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            const keyPair = await generateDeviceKey();

            if (isMounted) {
                setKeyPair(keyPair);
                setIsGeneratingKey(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isGeneratingKey) {
        return <div>generating key pair...</div>;
    }

    const initialValues: CreateDeviceKeyFormValues = {
        name: '',
        keyId: keyPair?.keyId || '',
        secretAccessKey: keyPair?.secretAccessKey || '',
    };

    return (
        <div>
            <div className="Row-container">
                <h1>create device key</h1>
            </div>
            <p>
                device keys are downloaded automatically upon creation, <br />
                the secret key cannot be retrieved after leaving this page
            </p>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await addDeviceKey(values);
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        const element = document.createElement('a');
                        const configFile = new Blob([JSON.stringify(values)], {
                            type: 'text/plain',
                        });
                        element.href = URL.createObjectURL(configFile);
                        element.download = `${values.name}_device_key.json`;
                        document.body.appendChild(element);
                        element.click();

                        navigate('/device-management#keys');
                    }
                }}
            >
                <Form
                    className="Form"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                >
                    <div>
                        <label htmlFor="name">device name</label>
                        <Field id="name" name="name" placeholder="" />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="keyId">key id</label>
                        <Field
                            id="keyId"
                            name="keyId"
                            placeholder=""
                            readOnly
                        />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="secretAccessKey">secretAccessKey</label>
                        <Field
                            id="secretAccessKey"
                            name="secretAccessKey"
                            placeholder=""
                            type={showPassword ? 'textbox' : 'password'}
                            readOnly
                        />
                        <i
                            onClick={() => {
                                setShowPassword((value) => !value);
                            }}
                            className={`bi ${
                                showPassword ? 'bi-eye-slash' : 'bi-eye'
                            } button left-padding`}
                        ></i>
                    </div>
                    <br />
                    <br />
                    <div className="Layout-row">
                        <Button type="submit" className="Primary-button">
                            create device key
                        </Button>
                        <NavLink to="/device-management#keys">
                            <Button className="Secondary-button">cancel</Button>
                        </NavLink>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}
