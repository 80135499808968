import './LoadingIndicator.css';

export default function LoadingIndicator({
    color = 'black',
}: {
    color?: string;
}) {
    return (
        <div className="Loading-indicator">
            <div className={`lds-ellipsis`}>
                <div style={{ background: color }}></div>
                <div style={{ background: color }}></div>
                <div style={{ background: color }}></div>
                <div style={{ background: color }}></div>
            </div>
        </div>
    );
}
