import moment from 'moment';
import Table, { createColumnHelper } from '../../Common/components/Table';
// import { useState } from 'react';
// import useV3Folders from './hooks/useV3Folders';
import useV3Sync from './hooks/useV3Sync';
import V3Actions from './V3Actions';
import { Button } from '@/src/shadComponents/ui/button';

const columnHelper = createColumnHelper<V3ImportedFolder>();

export default function V3Sync() {
    const {
        syncAllMedia,
        syncAllFolders,
        syncStats,
        foldersNeverSyncd,
        repairAndSyncFolder,
        feedback,
        fixOldVideoThumbnails,
    } = useV3Sync();

    const columnDefinitions = [
        columnHelper.accessor('folder', {
            id: 'folder',
            header: () => <span>folder</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.accessor('portal', {
            id: 'portal',
            header: () => <span>portal</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        columnHelper.accessor('date', {
            id: 'date',
            header: () => <span>date</span>,
            cell: (info) => <span>{info.getValue().split('T')[0]}</span>,
        }),
        columnHelper.display({
            id: 'actions',
            header: () => <span>repair</span>,
            cell: (props: { row: any }) => (
                <V3Actions
                    row={props}
                    onRepair={() => {
                        repairAndSyncFolder(
                            props.row.original.portal,
                            props.row.original.folder,
                        );
                    }}
                />
            ),
        }),
    ];

    return (
        <div>
            <h1>v3 cloud sync</h1>
            <div className="row">
                <Button
                    className="Primary-button"
                    onClick={() => syncAllFolders()}
                    disabled={syncStats?.isBulkFolderSyncRunning}
                >
                    sync all folders
                    {syncStats?.isBulkFolderSyncRunning && (
                        <i className="bi bi-hourglass-split" />
                    )}
                </Button>
                <Button
                    className="Primary-button"
                    onClick={() => syncAllMedia()}
                    disabled={syncStats?.isBulkMediaSyncRunning}
                >
                    sync all media
                    {syncStats?.isBulkMediaSyncRunning && (
                        <i className="bi bi-hourglass-split" />
                    )}
                </Button>
                <Button
                    className="Primary-button"
                    onClick={() => fixOldVideoThumbnails()}
                >
                    video thumbnail fix
                </Button>
            </div>

            <div className="Stat-section">
                <h2>sync stats </h2>
                <div className="Layout-row">
                    <div className="Stat-column">
                        <div className="Stat-row">
                            <div className="label">
                                total v3 folders registered:
                            </div>
                            <div className="value">
                                {syncStats?.syncedFolderCount}
                            </div>
                        </div>

                        <div className="Stat-row">
                            <div className="label">never sync'd:</div>
                            <div className="value">
                                {syncStats?.neverSyncedCount}
                            </div>
                        </div>

                        <div className="Stat-row">
                            <div className="label">sync'd percentage:</div>
                            <div className="value">
                                {syncStats
                                    ? 100 -
                                      Math.round(
                                          (syncStats?.neverSyncedCount /
                                              syncStats?.syncedFolderCount) *
                                              100,
                                      )
                                    : '-'}
                                {''}%
                            </div>
                        </div>

                        <div className="Stat-row">
                            <div className="label">automatically sync'd:</div>
                            <div className="value">
                                {syncStats?.automaticSyncCount}
                            </div>
                        </div>
                        <div className="Stat-row">
                            <div className="label">last sync:</div>
                            <div className="value">
                                {syncStats?.lastSync
                                    ? moment(syncStats.lastSync).format('lll')
                                    : '-'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Stat-section">
                <h2>never sync'd ({foldersNeverSyncd.length})</h2>

                {feedback ? (
                    <div className="feedback">
                        {feedback}
                        <br />
                        <br />
                    </div>
                ) : null}
                <Table
                    data={foldersNeverSyncd}
                    columns={columnDefinitions}
                    enablePagination={true}
                />
            </div>
        </div>
    );
}
