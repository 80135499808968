import { useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import { useFormik } from 'formik';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';
import { Button } from '@/src/shadComponents/ui/button';

export default function ReplaceMedia({ mediaId, onComplete, onCancel }: any) {
    const [isLoading, setIsLoading] = useState(false);

    const { put } = useAuthenticatedApi();

    const replaceMedia = async (values: { [key: string]: string }) => {
        const formData = new FormData();
        for (let value in values) {
            formData.append(value, values[value]);
        }

        const response = await put(`/media/replace/${mediaId}`, formData);

        const { data } = response;

        return data;
    };

    const formik = useFormik({
        initialValues: {
            photo: '',
        },
        onSubmit: async (...args) => {
            setIsLoading(true);
            // @ts-ignore
            const response = await replaceMedia(...args);
            onComplete(response.originalUri);
            setIsLoading(false);
        },
    });

    return (
        <div className="Asset-modal-container">
            <div className="modal">
                <form
                    className="Form test-form"
                    onSubmit={formik.handleSubmit}
                    encType="multpart/form-data"
                >
                    <div className="row">
                        <div className="form">
                            <div className="column">
                                <h2>Replace Photo</h2>

                                <div className="Form-row">
                                    <label>file&nbsp;</label>

                                    <input
                                        type="file"
                                        name="photo"
                                        accept="*/*"
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'photo',
                                                e.currentTarget.files?.[0],
                                            );
                                        }}
                                    />
                                </div>
                                <div className="spacer" />
                            </div>
                        </div>
                    </div>
                    <div className="row bottom-row">
                        <div className="spacer" />
                        <Button type="submit" className="Primary-button">
                            replace
                            {isLoading ? <LoadingIndicator /> : null}
                        </Button>
                        <Button onClick={onCancel} className="Secondary-button">
                            close
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
