import React from 'react';
import { Link } from 'react-router-dom';

interface FeatureFlagActionsProps {
    row: any;
}

export default function RoleFeatureFlagAssociationActions({
    row,
}: FeatureFlagActionsProps) {
    const name = row.original.name;
    const roleId = row.original.roleId;

    return (
        <span className="Table-options">
            <Link to={`role/${name}/${roleId}`}>
                <i className="bi bi-pencil-fill"></i>
            </Link>
        </span>
    );
}
