import logo from './white_logo.png';
import Loading from '../../Common/components/Loading';
import useSlideshowBehaviors from './hooks/useSlideshowBehaviors';
import { MediaWrapper } from './SlideShowPreviewImage';
import { useState } from 'react';

interface SlideshowViewerProps {
    isLoading: boolean;
    gridWidth: number;
    gridHeight: number;
    gallery: GalleryObject;
    gridGap: number;
    rotationSpeed: number;
    photos: any;
    displayHeader: boolean;
    displayNewest: boolean;
}

export default function SlideshowViewer({
    isLoading,
    gallery,
    photos,
    gridWidth,
    gridHeight,
    gridGap,
    rotationSpeed,
    displayHeader,
    displayNewest,
}: SlideshowViewerProps) {
    const { displayPhotos, previousPhotos } = useSlideshowBehaviors(
        gridWidth * gridHeight,
        photos,
        rotationSpeed,
    );

    const [newestPhoto, setNewestPhoto] = useState<string | null>(null);

    console.log('Display photos', displayPhotos);
    const previousRows = [];
    for (let i = 0; i < previousPhotos.length; i += gridWidth) {
        const row = previousPhotos.slice(i, i + gridWidth);
        previousRows.push(row);
    }

    const rows = [];
    for (let i = 0; i < displayPhotos.current.length; i += gridWidth) {
        let row = displayPhotos.current.slice(i, i + gridWidth);
        if (
            displayNewest &&
            i === 0 &&
            photos.current[0].originalUri !== newestPhoto
        ) {
            row[0] = photos.current[0];
            const newest = row[0];
            setNewestPhoto(newest.originalUri);
        }

        rows.push(row);
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            {!isLoading && (
                <>
                    {displayHeader ? (
                        <div className="Gallery-header">
                            <div className="Gallery-header-title">
                                <img
                                    src={gallery?.logo || logo}
                                    alt="Smilebooth Logo"
                                />
                            </div>
                            <div className="Gallery-name">{gallery.name}</div>
                        </div>
                    ) : null}

                    <div
                        className={`Gallery-content pb-[20px] relative`}
                        style={{
                            top: displayHeader ? '62px' : '0',
                            height: `calc(100% - ${
                                displayHeader ? '62px' : '0'
                            })`,
                        }}
                        id="galleryContainer"
                    >
                        <div
                            className={`Slideshow-grid-container flex h-full  absolute z-10 max-w-full overflow-hidden`}
                        >
                            {rows.map((row, rowIndex) => (
                                <div key={rowIndex} className={`flex-1`}>
                                    {row.map((photo, photoIndex) => (
                                        <div
                                            style={{
                                                padding: `${gridGap}px`,
                                            }}
                                            key={photo.originalUri}
                                        >
                                            <MediaWrapper
                                                key={photoIndex}
                                                media={photo}
                                                isExpanded={true}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div
                            className={`Slideshow-grid-container flex  absolute z-10 max-w-full overflow-hidden`}
                        >
                            {previousRows.map((row, rowIndex) => (
                                <div key={rowIndex} className={`flex-1`}>
                                    {row.map((photo, photoIndex) => (
                                        <div
                                            style={{
                                                padding: `${gridGap}px`,
                                            }}
                                            className="starting-visible fade-out-5"
                                            key={photo.originalUri}
                                        >
                                            <MediaWrapper
                                                key={photoIndex}
                                                media={photo}
                                                isExpanded={true}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
