import { useEffect, useState } from 'react';
import Table, { createColumnHelper } from '../../Common/components/Table';
import GalleryDeviceAssignments from '../../DeviceManagement/components/GalleryDeviceAssignments';
import GalleryActions from './GalleryActions';
import useGetGalleries from './hooks/useGetGalleries';
import Loading from '@/src/Common/components/Loading';
import GalleryDeviceGroupAssignments from '@/src/DeviceManagement/components/GalleryDeviceGroupAssignments';

interface GalleryListProps {
    activationId: number;
}

interface GalleryObject {
    id: number;
    name: string;
    isPrivate: boolean;
    totalViews: number;
    uniqueViews: number;
}

const columnHelper = createColumnHelper<GalleryObject>();

export default function GalleryList({ activationId }: GalleryListProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [galleries, setGalleries] = useState<GalleryObject[]>([]);
    const { getGalleriesForActivation } = useGetGalleries();

    const columnDefinitions = [
        columnHelper.accessor('name', {
            id: 'name',
            header: () => <span>Name</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                    {info.row.original.isPrivate ? (
                        <div className="center">
                            <i className="bi bi-lock-fill"></i>
                        </div>
                    ) : null}
                </div>
            ),
        }),
        columnHelper.display({
            id: 'devices',
            header: () => <span>Devices</span>,
            cell: (props: { row: any }) => (
                <GalleryDeviceAssignments galleryId={props.row.original.id} />
            ),
        }),
        columnHelper.display({
            id: 'devices',
            header: () => <span>Groups</span>,
            cell: (props: { row: any }) => (
                <GalleryDeviceGroupAssignments
                    galleryId={props.row.original.id}
                />
            ),
        }),
        columnHelper.accessor('totalViews', {
            id: 'totalViews',
            header: () => <span>Total Visits</span>,
            cell: (info) => <div className="center">{info.getValue()}</div>,
        }),
        columnHelper.accessor('uniqueViews', {
            id: 'uniqueViews',
            header: () => <span>Unique Visitors</span>,
            cell: (info) => <div className="center">{info.getValue()}</div>,
        }),
        columnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => <GalleryActions row={props.row} />,
        }),
    ];

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const galleries = await getGalleriesForActivation(activationId);
                setGalleries(galleries);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div>
            <Table data={galleries} columns={columnDefinitions} />
        </div>
    );
}
