import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../Common/components/Table';

const columnHelper = createColumnHelper<any>();

interface MediaStatsTableProps {
    mediaStats: any[];
}

export default function MediaStatsTable({ mediaStats }: MediaStatsTableProps) {
    const columns = [
        columnHelper.accessor('uploadDate', {
            id: 'uploadDate',
            header: () => <span>date</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('total', {
            id: 'total',
            header: () => <span>total count</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('photo', {
            id: 'photo',
            header: () => <span>processed</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('video', {
            id: 'video',
            header: () => <span>videos</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('original', {
            id: 'original',
            header: () => <span>originals</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('edited', {
            id: 'edited',
            header: () => <span>edited</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('prints', {
            id: 'prints',
            header: () => <span>prints</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('other', {
            id: 'other',
            header: () => <span>other</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
    ];

    return <Table columns={columns} data={mediaStats} />;
}
