import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export default function useSurveyQuestions() {
    const authenticatedApi = useAuthenticatedApi();
    const getSurveyQuestions = async (activationId: number) => {
        const response = await authenticatedApi.get(
            `/surveys/activation/${activationId}/questions`,
        );

        const { data } = response;

        return data;
    };

    return {
        getSurveyQuestions,
    };
}
