import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../Common/components/Table';

const columnHelper = createColumnHelper<any>();

interface ShareStatsTableProps {
    shareStats: any[];
}

export default function ShareStatsTable({ shareStats }: ShareStatsTableProps) {
    const columns = [
        columnHelper.accessor('shareDate', {
            id: 'shareDate',
            header: () => <span>date</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('total', {
            id: 'total',
            header: () => <span>total count</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('text', {
            id: 'text',
            header: () => <span>text count</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => <span>email count</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
    ];

    return <Table columns={columns} data={shareStats} />;
}
