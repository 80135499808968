import { useReducer } from 'react';

export default function useFlowStore() {
    const [flow, dispatch] = useReducer(flowReducer, []);

    const loadFlow = (flow: any) => {
        dispatch({ type: actions.LOAD, payload: flow });
    };

    const addStep = (step: any) => {
        dispatch({ type: actions.ADD_STEP, payload: step });
    };

    const removeStep = (index: number) => {
        dispatch({ type: actions.REMOVE_STEP, payload: index });
    };

    const updateStep = (step: any) => {
        dispatch({ type: actions.UPDATE_STEP, payload: step });
    };

    const moveStep = (from: number, to: number) => {
        dispatch({ type: actions.MOVE_STEP, payload: { from, to } });
    };

    return {
        flow,
        loadFlow,
        addStep,
        removeStep,
        updateStep,
        moveStep,
    };
}

const actions = {
    LOAD: 'LOAD',
    ADD_STEP: 'ADD_STEP',
    REMOVE_STEP: 'REMOVE_STEP',
    UPDATE_STEP: 'UPDATE_STEP',
    MOVE_STEP: 'MOVE_STEP',
};

type FlowAction = {
    type: string;
    payload: any;
};

const flowReducer = (state: any = [], action: FlowAction) => {
    switch (action.type) {
        case actions.LOAD:
            console.log('LOAD', action.payload);
            return action.payload;
        case actions.ADD_STEP:
            console.log('ADD_STEP', action.payload);
            return [...state, action.payload];
        case actions.REMOVE_STEP:
            console.log('REMOVE_STEP', action.payload);
            return state.filter(
                (_step: any, index: number) => index !== action.payload,
            );
        case actions.MOVE_STEP:
            console.log('MOVE_STEP', action.payload);
            console.log('state', state);
            const newDefinition = [...state];
            const [removed] = newDefinition.splice(action.payload.from, 1);
            newDefinition.splice(action.payload.to, 0, removed);

            console.log('new state', newDefinition);
            return [...newDefinition];
        case actions.UPDATE_STEP:
            console.log('UPDATE_STEP', action.payload);
            return state.map((step: any, index: number) => {
                if (index === action.payload.index) {
                    return action.payload;
                }
                return step;
            });
        default:
            return state;
    }
};
