import React from 'react';
import '../../Common/components/Form.css';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router';
import { NavLink, useParams } from 'react-router-dom';
import useEditAccount from './hooks/useEditAccount';
import { Button } from '@/src/shadComponents/ui/button';

interface PasswordObject {
    password: string;
    confirmPassword: string;
}

export default function SetPassword() {
    const navigate = useNavigate();
    const { setPassword } = useEditAccount();
    const { id } = useParams();

    const passwordObject: PasswordObject = {
        password: '',
        confirmPassword: '',
    };

    const validate = (values: PasswordObject) => {
        const errors: any = {};
        const { password, confirmPassword } = values;
        if (!password) {
            errors.password = 'required';
        } else if (!confirmPassword) {
            errors.confirmPassword = 'required';
        } else if (password !== confirmPassword) {
            errors.password = 'passwords must match';
            errors.confirmPassword = 'passwords must match';
        }
        return errors;
    };

    return (
        <div>
            <div className="Row-container">
                <h1>change password</h1>
            </div>
            <Formik
                validate={validate}
                initialValues={passwordObject}
                onSubmit={async (values, actions) => {
                    const { password } = values;

                    const wasSuccessful = await setPassword(
                        parseInt(`${id}`, 10),
                        password,
                    );

                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate('/accounts');
                    }
                }}
            >
                {({ errors, touched }) => (
                    <Form
                        className="Form"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div>
                            <label htmlFor="password">password</label>
                            <Field
                                id="password"
                                name="password"
                                placeholder=""
                            />
                        </div>
                        {errors.password && touched.password && (
                            <div className="Form-error">{errors.password}</div>
                        )}
                        <br />
                        <div>
                            <label htmlFor="confirmPassword">
                                confirm password
                            </label>
                            <Field
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder=""
                            />
                        </div>

                        {errors.confirmPassword && touched.confirmPassword && (
                            <div className="Form-error">
                                {errors.confirmPassword}
                            </div>
                        )}
                        <br />
                        <br />
                        <div className="Layout-row">
                            <Button type="submit" className="Primary-button">
                                save
                            </Button>
                            <NavLink to="/accounts">
                                <Button className="Secondary-button">
                                    cancel
                                </Button>
                            </NavLink>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
