import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export default function useAccounts() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const getAccounts = async () => {
        const response = await authenticatedApi.get(`${orgId}/accounts`);
        return response.data;
    };

    return {
        getAccounts,
    };
}
