import React from 'react';

type Org = { id: number; name: string; logo: string };
export interface OrgContextType {
    org: Org;
    setOrg: (orgId: number) => void;
}

const OrgContext = React.createContext<OrgContextType>(null!);

export default OrgContext;
