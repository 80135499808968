import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

interface DropdownMenuProps {
    title?: string;
    titleIcon?: React.ReactNode;
    children: React.ReactNode;
}

export default function DropdownMenu({
    title,
    titleIcon,
    children,
}: DropdownMenuProps) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex justify-center w-full px-2 py-3 text-sm font-medium text-black bg-white rounded-md hover:bg-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    {title}
                    {titleIcon}
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Menu.Items className="z-50 absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none zIndex-50">
                    {React.Children.map(children, (child) => (
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {
                                    // @ts-ignore
                                    React.cloneElement(child, {})
                                }
                            </Menu.Item>
                        </div>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
