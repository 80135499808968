import { useEffect, useState } from 'react';
import useQueryString from '../../../Common/components/hooks/useQueryString';
import api from '../../../services/api';

export default function useShareGallery() {
    const query = useQueryString();
    const shareIdentifiers = query
        .getAll('shareIdentifier')
        .map((item) => item.split(','))
        .flat();
    const [gallery, setGallery] = useState<any[]>([]);
    const [isLoadingMedia, setIsLoadingMedia] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const getMedia = async () => {
            const media = [];
            for (let shareIdentifier of shareIdentifiers) {
                try {
                    const result = await api.get(
                        `/public/media/${encodeURIComponent(shareIdentifier)}`,
                    );

                    if (result.status === 200) {
                        media.push(result.data);
                    }
                } catch (e) {
                    // Media not found
                }
            }

            isMounted && setGallery(media);
            setIsLoadingMedia(false);
        };

        getMedia();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        gallery,
        isAllMediaInCloud: gallery.length === shareIdentifiers.length,
        isLoadingMedia,
    };
}
