import logo from './white_logo.png';

export default function DefaultGalleryImage() {
    return (
        <div className="Gallery-preview-cell gallery-cell-preview">
            <div className="gallery-cell-placeholder-container">
                <img src={logo} alt="Smilebooth Logo" />
            </div>
        </div>
    );
}
