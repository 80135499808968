import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import useRefreshToken from './hooks/useRefreshToken';

export default function PersistLogin() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();

    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>{!persist ? <Outlet /> : isLoading ? <p>Loading</p> : <Outlet />}</>
    );
}
