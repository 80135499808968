import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from './hooks/useAuth';

interface RequireAuthProps {
    allowedRoles: number[];
}

export default function RequireAuth({ allowedRoles }: RequireAuthProps) {
    let { auth } = useAuth();
    let location = useLocation();

    return auth?.roles?.findIndex((role: number) =>
        allowedRoles?.includes(role)
    ) > -1 ? (
        <Outlet />
    ) : auth?.accessToken ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
}
