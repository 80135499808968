import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export default function useCreateJob() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();
    const createJob = async (values: any) => {
        const response = await authenticatedApi.post(
            `${orgId}/jobs/create`,
            values,
        );
        return response.status < 400;
    };

    const updateJob = async (values: any) => {
        const response = await authenticatedApi.put(`${orgId}/jobs`, values);
        return response.status < 400;
    };

    return { createJob, updateJob };
}
