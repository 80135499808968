import { useState, useLayoutEffect } from 'react';
import api from '../../../../services/api';

export default function useCollageWall(activationId: number) {
    const [smiles, setSmiles] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useLayoutEffect(() => {
        const getSmiles = async () => {
            setLoading(true);

            try {
                const smileData = await api.get(
                    `https://photostat-api.smilebooth.com/public/activations/${activationId}/photos`,
                );

                setSmiles(smileData.data);
            } catch (error) {}

            setLoading(false);
        };

        getSmiles();
        let timer = setInterval(() => {
            getSmiles();
        }, 10000);

        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        smiles,
        loading,
    };
}
