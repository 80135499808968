import {
    Select,
    SelectContent,
    SelectTrigger,
    SelectValue,
} from '@/src/shadComponents/ui/select';
import { useFormikContext, useField } from 'formik';

export default function SelectWrapper(props: any) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const { name, children } = props;

    return (
        <Select
            {...field}
            {...props}
            value={field.value}
            onValueChange={(value) => {
                setFieldValue(name, value);
            }}
        >
            <SelectTrigger className="w-[70%]">
                <SelectValue placeholder={props.placeholder} />
            </SelectTrigger>
            <SelectContent>{children}</SelectContent>
        </Select>
    );
}
