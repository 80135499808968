interface DialogFormRowProps {
    title: string;
    field: string;
    children: React.ReactNode;
}

export default function DialogFormRow({
    title,
    field,
    children,
}: DialogFormRowProps) {
    return (
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
                <label
                    htmlFor={field}
                    className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                >
                    {title}
                </label>
            </div>
            <div className="sm:col-span-2">{children}</div>
        </div>
    );
}
