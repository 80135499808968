import { useParams } from 'react-router-dom';
import './Gallery.css';
import { useState } from 'react';
import useSlideshow from './hooks/useSlideshow';
import useSlideshowDisplay from './hooks/useSlideshowDisplay';
import SlideshowSettingsModal from './SlideshowSettingsModal';
import { Button } from '@/src/shadComponents/ui/button';
import SlideshowViewer from './SlideshowViewer';
import GlobalStyle from '@/src/Share/components/GalleryTheme';

export default function Slideshow() {
    const { slug } = useParams();
    const { photos, isLoading, gallery } = useSlideshow(slug || '');
    const settings = useSlideshowDisplay();

    const [showSettingsModal, setShowSettingsModal] = useState(false);

    if (!gallery && !isLoading) {
        return (
            <div>
                <h3>gallery not found...</h3>
            </div>
        );
    }

    return (
        <div className="Gallery-view overflow-hidden">
            <GlobalStyle
                foregroundColor={gallery?.foregroundColor}
                backgroundColor={gallery?.backgroundColor}
                headerBackgroundColor={gallery?.headerBackgroundColor}
                headerForegroundColor={gallery?.headerForegroundColor}
            />
            {gallery && (
                <SlideshowViewer
                    isLoading={isLoading}
                    gallery={gallery}
                    photos={photos}
                    {...settings}
                />
            )}
            <div className="absolute bottom-0 right-0 z-[102]">
                <Button
                    onClick={() => setShowSettingsModal(true)}
                    className="Primary-button hide-after-timeout"
                >
                    Settings
                </Button>
            </div>
            {gallery && showSettingsModal && (
                <SlideshowSettingsModal
                    gallery={gallery}
                    {...settings}
                    setShowModal={setShowSettingsModal}
                />
            )}
        </div>
    );
}
