import { useCallback, useEffect, useState } from 'react';
import ListPickerModal from '../../Common/components/ListPickerModal';
import useActivationTagAssociation from './hooks/useActivationTagAssociation';
import useTags from './hooks/useTags';

interface ActivationTagAssociationProps {
    activationId: number;
}

export default function ActivationTagAssociations({
    activationId,
}: ActivationTagAssociationProps) {
    const [isLoading, setIsLoading] = useState(true);
    const { getTags, addTag } = useTags();
    const { getTagAssociations, setTagAssociations } =
        useActivationTagAssociation(activationId);
    const [tags, setTags] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [showPicker, setShowPicker] = useState(false);

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const assignments = await getTagAssociations();

                const tags = await getTags();

                setAssignments(assignments);
                setTags(tags);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveAssignments = useCallback(
        async (tagIds: number[]) => {
            try {
                const response = await setTagAssociations({ tagIds });

                setAssignments(response);
            } catch (error) {
                console.error(error);
            }
            setShowPicker(false);
        },
        [setTagAssociations],
    );

    const onTagAdd = useCallback(async (name: string) => {
        try {
            await addTag({ name });
            const tags = await getTags();

            setTags(tags);
        } catch (error) {
            console.error(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <div></div>;
    }

    return (
        <>
            <span className="center" onClick={() => setShowPicker(true)}>
                {assignments.length === 0
                    ? 'none'
                    : assignments.map(({ name }) => `${name} `)}
                {' |'}
                <i className="bi bi-card-checklist"></i>
            </span>
            {showPicker && (
                <ListPickerModal
                    list={tags}
                    selectedIds={assignments.map(({ id }) => id)}
                    onClose={() => setShowPicker(false)}
                    onSave={saveAssignments}
                    allowAdd={true}
                    onAdd={onTagAdd}
                />
            )}
        </>
    );
}
