import api from '../../../services/api';

interface GalleryDescriptor {
    slug: string;
    galleryId: number;
}

export default function useGallery({ slug, galleryId }: GalleryDescriptor) {
    const getGalleryById = async () => {
        const response = await api.get(`/public/galleries/${galleryId}`);

        const { data } = response;
        return data;
    };

    const getSessionGallery = async (photoSessionId: string) => {
        const response = await api.get(
            `/public/galleries/${galleryId}/session/${photoSessionId}/photos`,
        );

        const { data } = response;
        return data;
    };

    const getGallery = async () => {
        const response = await api.get(`/public/galleries/${slug}`);

        const { data } = response;
        return data;
    };

    const getActivationPhotos = async (activationId: number) => {
        const response = await api.get(
            `/public/activations/${activationId}/photos`,
        );

        const { data } = response;

        return data;
    };

    const getSingleDestinationGalleryPhotos = async (
        activationId: number,
        slug: string,
    ) => {
        const response = await api.get(
            `/public/activations/${activationId}/photos/${slug}`,
        );

        const { data } = response;

        return data;
    };

    const getPhotoByShareIdentifier = async (shareIdentifier: string) => {
        const response = await api.get(
            `/public/galleries/${galleryId}/photo/${encodeURIComponent(
                shareIdentifier,
            )}`,
        );

        const { data } = response;

        return data;
    };

    return {
        getGallery,
        getGalleryById,
        getActivationPhotos,
        getPhotoByShareIdentifier,
        getSessionGallery,
        getSingleDestinationGalleryPhotos,
    };
}
