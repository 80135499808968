import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export interface EmailTemplate {
    id?: number;
    name: string;
    body: string;
}

export default function useTemplates() {
    const [feedback, setFeedback] = useState<string | null>();
    const navigate = useNavigate();
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const getTemplates = async () => {
        const response = await authenticatedApi.get(`${orgId}/templates`);
        return response.data;
    };

    const getTemplate = async (templateId: number) => {
        const response = await authenticatedApi.get(
            `${orgId}/template/${templateId}`,
        );
        return response.data;
    };

    const importTemplate = async (values: { [key: string]: string }) => {
        const formData = new FormData();

        for (let value in values) {
            formData.append(value, values[value]);
        }

        const response = await authenticatedApi.post(
            `${orgId}/templates/import`,
            formData,
        );

        if (response.status !== 200) {
            setFeedback(
                'An error ocurred, check the file format of the zip or that the name of the template is unique',
            );
            return;
        }

        navigate(-1);
    };

    return {
        getTemplates,
        getTemplate,
        importTemplate,
        feedback,
    };
}
