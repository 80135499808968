import { useState, useEffect } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

export function useDeviceInviteList(orgId: number, groupId: number) {
    const authenticatedApi = useAuthenticatedApi();
    const [deviceInvites, setDeviceInvites] = useState<DeviceInvite[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchDeviceInvites = async () => {
            try {
                const results = await authenticatedApi.get(
                    `${orgId}/device-invites/${groupId}`,
                );

                setDeviceInvites(results.data);
            } catch (e: any) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDeviceInvites();
    }, [orgId, groupId, authenticatedApi]);

    return {
        deviceInvites,
        isLoading,
        error,
    };
}
