import { useLocalStorage } from '@/src/Common/components/hooks/useLocalStorage';
import { useState } from 'react';

export default function useSlideshowDisplay() {
    const [displayNewest, setDisplayNewest] = useLocalStorage(
        'sb-display-newest',
        false,
    );
    const [gridWidth, setGridWidth] = useLocalStorage('sb-slideshow-width', 1);
    const [gridHeight, setGridHeight] = useLocalStorage(
        'sb-slideshow-height',
        1,
    );
    const [gridGap, setGridGap] = useLocalStorage('sb-slideshow-gap', 0);
    const [rotationSpeed, setRotationSpeed] = useLocalStorage(
        'sb-rotation-speed',
        30,
    ); //In Seconds

    const [displayHeader, setDisplayHeader] = useState(true);

    return {
        gridWidth,
        setGridWidth,
        gridHeight,
        setGridHeight,
        gridGap,
        setGridGap,
        rotationSpeed,
        setRotationSpeed,
        displayHeader,
        setDisplayHeader,
        displayNewest,
        setDisplayNewest,
    };
}
