import HeadlessTable from '@/src/Common/components/HeadlessTable';
import { format } from 'date-fns';
const statuses: any = {
    Completed: 'text-green-700 bg-green-50 ring-green-600/20',
    Sent: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    'In Progress': 'text-red-700 bg-red-50 ring-red-600/10',
    Unopened: 'text-red-700 bg-red-50 ring-red-600/10',
};

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}
const documents = [
    {
        title: 'Logistics Form',
        status: 'Completed',
        sentOn: new Date(),
        completedOn: new Date(),
        client: 'Peter Griffen',
        ownerName: 'Aubrey McCoy',
        id: 1,
        completedPercentage: '100%',
    },
];

export default function ActivationDocuments() {
    return (
        <HeadlessTable>
            {documents.map((document) => {
                return (
                    <tr key={document.id}>
                        <td className="relative">
                            <div className="flex gap-x-6 mr-5">
                                <div className="text-sm font-medium leading-6 text-gray-900">
                                    {document.title}
                                </div>
                                {document.status ? (
                                    <div
                                        className={classNames(
                                            statuses[document.status],
                                            'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset',
                                        )}
                                    >
                                        {document.status}
                                    </div>
                                ) : null}
                            </div>
                        </td>

                        <td className="hidden py-5 pr-6 sm:table-cell">
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                                Sent To{' '}
                                <span className="text-gray-900">
                                    {document.client}
                                </span>
                            </div>
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                                Sent By{' '}
                                <span className="text-gray-900">
                                    {document.ownerName}
                                </span>
                            </div>
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                                Sent On{' '}
                                <span className="text-gray-900">
                                    {format(document.sentOn, 'MM/dd/yyyy')}
                                </span>
                            </div>
                        </td>

                        <td className="hidden py-5 pr-6 sm:table-cell">
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                                Last Viewed{' '}
                                <span className="text-gray-900">
                                    {format(document.sentOn, 'MM/dd/yyyy')}
                                </span>
                            </div>
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                                <span className="text-gray-900">
                                    {document.completedPercentage}
                                </span>{' '}
                                Completed
                            </div>
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                                Completed On{' '}
                                <span className="text-gray-900">
                                    {format(document.completedOn, 'MM/dd/yyyy')}
                                </span>
                            </div>
                        </td>

                        <td className="py-5 text-right">
                            <div className="flex justify-end">
                                <a
                                    href={`/documents/${document.id}`}
                                    className="text-sm font-medium leading-6 text-black hover:text-indigo-500"
                                >
                                    View
                                    <span className="hidden sm:inline">
                                        {' '}
                                        Document &rarr;
                                    </span>
                                </a>
                            </div>
                        </td>
                    </tr>
                );
            })}
        </HeadlessTable>
    );
}
